import type {
  PostAdditionalUploads200,
  PostAdditionalUploads505,
  PostAdditionalUploadsMutationRequest,
  PostAdditionalUploadsMutationResponse,
} from '../types/PostAdditionalUploads';
import { createAdditionalUploadsPostRequest } from './createAdditionalUploadsPostRequest';
import { createAdditionalUploadsPostResponse } from './createAdditionalUploadsPostResponse';

/**
 * @description 200 response
 */
export function createPostAdditionalUploads200(): NonNullable<PostAdditionalUploads200> {
  return createAdditionalUploadsPostResponse();
}

/**
 * @description 505 response
 */
export function createPostAdditionalUploads505(): NonNullable<PostAdditionalUploads505> {
  return undefined;
}

export function createPostAdditionalUploadsMutationRequest(): NonNullable<PostAdditionalUploadsMutationRequest> {
  return createAdditionalUploadsPostRequest();
}

/**
 * @description 200 response
 */
export function createPostAdditionalUploadsMutationResponse(): NonNullable<PostAdditionalUploadsMutationResponse> {
  return createAdditionalUploadsPostResponse();
}
