import { http } from 'msw';

import { createGetToggleDividendsReinvestQueryResponse } from '../mocks/createGetToggleDividendsReinvest';

export const getToggleDividendsReinvestHandler = http.get('*/toggle_dividends_reinvest', function handler(info) {
  return new Response(JSON.stringify(createGetToggleDividendsReinvestQueryResponse()), {
    headers: {
      'Content-Type': 'application/json',
    },
  });
});
