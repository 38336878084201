import { z } from 'zod';

import { subscriptionFormPostRequestSchema } from './subscriptionFormPostRequestSchema';
import { subscriptionFormPostResponseSchema } from './subscriptionFormPostResponseSchema';

/**
 * @description 200 response
 */
export const postSubscriptionForm200Schema = z.lazy(() => subscriptionFormPostResponseSchema);
/**
 * @description 505 response
 */
export const postSubscriptionForm505Schema = z.any();

export const postSubscriptionFormMutationRequestSchema = z.lazy(() => subscriptionFormPostRequestSchema);
/**
 * @description 200 response
 */
export const postSubscriptionFormMutationResponseSchema = z.lazy(() => subscriptionFormPostResponseSchema);
