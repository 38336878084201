import { z } from 'zod';

import { bankDetailsPostRequestSchema } from './bankDetailsPostRequestSchema';
import { bankDetailsPostResponseSchema } from './bankDetailsPostResponseSchema';

/**
 * @description 200 response
 */
export const postBankDetails200Schema = z.lazy(() => bankDetailsPostResponseSchema);
/**
 * @description 505 response
 */
export const postBankDetails505Schema = z.any();

export const postBankDetailsMutationRequestSchema = z.lazy(() => bankDetailsPostRequestSchema);
/**
 * @description 200 response
 */
export const postBankDetailsMutationResponseSchema = z.lazy(() => bankDetailsPostResponseSchema);
