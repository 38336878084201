import { z } from 'zod';

import { changeZakatDatePostRequestSchema } from './changeZakatDatePostRequestSchema';
import { changeZakatDatePostResponseSchema } from './changeZakatDatePostResponseSchema';

/**
 * @description 200 response
 */
export const postChangeZakatDate200Schema = z.lazy(() => changeZakatDatePostResponseSchema);
/**
 * @description 505 response
 */
export const postChangeZakatDate505Schema = z.any();

export const postChangeZakatDateMutationRequestSchema = z.lazy(() => changeZakatDatePostRequestSchema);
/**
 * @description 200 response
 */
export const postChangeZakatDateMutationResponseSchema = z.lazy(() => changeZakatDatePostResponseSchema);
