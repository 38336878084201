import { z } from 'zod';

import { financeDetailsGetResponseSchema } from './financeDetailsGetResponseSchema';

/**
 * @description 200 response
 */
export const getFinanceDetails200Schema = z.lazy(() => financeDetailsGetResponseSchema);
/**
 * @description 505 response
 */
export const getFinanceDetails505Schema = z.any();
/**
 * @description 200 response
 */
export const getFinanceDetailsQueryResponseSchema = z.lazy(() => financeDetailsGetResponseSchema);
