import { http } from 'msw';

import { createGetCalculatorHistoryQueryResponse } from '../mocks/createGetCalculatorHistory';

export const getCalculatorHistoryHandler = http.get('*/calculator_history', function handler(info) {
  return new Response(JSON.stringify(createGetCalculatorHistoryQueryResponse()), {
    headers: {
      'Content-Type': 'application/json',
    },
  });
});
