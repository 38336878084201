import type {
  PostVerifyUserAttribute200,
  PostVerifyUserAttribute505,
  PostVerifyUserAttributeMutationRequest,
  PostVerifyUserAttributeMutationResponse,
} from '../types/PostVerifyUserAttribute';
import { createVerifyUserAttributePostRequest } from './createVerifyUserAttributePostRequest';
import { createVerifyUserAttributePostResponse } from './createVerifyUserAttributePostResponse';

/**
 * @description 200 response
 */
export function createPostVerifyUserAttribute200(): NonNullable<PostVerifyUserAttribute200> {
  return createVerifyUserAttributePostResponse();
}

/**
 * @description 505 response
 */
export function createPostVerifyUserAttribute505(): NonNullable<PostVerifyUserAttribute505> {
  return undefined;
}

export function createPostVerifyUserAttributeMutationRequest(): NonNullable<PostVerifyUserAttributeMutationRequest> {
  return createVerifyUserAttributePostRequest();
}

/**
 * @description 200 response
 */
export function createPostVerifyUserAttributeMutationResponse(): NonNullable<PostVerifyUserAttributeMutationResponse> {
  return createVerifyUserAttributePostResponse();
}
