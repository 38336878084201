import { http } from 'msw';

import { createGetListUserObjectsQueryResponse } from '../mocks/createGetListUserObjects';

export const getListUserObjectsHandler = http.get('*/list_user_objects', function handler(info) {
  return new Response(JSON.stringify(createGetListUserObjectsQueryResponse()), {
    headers: {
      'Content-Type': 'application/json',
    },
  });
});
