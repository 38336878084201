import { useQueryErrorResetBoundary } from '@tanstack/react-query';
import type { ErrorComponentProps } from '@tanstack/react-router';
import { useNavigate, useRouter } from '@tanstack/react-router';
import { useEffect } from 'react';

import { Button, buttonStyles } from '@pfida-finance/ui/components';
import { HttpError, getErrorMessage } from '@repo/utils';
import { Error, ErrorCause, ErrorFooter, ErrorIcon, ErrorMessage, ErrorTitle } from './error';
import type { GeneralErrorBoundaryProps } from './generalErrorBoundary';
import { GeneralErrorBoundary } from './generalErrorBoundary';

type RouteErrorBoundaryProps = GeneralErrorBoundaryProps & { reset: ErrorComponentProps['reset'] };

function Default({ error }: { error: string }) {
  const navigate = useNavigate();

  function redirectToDashboard() {
    navigate({
      to: '/dashboard',
    });
  }
  return (
    <Error>
      <ErrorIcon name="x" />
      <ErrorTitle>Oops, something went wrong</ErrorTitle>
      <ErrorMessage>
        We’re sorry for the inconvenience. If this issue persists, please don not hesitate to contact us at{' '}
        <a href="mailto:info@pfida.com" className={buttonStyles({ variant: 'link' })}>
          info@pfida.com
        </a>
        .
      </ErrorMessage>
      <ErrorCause errorMessage={error} />
      <ErrorFooter>
        <Button variant="outline" onClick={() => redirectToDashboard()}>
          Back to Dashboard
        </Button>
      </ErrorFooter>
    </Error>
  );
}

function DefaultStatus({ handleRetry, error }: { handleRetry: () => void; error: HttpError }) {
  const navigate = useNavigate();

  function redirectToDashboard() {
    navigate({
      to: '/dashboard',
    });
  }
  return (
    <Error>
      <ErrorIcon name="x" />
      <ErrorTitle>Oops, something went wrong</ErrorTitle>
      <ErrorMessage>
        We’re sorry for the inconvenience. If this issue persists, please don not hesitate to contact us at{' '}
        <a href="mailto:info@pfida.com" className={buttonStyles({ variant: 'link' })}>
          info@pfida.com
        </a>
        .
      </ErrorMessage>
      <ErrorCause errorTitle={error.code} errorMessage={error.message} />
      <ErrorFooter>
        <Button variant="outline" onClick={() => redirectToDashboard()}>
          Back to Dashboard
        </Button>

        <Button variant="outline" onClick={() => handleRetry()}>
          Retry
        </Button>
      </ErrorFooter>
    </Error>
  );
}

export function RouteErrorBoundary({ reset, ...restProps }: RouteErrorBoundaryProps) {
  const router = useRouter();

  const queryErrorResetBoundary = useQueryErrorResetBoundary();

  useEffect(() => {
    queryErrorResetBoundary.reset();
  }, [queryErrorResetBoundary]);

  function handleRetry() {
    reset();
    router.invalidate();
  }

  return (
    <GeneralErrorBoundary
      {...restProps}
      defaultStatusHandler={(error) => <DefaultStatus error={error.error} handleRetry={() => handleRetry()} />}
      unexpectedErrorHandler={(error) => <Default error={getErrorMessage(error)} />}
    />
  );
}
