import { http } from 'msw';

import { createPostToggleMfaMutationResponse } from '../mocks/createPostToggleMfa';

export const postToggleMfaHandler = http.post('*/toggle_mfa', function handler(info) {
  return new Response(JSON.stringify(createPostToggleMfaMutationResponse()), {
    headers: {
      'Content-Type': 'application/json',
    },
  });
});
