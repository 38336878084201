import { faker } from '@faker-js/faker';

import type {
  GetToggleDividendsReinvest200,
  GetToggleDividendsReinvest505,
  GetToggleDividendsReinvestQueryParams,
  GetToggleDividendsReinvestQueryResponse,
} from '../types/GetToggleDividendsReinvest';
import { createToggleDividendsReinvestGetResponse } from './createToggleDividendsReinvestGetResponse';

export function createGetToggleDividendsReinvestQueryParams(): NonNullable<GetToggleDividendsReinvestQueryParams> {
  return { investment_product: faker.string.alpha() };
}

/**
 * @description 200 response
 */
export function createGetToggleDividendsReinvest200(): NonNullable<GetToggleDividendsReinvest200> {
  return createToggleDividendsReinvestGetResponse();
}

/**
 * @description 505 response
 */
export function createGetToggleDividendsReinvest505(): NonNullable<GetToggleDividendsReinvest505> {
  return undefined;
}

/**
 * @description 200 response
 */
export function createGetToggleDividendsReinvestQueryResponse(): NonNullable<GetToggleDividendsReinvestQueryResponse> {
  return createToggleDividendsReinvestGetResponse();
}
