import { z } from 'zod';

export const eligibilityPostRequestSchema = z.object({
  accounts_records: z.string().optional(),
  annual_income: z.string(),
  ccj_bankruptcy: z.string(),
  commercial_let: z.string().optional(),
  continuous_employment: z.string().optional(),
  country: z.string(),
  deposit: z.string(),
  employment_type: z.string(),
  finance_purpose: z.string(),
  first_time_buyer: z.string(),
  have_inhabited: z.string().optional(),
  main_residence: z.string(),
  monthly_spend: z.string(),
  nationality: z.string(),
  net_monthly_income: z.string(),
  own_other_lets: z.string().optional(),
  property_location: z.string(),
  property_type: z.string(),
  property_value: z.string(),
  term: z.string(),
  will_inhabit: z.string().optional(),
});
