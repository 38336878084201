import type {
  DeleteOneOffPaymentNotice200,
  DeleteOneOffPaymentNotice505,
  DeleteOneOffPaymentNoticeMutationRequest,
  DeleteOneOffPaymentNoticeMutationResponse,
} from '../types/DeleteOneOffPaymentNotice';
import { createOneOffPaymentNoticeDeleteRequest } from './createOneOffPaymentNoticeDeleteRequest';
import { createOneOffPaymentNoticeDeleteResponse } from './createOneOffPaymentNoticeDeleteResponse';

/**
 * @description 200 response
 */
export function createDeleteOneOffPaymentNotice200(): NonNullable<DeleteOneOffPaymentNotice200> {
  return createOneOffPaymentNoticeDeleteResponse();
}

/**
 * @description 505 response
 */
export function createDeleteOneOffPaymentNotice505(): NonNullable<DeleteOneOffPaymentNotice505> {
  return undefined;
}

export function createDeleteOneOffPaymentNoticeMutationRequest(): NonNullable<DeleteOneOffPaymentNoticeMutationRequest> {
  return createOneOffPaymentNoticeDeleteRequest();
}

/**
 * @description 200 response
 */
export function createDeleteOneOffPaymentNoticeMutationResponse(): NonNullable<DeleteOneOffPaymentNoticeMutationResponse> {
  return createOneOffPaymentNoticeDeleteResponse();
}
