import { faker } from '@faker-js/faker';

import type { UserPoolDetailsGetResponse } from '../types/UserPoolDetailsGetResponse';

export function createUserPoolDetailsGetResponse(
  data: NonNullable<Partial<UserPoolDetailsGetResponse>> = {}
): NonNullable<UserPoolDetailsGetResponse> {
  return {
    ...{
      data: {
        mfa: faker.string.alpha(),
        password_policy: {
          MinimumLength: faker.number.int(),
          RequireLowercase: faker.datatype.boolean(),
          RequireNumbers: faker.datatype.boolean(),
          RequireSymbols: faker.datatype.boolean(),
          RequireUppercase: faker.datatype.boolean(),
          TemporaryPasswordValidityDays: faker.number.float(),
        },
      },
      response: {
        date: faker.string.alpha(),
        messages: faker.helpers.arrayElements([faker.string.alpha()]) as any,
        status: faker.number.int(),
        type: faker.string.alpha(),
      },
    },
    ...data,
  };
}
