import { z } from 'zod';

import { equityInvestmentPageVisitedPostRequestSchema } from './equityInvestmentPageVisitedPostRequestSchema';
import { equityInvestmentPageVisitedPostResponseSchema } from './equityInvestmentPageVisitedPostResponseSchema';

/**
 * @description 200 response
 */
export const postEquityInvestmentPageVisited200Schema = z.lazy(() => equityInvestmentPageVisitedPostResponseSchema);
/**
 * @description 505 response
 */
export const postEquityInvestmentPageVisited505Schema = z.any();

export const postEquityInvestmentPageVisitedMutationRequestSchema = z.lazy(
  () => equityInvestmentPageVisitedPostRequestSchema
);
/**
 * @description 200 response
 */
export const postEquityInvestmentPageVisitedMutationResponseSchema = z.lazy(
  () => equityInvestmentPageVisitedPostResponseSchema
);
