import { z } from 'zod';

import { costCalculatorAuthenticatedGetResponseSchema } from './costCalculatorAuthenticatedGetResponseSchema';

/**
 * @description 200 response
 */
export const getCostCalculatorAuthenticated200Schema = z.lazy(() => costCalculatorAuthenticatedGetResponseSchema);
/**
 * @description 505 response
 */
export const getCostCalculatorAuthenticated505Schema = z.any();
/**
 * @description 200 response
 */
export const getCostCalculatorAuthenticatedQueryResponseSchema = z.lazy(
  () => costCalculatorAuthenticatedGetResponseSchema
);
