import { http } from 'msw';

import { createGetUserDetailsQueryResponse } from '../mocks/createGetUserDetails';

export const getUserDetailsHandler = http.get('*/user_details', function handler(info) {
  return new Response(JSON.stringify(createGetUserDetailsQueryResponse()), {
    headers: {
      'Content-Type': 'application/json',
    },
  });
});
