import type {
  GetSubscriptionForm200,
  GetSubscriptionForm505,
  GetSubscriptionFormQueryResponse,
} from '../types/GetSubscriptionForm';
import { createSubscriptionFormGetResponse } from './createSubscriptionFormGetResponse';

/**
 * @description 200 response
 */
export function createGetSubscriptionForm200(): NonNullable<GetSubscriptionForm200> {
  return createSubscriptionFormGetResponse();
}

/**
 * @description 505 response
 */
export function createGetSubscriptionForm505(): NonNullable<GetSubscriptionForm505> {
  return undefined;
}

/**
 * @description 200 response
 */
export function createGetSubscriptionFormQueryResponse(): NonNullable<GetSubscriptionFormQueryResponse> {
  return createSubscriptionFormGetResponse();
}
