import { z } from 'zod';

import { changeRetirementDateGetResponseSchema } from './changeRetirementDateGetResponseSchema';

/**
 * @description 200 response
 */
export const getChangeRetirementDate200Schema = z.lazy(() => changeRetirementDateGetResponseSchema);
/**
 * @description 505 response
 */
export const getChangeRetirementDate505Schema = z.any();
/**
 * @description 200 response
 */
export const getChangeRetirementDateQueryResponseSchema = z.lazy(() => changeRetirementDateGetResponseSchema);
