import { http } from 'msw';

import { createPostAdditionalUploadsMutationResponse } from '../mocks/createPostAdditionalUploads';

export const postAdditionalUploadsHandler = http.post('*/additional_uploads', function handler(info) {
  return new Response(JSON.stringify(createPostAdditionalUploadsMutationResponse()), {
    headers: {
      'Content-Type': 'application/json',
    },
  });
});
