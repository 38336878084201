import { z } from 'zod';

export const financeRequirementsPostRequestSchema = z.object({
  birthdate: z.string().optional(),
  deposit_percentage: z.string(),
  email: z.string().optional(),
  employment: z.string(),
  finance_purpose: z.string(),
  finance_time: z.string(),
  name: z.string().optional(),
  phone_number: z.string().optional(),
  product_type: z.string(),
  property_value: z.number(),
});
