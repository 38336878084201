import { PropsWithChildren } from 'react';
import { HelmetProvider } from 'react-helmet-async';

import { GrowthbookProvider } from '@/libs/growthbook';
import { TanstackQueryProvider } from '../libs/tanstackQuery';

export function AppProviders({ children }: PropsWithChildren) {
  return (
    <HelmetProvider>
      <GrowthbookProvider>
        <TanstackQueryProvider>{children}</TanstackQueryProvider>
      </GrowthbookProvider>
    </HelmetProvider>
  );
}
