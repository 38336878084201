import { faker } from '@faker-js/faker';

import type {
  GetIfaApproval200,
  GetIfaApproval505,
  GetIfaApprovalQueryParams,
  GetIfaApprovalQueryResponse,
} from '../types/GetIfaApproval';
import { createIfaApprovalGetResponse } from './createIfaApprovalGetResponse';

export function createGetIfaApprovalQueryParams(): NonNullable<GetIfaApprovalQueryParams> {
  return { company: faker.string.alpha() };
}

/**
 * @description 200 response
 */
export function createGetIfaApproval200(): NonNullable<GetIfaApproval200> {
  return createIfaApprovalGetResponse();
}

/**
 * @description 505 response
 */
export function createGetIfaApproval505(): NonNullable<GetIfaApproval505> {
  return undefined;
}

/**
 * @description 200 response
 */
export function createGetIfaApprovalQueryResponse(): NonNullable<GetIfaApprovalQueryResponse> {
  return createIfaApprovalGetResponse();
}
