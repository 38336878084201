import { z } from 'zod';

import { accountTypeGetResponseSchema } from './accountTypeGetResponseSchema';

/**
 * @description 200 response
 */
export const getAccountType200Schema = z.lazy(() => accountTypeGetResponseSchema);
/**
 * @description 505 response
 */
export const getAccountType505Schema = z.any();
/**
 * @description 200 response
 */
export const getAccountTypeQueryResponseSchema = z.lazy(() => accountTypeGetResponseSchema);
