import { z } from 'zod';

import { userPoolDetailsGetResponseSchema } from './userPoolDetailsGetResponseSchema';

/**
 * @description 200 response
 */
export const getUserPoolDetails200Schema = z.lazy(() => userPoolDetailsGetResponseSchema);
/**
 * @description 505 response
 */
export const getUserPoolDetails505Schema = z.any();
/**
 * @description 200 response
 */
export const getUserPoolDetailsQueryResponseSchema = z.lazy(() => userPoolDetailsGetResponseSchema);
