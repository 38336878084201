import { Error, ErrorFooter, ErrorIcon, ErrorMessage, ErrorTitle } from '@/components/errors/error';
import { Button, buttonStyles } from '@pfida-finance/ui/components';
import { useNavigate } from '@tanstack/react-router';

export function SubmittedError() {
  const navigate = useNavigate();

  function redirectToDashboard() {
    navigate({
      to: '/dashboard',
    });
  }
  return (
    <Error>
      <ErrorIcon name="x" />
      <ErrorTitle>Form already submitted</ErrorTitle>
      <ErrorMessage>You’ve already submitted your form, so there’s nothing else to do for now.</ErrorMessage>
      <ErrorMessage>
        If you need to make changes, please email{' '}
        <a href="mailto:info@pfida.com" className={buttonStyles({ variant: 'link' })}>
          info@pfida.com
        </a>{' '}
        with the subject heading &quot;I need to update my Decision in Principle application&quot;.
      </ErrorMessage>
      <ErrorFooter>
        <Button variant="outline" onClick={() => redirectToDashboard()}>
          Back to Dashboard
        </Button>
      </ErrorFooter>
    </Error>
  );
}
