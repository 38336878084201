import type {
  PostBankDetails200,
  PostBankDetails505,
  PostBankDetailsMutationRequest,
  PostBankDetailsMutationResponse,
} from '../types/PostBankDetails';
import { createBankDetailsPostRequest } from './createBankDetailsPostRequest';
import { createBankDetailsPostResponse } from './createBankDetailsPostResponse';

/**
 * @description 200 response
 */
export function createPostBankDetails200(): NonNullable<PostBankDetails200> {
  return createBankDetailsPostResponse();
}

/**
 * @description 505 response
 */
export function createPostBankDetails505(): NonNullable<PostBankDetails505> {
  return undefined;
}

export function createPostBankDetailsMutationRequest(): NonNullable<PostBankDetailsMutationRequest> {
  return createBankDetailsPostRequest();
}

/**
 * @description 200 response
 */
export function createPostBankDetailsMutationResponse(): NonNullable<PostBankDetailsMutationResponse> {
  return createBankDetailsPostResponse();
}
