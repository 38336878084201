import type { GetToggleMfa200, GetToggleMfa505, GetToggleMfaQueryResponse } from '../types/GetToggleMfa';
import { createToggleMfaGetResponse } from './createToggleMfaGetResponse';

/**
 * @description 200 response
 */
export function createGetToggleMfa200(): NonNullable<GetToggleMfa200> {
  return createToggleMfaGetResponse();
}

/**
 * @description 505 response
 */
export function createGetToggleMfa505(): NonNullable<GetToggleMfa505> {
  return undefined;
}

/**
 * @description 200 response
 */
export function createGetToggleMfaQueryResponse(): NonNullable<GetToggleMfaQueryResponse> {
  return createToggleMfaGetResponse();
}
