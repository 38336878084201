import type {
  GetCostCalculator200,
  GetCostCalculator505,
  GetCostCalculatorQueryResponse,
} from '../types/GetCostCalculator';
import { createCostCalculatorGetResponse } from './createCostCalculatorGetResponse';

/**
 * @description 200 response
 */
export function createGetCostCalculator200(): NonNullable<GetCostCalculator200> {
  return createCostCalculatorGetResponse();
}

/**
 * @description 505 response
 */
export function createGetCostCalculator505(): NonNullable<GetCostCalculator505> {
  return undefined;
}

/**
 * @description 200 response
 */
export function createGetCostCalculatorQueryResponse(): NonNullable<GetCostCalculatorQueryResponse> {
  return createCostCalculatorGetResponse();
}
