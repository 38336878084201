import { http } from 'msw';

import { createPostAffordabilityCalculatorMutationResponse } from '../mocks/createPostAffordabilityCalculator';

export const postAffordabilityCalculatorHandler = http.post('*/affordability_calculator', function handler(info) {
  return new Response(JSON.stringify(createPostAffordabilityCalculatorMutationResponse()), {
    headers: {
      'Content-Type': 'application/json',
    },
  });
});
