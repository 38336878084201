import { http } from 'msw';

import { createGetHearAboutQueryResponse } from '../mocks/createGetHearAbout';

export const getHearAboutHandler = http.get('*/hear_about', function handler(info) {
  return new Response(JSON.stringify(createGetHearAboutQueryResponse()), {
    headers: {
      'Content-Type': 'application/json',
    },
  });
});
