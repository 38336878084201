import { http } from 'msw';

import { createGetRevolutOpenBankingCallbackQueryResponse } from '../mocks/createGetRevolutOpenBankingCallback';

export const getRevolutOpenBankingCallbackHandler = http.get('*/revolut_open_banking_callback', function handler(info) {
  return new Response(JSON.stringify(createGetRevolutOpenBankingCallbackQueryResponse()), {
    headers: {
      'Content-Type': 'application/json',
    },
  });
});
