import { z } from 'zod';

import { forgotPasswordConfirmPostRequestSchema } from './forgotPasswordConfirmPostRequestSchema';
import { forgotPasswordConfirmPostResponseSchema } from './forgotPasswordConfirmPostResponseSchema';

/**
 * @description 200 response
 */
export const postForgotPasswordConfirm200Schema = z.lazy(() => forgotPasswordConfirmPostResponseSchema);
/**
 * @description 505 response
 */
export const postForgotPasswordConfirm505Schema = z.any();

export const postForgotPasswordConfirmMutationRequestSchema = z.lazy(() => forgotPasswordConfirmPostRequestSchema);
/**
 * @description 200 response
 */
export const postForgotPasswordConfirmMutationResponseSchema = z.lazy(() => forgotPasswordConfirmPostResponseSchema);
