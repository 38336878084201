import type {
  PostEquityInvestmentPageVisited200,
  PostEquityInvestmentPageVisited505,
  PostEquityInvestmentPageVisitedMutationRequest,
  PostEquityInvestmentPageVisitedMutationResponse,
} from '../types/PostEquityInvestmentPageVisited';
import { createEquityInvestmentPageVisitedPostRequest } from './createEquityInvestmentPageVisitedPostRequest';
import { createEquityInvestmentPageVisitedPostResponse } from './createEquityInvestmentPageVisitedPostResponse';

/**
 * @description 200 response
 */
export function createPostEquityInvestmentPageVisited200(): NonNullable<PostEquityInvestmentPageVisited200> {
  return createEquityInvestmentPageVisitedPostResponse();
}

/**
 * @description 505 response
 */
export function createPostEquityInvestmentPageVisited505(): NonNullable<PostEquityInvestmentPageVisited505> {
  return undefined;
}

export function createPostEquityInvestmentPageVisitedMutationRequest(): NonNullable<PostEquityInvestmentPageVisitedMutationRequest> {
  return createEquityInvestmentPageVisitedPostRequest();
}

/**
 * @description 200 response
 */
export function createPostEquityInvestmentPageVisitedMutationResponse(): NonNullable<PostEquityInvestmentPageVisitedMutationResponse> {
  return createEquityInvestmentPageVisitedPostResponse();
}
