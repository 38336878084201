import { http } from 'msw';

import { createPostReferralsMutationResponse } from '../mocks/createPostReferrals';

export const postReferralsHandler = http.post('*/referrals', function handler(info) {
  return new Response(JSON.stringify(createPostReferralsMutationResponse()), {
    headers: {
      'Content-Type': 'application/json',
    },
  });
});
