import { http } from 'msw';

import { createPostSendSubscriptionFormMutationResponse } from '../mocks/createPostSendSubscriptionForm';

export const postSendSubscriptionFormHandler = http.post('*/send_subscription_form', function handler(info) {
  return new Response(JSON.stringify(createPostSendSubscriptionFormMutationResponse()), {
    headers: {
      'Content-Type': 'application/json',
    },
  });
});
