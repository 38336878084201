import type {
  PostSubscriptionForm200,
  PostSubscriptionForm505,
  PostSubscriptionFormMutationRequest,
  PostSubscriptionFormMutationResponse,
} from '../types/PostSubscriptionForm';
import { createSubscriptionFormPostRequest } from './createSubscriptionFormPostRequest';
import { createSubscriptionFormPostResponse } from './createSubscriptionFormPostResponse';

/**
 * @description 200 response
 */
export function createPostSubscriptionForm200(): NonNullable<PostSubscriptionForm200> {
  return createSubscriptionFormPostResponse();
}

/**
 * @description 505 response
 */
export function createPostSubscriptionForm505(): NonNullable<PostSubscriptionForm505> {
  return undefined;
}

export function createPostSubscriptionFormMutationRequest(): NonNullable<PostSubscriptionFormMutationRequest> {
  return createSubscriptionFormPostRequest();
}

/**
 * @description 200 response
 */
export function createPostSubscriptionFormMutationResponse(): NonNullable<PostSubscriptionFormMutationResponse> {
  return createSubscriptionFormPostResponse();
}
