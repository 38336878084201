import { http } from 'msw';

import { createPostUpdateProfileMutationResponse } from '../mocks/createPostUpdateProfile';

export const postUpdateProfileHandler = http.post('*/update_profile', function handler(info) {
  return new Response(JSON.stringify(createPostUpdateProfileMutationResponse()), {
    headers: {
      'Content-Type': 'application/json',
    },
  });
});
