import { MifeParcel } from '@/components/MifeParcel';
import { createFileRoute } from '@tanstack/react-router';

function About() {
  return <MifeParcel />;
}

export const Route = createFileRoute('/_authenticated/_pfida/about')({
  component: About,
});
