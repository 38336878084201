import { http } from 'msw';

import { createPostCostCalculatorMutationResponse } from '../mocks/createPostCostCalculator';

export const postCostCalculatorHandler = http.post('*/cost_calculator', function handler(info) {
  return new Response(JSON.stringify(createPostCostCalculatorMutationResponse()), {
    headers: {
      'Content-Type': 'application/json',
    },
  });
});
