import { http } from 'msw';

import { createPostAffordabilityCalculatorAuthenticatedMutationResponse } from '../mocks/createPostAffordabilityCalculatorAuthenticated';

export const postAffordabilityCalculatorAuthenticatedHandler = http.post(
  '*/affordability_calculator_authenticated',
  function handler(info) {
    return new Response(JSON.stringify(createPostAffordabilityCalculatorAuthenticatedMutationResponse()), {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
);
