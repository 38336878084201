import { faker } from '@faker-js/faker';

import type { ChangeUnexpectedPaymentOutcomePostResponse } from '../types/ChangeUnexpectedPaymentOutcomePostResponse';

export function createChangeUnexpectedPaymentOutcomePostResponse(
  data: NonNullable<Partial<ChangeUnexpectedPaymentOutcomePostResponse>> = {}
): NonNullable<ChangeUnexpectedPaymentOutcomePostResponse> {
  return {
    ...{
      data: {},
      response: {
        date: faker.string.alpha(),
        messages: faker.helpers.arrayElements([faker.string.alpha()]) as any,
        status: faker.number.int(),
        type: faker.string.alpha(),
      },
    },
    ...data,
  };
}
