import { http } from 'msw';

import { createGetChangeInvestorCreditSplitQueryResponse } from '../mocks/createGetChangeInvestorCreditSplit';

export const getChangeInvestorCreditSplitHandler = http.get('*/change_investor_credit_split', function handler(info) {
  return new Response(JSON.stringify(createGetChangeInvestorCreditSplitQueryResponse()), {
    headers: {
      'Content-Type': 'application/json',
    },
  });
});
