import type {
  GetFinanceDetails200,
  GetFinanceDetails505,
  GetFinanceDetailsQueryResponse,
} from '../types/GetFinanceDetails';
import { createFinanceDetailsGetResponse } from './createFinanceDetailsGetResponse';

/**
 * @description 200 response
 */
export function createGetFinanceDetails200(): NonNullable<GetFinanceDetails200> {
  return createFinanceDetailsGetResponse();
}

/**
 * @description 505 response
 */
export function createGetFinanceDetails505(): NonNullable<GetFinanceDetails505> {
  return undefined;
}

/**
 * @description 200 response
 */
export function createGetFinanceDetailsQueryResponse(): NonNullable<GetFinanceDetailsQueryResponse> {
  return createFinanceDetailsGetResponse();
}
