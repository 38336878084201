import type {
  GetChangeUnexpectedPaymentOutcome200,
  GetChangeUnexpectedPaymentOutcome505,
  GetChangeUnexpectedPaymentOutcomeQueryResponse,
} from '../types/GetChangeUnexpectedPaymentOutcome';
import { createChangeUnexpectedPaymentOutcomeGetResponse } from './createChangeUnexpectedPaymentOutcomeGetResponse';

/**
 * @description 200 response
 */
export function createGetChangeUnexpectedPaymentOutcome200(): NonNullable<GetChangeUnexpectedPaymentOutcome200> {
  return createChangeUnexpectedPaymentOutcomeGetResponse();
}

/**
 * @description 505 response
 */
export function createGetChangeUnexpectedPaymentOutcome505(): NonNullable<GetChangeUnexpectedPaymentOutcome505> {
  return undefined;
}

/**
 * @description 200 response
 */
export function createGetChangeUnexpectedPaymentOutcomeQueryResponse(): NonNullable<GetChangeUnexpectedPaymentOutcomeQueryResponse> {
  return createChangeUnexpectedPaymentOutcomeGetResponse();
}
