import type {
  PostVerifyMfaToken200,
  PostVerifyMfaToken505,
  PostVerifyMfaTokenMutationRequest,
  PostVerifyMfaTokenMutationResponse,
} from '../types/PostVerifyMfaToken';
import { createVerifyMfaTokenPostRequest } from './createVerifyMfaTokenPostRequest';
import { createVerifyMfaTokenPostResponse } from './createVerifyMfaTokenPostResponse';

/**
 * @description 200 response
 */
export function createPostVerifyMfaToken200(): NonNullable<PostVerifyMfaToken200> {
  return createVerifyMfaTokenPostResponse();
}

/**
 * @description 505 response
 */
export function createPostVerifyMfaToken505(): NonNullable<PostVerifyMfaToken505> {
  return undefined;
}

export function createPostVerifyMfaTokenMutationRequest(): NonNullable<PostVerifyMfaTokenMutationRequest> {
  return createVerifyMfaTokenPostRequest();
}

/**
 * @description 200 response
 */
export function createPostVerifyMfaTokenMutationResponse(): NonNullable<PostVerifyMfaTokenMutationResponse> {
  return createVerifyMfaTokenPostResponse();
}
