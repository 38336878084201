import type { PostDip200, PostDip505, PostDipMutationRequest, PostDipMutationResponse } from '../types/PostDip';
import { createDipPostRequest } from './createDipPostRequest';
import { createDipPostResponse } from './createDipPostResponse';

/**
 * @description 200 response
 */
export function createPostDip200(): NonNullable<PostDip200> {
  return createDipPostResponse();
}

/**
 * @description 505 response
 */
export function createPostDip505(): NonNullable<PostDip505> {
  return undefined;
}

export function createPostDipMutationRequest(): NonNullable<PostDipMutationRequest> {
  return createDipPostRequest();
}

/**
 * @description 200 response
 */
export function createPostDipMutationResponse(): NonNullable<PostDipMutationResponse> {
  return createDipPostResponse();
}
