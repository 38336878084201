import { http } from 'msw';

import { createPostVerifyUserAttributeMutationResponse } from '../mocks/createPostVerifyUserAttribute';

export const postVerifyUserAttributeHandler = http.post('*/verify_user_attribute', function handler(info) {
  return new Response(JSON.stringify(createPostVerifyUserAttributeMutationResponse()), {
    headers: {
      'Content-Type': 'application/json',
    },
  });
});
