import { http } from 'msw';

import { createPostChangeMonthlyPaymentMutationResponse } from '../mocks/createPostChangeMonthlyPayment';

export const postChangeMonthlyPaymentHandler = http.post('*/change_monthly_payment', function handler(info) {
  return new Response(JSON.stringify(createPostChangeMonthlyPaymentMutationResponse()), {
    headers: {
      'Content-Type': 'application/json',
    },
  });
});
