import { z } from 'zod';

import { equityInvestmentPageVisitedGetResponseSchema } from './equityInvestmentPageVisitedGetResponseSchema';

/**
 * @description 200 response
 */
export const getEquityInvestmentPageVisited200Schema = z.lazy(() => equityInvestmentPageVisitedGetResponseSchema);
/**
 * @description 505 response
 */
export const getEquityInvestmentPageVisited505Schema = z.any();
/**
 * @description 200 response
 */
export const getEquityInvestmentPageVisitedQueryResponseSchema = z.lazy(
  () => equityInvestmentPageVisitedGetResponseSchema
);
