import type {
  PostSignUpConfirm200,
  PostSignUpConfirm505,
  PostSignUpConfirmMutationRequest,
  PostSignUpConfirmMutationResponse,
} from '../types/PostSignUpConfirm';
import { createSignUpConfirmPostRequest } from './createSignUpConfirmPostRequest';
import { createSignUpConfirmPostResponse } from './createSignUpConfirmPostResponse';

/**
 * @description 200 response
 */
export function createPostSignUpConfirm200(): NonNullable<PostSignUpConfirm200> {
  return createSignUpConfirmPostResponse();
}

/**
 * @description 505 response
 */
export function createPostSignUpConfirm505(): NonNullable<PostSignUpConfirm505> {
  return undefined;
}

export function createPostSignUpConfirmMutationRequest(): NonNullable<PostSignUpConfirmMutationRequest> {
  return createSignUpConfirmPostRequest();
}

/**
 * @description 200 response
 */
export function createPostSignUpConfirmMutationResponse(): NonNullable<PostSignUpConfirmMutationResponse> {
  return createSignUpConfirmPostResponse();
}
