import { faker } from '@faker-js/faker';

import type { LoginAuthChallengePostResponse } from '../types/LoginAuthChallengePostResponse';

export function createLoginAuthChallengePostResponse(
  data: NonNullable<Partial<LoginAuthChallengePostResponse>> = {}
): NonNullable<LoginAuthChallengePostResponse> {
  return {
    ...{
      data: {
        AuthenticationResult: {
          AccessToken: faker.string.alpha(),
          IdToken: faker.string.alpha(),
          RefreshToken: faker.string.alpha(),
        },
        ChallengeName: faker.string.alpha(),
        ChallengeParameters: {},
        SecretCode: faker.string.alpha(),
        Session: faker.string.alpha(),
      },
      response: {
        date: faker.string.alpha(),
        messages: faker.helpers.arrayElements([faker.string.alpha()]) as any,
        status: faker.number.int(),
        type: faker.string.alpha(),
      },
    },
    ...data,
  };
}
