import { z } from 'zod';

import { userDetailsGetResponseSchema } from './userDetailsGetResponseSchema';

/**
 * @description 200 response
 */
export const getUserDetails200Schema = z.lazy(() => userDetailsGetResponseSchema);
/**
 * @description 505 response
 */
export const getUserDetails505Schema = z.any();
/**
 * @description 200 response
 */
export const getUserDetailsQueryResponseSchema = z.lazy(() => userDetailsGetResponseSchema);
