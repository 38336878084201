import type {
  GetCostCalculatorAuthenticated200,
  GetCostCalculatorAuthenticated505,
  GetCostCalculatorAuthenticatedQueryResponse,
} from '../types/GetCostCalculatorAuthenticated';
import { createCostCalculatorAuthenticatedGetResponse } from './createCostCalculatorAuthenticatedGetResponse';

/**
 * @description 200 response
 */
export function createGetCostCalculatorAuthenticated200(): NonNullable<GetCostCalculatorAuthenticated200> {
  return createCostCalculatorAuthenticatedGetResponse();
}

/**
 * @description 505 response
 */
export function createGetCostCalculatorAuthenticated505(): NonNullable<GetCostCalculatorAuthenticated505> {
  return undefined;
}

/**
 * @description 200 response
 */
export function createGetCostCalculatorAuthenticatedQueryResponse(): NonNullable<GetCostCalculatorAuthenticatedQueryResponse> {
  return createCostCalculatorAuthenticatedGetResponse();
}
