import { http } from 'msw';

import { createPostVerifyMfaTokenMutationResponse } from '../mocks/createPostVerifyMfaToken';

export const postVerifyMfaTokenHandler = http.post('*/verify_mfa_token', function handler(info) {
  return new Response(JSON.stringify(createPostVerifyMfaTokenMutationResponse()), {
    headers: {
      'Content-Type': 'application/json',
    },
  });
});
