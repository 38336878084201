import { z } from 'zod';

import { loginPostRequestSchema } from './loginPostRequestSchema';
import { loginPostResponseSchema } from './loginPostResponseSchema';

/**
 * @description 200 response
 */
export const postLogin200Schema = z.lazy(() => loginPostResponseSchema);
/**
 * @description 505 response
 */
export const postLogin505Schema = z.any();

export const postLoginMutationRequestSchema = z.lazy(() => loginPostRequestSchema);
/**
 * @description 200 response
 */
export const postLoginMutationResponseSchema = z.lazy(() => loginPostResponseSchema);
