import { z } from 'zod';

export const userPoolDetailsGetResponseSchema = z.object({
  data: z.object({
    mfa: z.string().optional(),
    password_policy: z
      .object({
        MinimumLength: z.number(),
        RequireLowercase: z.boolean(),
        RequireNumbers: z.boolean(),
        RequireSymbols: z.boolean(),
        RequireUppercase: z.boolean(),
        TemporaryPasswordValidityDays: z.number(),
      })
      .optional(),
  }),
  response: z.object({ date: z.string(), messages: z.array(z.string()), status: z.number(), type: z.string() }),
});
