import { z } from 'zod';

import { toggleMfaPostRequestSchema } from './toggleMfaPostRequestSchema';
import { toggleMfaPostResponseSchema } from './toggleMfaPostResponseSchema';

/**
 * @description 200 response
 */
export const postToggleMfa200Schema = z.lazy(() => toggleMfaPostResponseSchema);
/**
 * @description 505 response
 */
export const postToggleMfa505Schema = z.any();

export const postToggleMfaMutationRequestSchema = z.lazy(() => toggleMfaPostRequestSchema);
/**
 * @description 200 response
 */
export const postToggleMfaMutationResponseSchema = z.lazy(() => toggleMfaPostResponseSchema);
