import type {
  PostChangeExpectedPaymentSetting200,
  PostChangeExpectedPaymentSetting505,
  PostChangeExpectedPaymentSettingMutationRequest,
  PostChangeExpectedPaymentSettingMutationResponse,
} from '../types/PostChangeExpectedPaymentSetting';
import { createChangeExpectedPaymentSettingPostRequest } from './createChangeExpectedPaymentSettingPostRequest';
import { createChangeExpectedPaymentSettingPostResponse } from './createChangeExpectedPaymentSettingPostResponse';

/**
 * @description 200 response
 */
export function createPostChangeExpectedPaymentSetting200(): NonNullable<PostChangeExpectedPaymentSetting200> {
  return createChangeExpectedPaymentSettingPostResponse();
}

/**
 * @description 505 response
 */
export function createPostChangeExpectedPaymentSetting505(): NonNullable<PostChangeExpectedPaymentSetting505> {
  return undefined;
}

export function createPostChangeExpectedPaymentSettingMutationRequest(): NonNullable<PostChangeExpectedPaymentSettingMutationRequest> {
  return createChangeExpectedPaymentSettingPostRequest();
}

/**
 * @description 200 response
 */
export function createPostChangeExpectedPaymentSettingMutationResponse(): NonNullable<PostChangeExpectedPaymentSettingMutationResponse> {
  return createChangeExpectedPaymentSettingPostResponse();
}
