import { z } from 'zod';

export const referralsGetResponseSchema = z.object({
  data: z.object({
    company: z
      .object({ pending_referrals: z.number(), total_referral_credit: z.string(), total_referrals: z.number() })
      .optional(),
    referral_link: z.string().optional(),
    show_referrals: z.boolean().optional(),
  }),
  response: z.object({ date: z.string(), messages: z.array(z.string()), status: z.number(), type: z.string() }),
});
