import { getErrorMessage, HttpError, isHttpError, StatusCodes } from '@repo/utils';
import React from 'react';

export type StatusHandler = (info: { error: HttpError }) => React.ReactNode | null;

export type GeneralErrorBoundaryProps = {
  error: unknown;
  defaultStatusHandler?: StatusHandler;
  statusHandlers?: Partial<Record<StatusCodes, StatusHandler>>;
  unexpectedErrorHandler?: (error: unknown) => JSX.Element | null;
};

export function DefaultStatusHandler({ error }: { error: HttpError }) {
  return <p className="text-red-500">{getErrorMessage(error)}</p>;
}

export function GeneralErrorBoundary({
  error,
  defaultStatusHandler = DefaultStatusHandler,
  statusHandlers,
  unexpectedErrorHandler = (err) => <p className="text-red-500">{getErrorMessage(err)}</p>,
}: GeneralErrorBoundaryProps) {
  return isHttpError(error)
    ? (statusHandlers?.[error.code] ?? defaultStatusHandler)({
        error,
      })
    : unexpectedErrorHandler(error);
}
