import { http } from 'msw';

import { createGetUserPoolDetailsQueryResponse } from '../mocks/createGetUserPoolDetails';

export const getUserPoolDetailsHandler = http.get('*/user_pool_details', function handler(info) {
  return new Response(JSON.stringify(createGetUserPoolDetailsQueryResponse()), {
    headers: {
      'Content-Type': 'application/json',
    },
  });
});
