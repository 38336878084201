import { http } from 'msw';

import { createPostDeleteAccountMutationResponse } from '../mocks/createPostDeleteAccount';

export const postDeleteAccountHandler = http.post('*/delete_account', function handler(info) {
  return new Response(JSON.stringify(createPostDeleteAccountMutationResponse()), {
    headers: {
      'Content-Type': 'application/json',
    },
  });
});
