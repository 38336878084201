import { http } from 'msw';

import { createPostForgotPasswordConfirmMutationResponse } from '../mocks/createPostForgotPasswordConfirm';

export const postForgotPasswordConfirmHandler = http.post('*/forgot_password_confirm', function handler(info) {
  return new Response(JSON.stringify(createPostForgotPasswordConfirmMutationResponse()), {
    headers: {
      'Content-Type': 'application/json',
    },
  });
});
