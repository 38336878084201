import { faker } from '@faker-js/faker';

import type { AffordabilityCalculatorAuthenticatedPostResponse } from '../types/AffordabilityCalculatorAuthenticatedPostResponse';

export function createAffordabilityCalculatorAuthenticatedPostResponse(
  data: NonNullable<Partial<AffordabilityCalculatorAuthenticatedPostResponse>> = {}
): NonNullable<AffordabilityCalculatorAuthenticatedPostResponse> {
  return {
    ...{
      data: {
        annual_income: faker.string.alpha(),
        deposit: faker.string.alpha(),
        finance_amount: faker.string.alpha(),
        monthly_affordability: faker.string.alpha(),
        monthly_spend: faker.string.alpha(),
        net_monthly_income: faker.string.alpha(),
      },
      response: {
        date: faker.string.alpha(),
        messages: faker.helpers.arrayElements([faker.string.alpha()]) as any,
        status: faker.number.int(),
        type: faker.string.alpha(),
      },
    },
    ...data,
  };
}
