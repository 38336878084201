import { MifeParcel } from '@/components/MifeParcel';
import { isTenant } from '@repo/utils';
import { createFileRoute } from '@tanstack/react-router';

export function canAccessAccountSettings() {
  return isTenant('pfida');
}

export const Route = createFileRoute('/_authenticated/_pfida/account-settings')({
  component: () => <MifeParcel />,
});
