import { z } from 'zod';

import { toggleDividendsReinvestPostRequestSchema } from './toggleDividendsReinvestPostRequestSchema';
import { toggleDividendsReinvestPostResponseSchema } from './toggleDividendsReinvestPostResponseSchema';

/**
 * @description 200 response
 */
export const postToggleDividendsReinvest200Schema = z.lazy(() => toggleDividendsReinvestPostResponseSchema);
/**
 * @description 505 response
 */
export const postToggleDividendsReinvest505Schema = z.any();

export const postToggleDividendsReinvestMutationRequestSchema = z.lazy(() => toggleDividendsReinvestPostRequestSchema);
/**
 * @description 200 response
 */
export const postToggleDividendsReinvestMutationResponseSchema = z.lazy(
  () => toggleDividendsReinvestPostResponseSchema
);
