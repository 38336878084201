import type {
  PostChangeRetirementDate200,
  PostChangeRetirementDate505,
  PostChangeRetirementDateMutationRequest,
  PostChangeRetirementDateMutationResponse,
} from '../types/PostChangeRetirementDate';
import { createChangeRetirementDatePostRequest } from './createChangeRetirementDatePostRequest';
import { createChangeRetirementDatePostResponse } from './createChangeRetirementDatePostResponse';

/**
 * @description 200 response
 */
export function createPostChangeRetirementDate200(): NonNullable<PostChangeRetirementDate200> {
  return createChangeRetirementDatePostResponse();
}

/**
 * @description 505 response
 */
export function createPostChangeRetirementDate505(): NonNullable<PostChangeRetirementDate505> {
  return undefined;
}

export function createPostChangeRetirementDateMutationRequest(): NonNullable<PostChangeRetirementDateMutationRequest> {
  return createChangeRetirementDatePostRequest();
}

/**
 * @description 200 response
 */
export function createPostChangeRetirementDateMutationResponse(): NonNullable<PostChangeRetirementDateMutationResponse> {
  return createChangeRetirementDatePostResponse();
}
