import type {
  PostDownloadTransactionsStatement200,
  PostDownloadTransactionsStatement505,
  PostDownloadTransactionsStatementMutationRequest,
  PostDownloadTransactionsStatementMutationResponse,
} from '../types/PostDownloadTransactionsStatement';
import { createDownloadTransactionsStatementPostRequest } from './createDownloadTransactionsStatementPostRequest';
import { createDownloadTransactionsStatementPostResponse } from './createDownloadTransactionsStatementPostResponse';

/**
 * @description 200 response
 */
export function createPostDownloadTransactionsStatement200(): NonNullable<PostDownloadTransactionsStatement200> {
  return createDownloadTransactionsStatementPostResponse();
}

/**
 * @description 505 response
 */
export function createPostDownloadTransactionsStatement505(): NonNullable<PostDownloadTransactionsStatement505> {
  return undefined;
}

export function createPostDownloadTransactionsStatementMutationRequest(): NonNullable<PostDownloadTransactionsStatementMutationRequest> {
  return createDownloadTransactionsStatementPostRequest();
}

/**
 * @description 200 response
 */
export function createPostDownloadTransactionsStatementMutationResponse(): NonNullable<PostDownloadTransactionsStatementMutationResponse> {
  return createDownloadTransactionsStatementPostResponse();
}
