import { z } from 'zod';

import { changeZakatDateGetResponseSchema } from './changeZakatDateGetResponseSchema';

/**
 * @description 200 response
 */
export const getChangeZakatDate200Schema = z.lazy(() => changeZakatDateGetResponseSchema);
/**
 * @description 505 response
 */
export const getChangeZakatDate505Schema = z.any();
/**
 * @description 200 response
 */
export const getChangeZakatDateQueryResponseSchema = z.lazy(() => changeZakatDateGetResponseSchema);
