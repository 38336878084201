import { z } from 'zod';

import { oneOffPaymentNoticePostRequestSchema } from './oneOffPaymentNoticePostRequestSchema';
import { oneOffPaymentNoticePostResponseSchema } from './oneOffPaymentNoticePostResponseSchema';

/**
 * @description 200 response
 */
export const postOneOffPaymentNotice200Schema = z.lazy(() => oneOffPaymentNoticePostResponseSchema);
/**
 * @description 505 response
 */
export const postOneOffPaymentNotice505Schema = z.any();

export const postOneOffPaymentNoticeMutationRequestSchema = z.lazy(() => oneOffPaymentNoticePostRequestSchema);
/**
 * @description 200 response
 */
export const postOneOffPaymentNoticeMutationResponseSchema = z.lazy(() => oneOffPaymentNoticePostResponseSchema);
