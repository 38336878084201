import type {
  PostEligibility200,
  PostEligibility505,
  PostEligibilityMutationRequest,
  PostEligibilityMutationResponse,
} from '../types/PostEligibility';
import { createEligibilityPostRequest } from './createEligibilityPostRequest';
import { createEligibilityPostResponse } from './createEligibilityPostResponse';

/**
 * @description 200 response
 */
export function createPostEligibility200(): NonNullable<PostEligibility200> {
  return createEligibilityPostResponse();
}

/**
 * @description 505 response
 */
export function createPostEligibility505(): NonNullable<PostEligibility505> {
  return undefined;
}

export function createPostEligibilityMutationRequest(): NonNullable<PostEligibilityMutationRequest> {
  return createEligibilityPostRequest();
}

/**
 * @description 200 response
 */
export function createPostEligibilityMutationResponse(): NonNullable<PostEligibilityMutationResponse> {
  return createEligibilityPostResponse();
}
