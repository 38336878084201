import { z } from 'zod';

import { toggleMfaGetResponseSchema } from './toggleMfaGetResponseSchema';

/**
 * @description 200 response
 */
export const getToggleMfa200Schema = z.lazy(() => toggleMfaGetResponseSchema);
/**
 * @description 505 response
 */
export const getToggleMfa505Schema = z.any();
/**
 * @description 200 response
 */
export const getToggleMfaQueryResponseSchema = z.lazy(() => toggleMfaGetResponseSchema);
