import type { GetHearAbout200, GetHearAbout505, GetHearAboutQueryResponse } from '../types/GetHearAbout';
import { createHearAboutGetResponse } from './createHearAboutGetResponse';

/**
 * @description 200 response
 */
export function createGetHearAbout200(): NonNullable<GetHearAbout200> {
  return createHearAboutGetResponse();
}

/**
 * @description 505 response
 */
export function createGetHearAbout505(): NonNullable<GetHearAbout505> {
  return undefined;
}

/**
 * @description 200 response
 */
export function createGetHearAboutQueryResponse(): NonNullable<GetHearAboutQueryResponse> {
  return createHearAboutGetResponse();
}
