import { z } from 'zod';

export const updateProfilePostResponseSchema = z.object({
  data: z.object({
    address: z.string().optional(),
    birthdate: z.string().optional(),
    city: z.string().optional(),
    email: z.string().optional(),
    name: z.string().optional(),
    phone_number: z.string().optional(),
    postcode: z.string().optional(),
  }),
  response: z.object({ date: z.string(), messages: z.array(z.string()), status: z.number(), type: z.string() }),
});
