import { InitialLoading } from '@/components/initialLoading';
import { useAuth } from '@/features/auth/store/authStore';
import { useIsReady } from '@/hooks/isReady';
import { createFileRoute, Navigate } from '@tanstack/react-router';
import { z } from 'zod';

const RootSearchQueryParamsValidation = z.object({
  referral: z.string().optional(),
  company: z.string().optional(),
});

export const Route = createFileRoute('/')({
  validateSearch: RootSearchQueryParamsValidation,
  component: Index,
});

function Index() {
  const status = useAuth.use.status();
  const isReady = useIsReady([status !== 'idle']);

  return isReady ? <Navigate to="/dashboard" /> : <InitialLoading />;
}
