import { http } from 'msw';

import { createPostMarketingPreferencesMutationResponse } from '../mocks/createPostMarketingPreferences';

export const postMarketingPreferencesHandler = http.post('*/marketing_preferences', function handler(info) {
  return new Response(JSON.stringify(createPostMarketingPreferencesMutationResponse()), {
    headers: {
      'Content-Type': 'application/json',
    },
  });
});
