import { faker } from '@faker-js/faker';

import type { CostCalculatorGetResponse } from '../types/CostCalculatorGetResponse';

export function createCostCalculatorGetResponse(
  data: NonNullable<Partial<CostCalculatorGetResponse>> = {}
): NonNullable<CostCalculatorGetResponse> {
  return {
    ...{
      data: { show_cost_calculator: faker.datatype.boolean(), show_rental_rates: faker.datatype.boolean() },
      response: {
        date: faker.string.alpha(),
        messages: faker.helpers.arrayElements([faker.string.alpha()]) as any,
        status: faker.number.int(),
        type: faker.string.alpha(),
      },
    },
    ...data,
  };
}
