import { useAuth } from '@/features/auth/store/authStore';
import { zodResolver } from '@hookform/resolvers/zod';
import { parseDate } from '@internationalized/date';
import {
  Button,
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
  DateInput,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  RadioGroup,
  RadioGroupItem,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Separator,
} from '@pfida-finance/ui/components';
import { invariant } from '@repo/utils';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { trueFalseEnum, yesNoEnum } from '../../constants/schema/common';
import {
  DetailsSchema,
  detailsSchema,
  maritalStatusEnum,
  relationshipEnum,
  residencyStatusEnum,
  residentialStatusEnum,
} from '../../constants/schema/detailsSchema';
import { useDipApplicationFormContext } from '../../context/dipApplicationFormContext';
import { onNumberInputChange } from '../../utils/onNumberInputChange';
import { Error } from '../error';

export function Details() {
  const user = useAuth.use.user();
  invariant(user, 'Error fetching user details');

  const { changeCurrentStep, formState, updateFormState, currentPageNumber } = useDipApplicationFormContext();

  const form = useForm<DetailsSchema>({
    resolver: zodResolver(detailsSchema),
    defaultValues: {
      name: user.name,
      dateOfBirth: user.birthdate,
      email: user.email,
      phoneNumber: user.phone_number,
      address: user.address?.formatted,
      city: user['custom:city'],
      postcode: user['custom:postcode'],
      ...formState.userDetails,
    },
  });

  const residencyStatus = form.watch('residencyStatus');
  const applicationTwoResidencyStatus = form.watch('applicantTwoResidencyStatus');
  const hasSecondApplicant = form.watch('hasSecondApplicant');
  const hasDependents = form.watch('hasDependents');

  useEffect(() => {
    if (residencyStatus !== 'Other') {
      form.setValue('residencyStatusOther', undefined);
    }
  }, [form, residencyStatus]);

  useEffect(() => {
    if (applicationTwoResidencyStatus !== 'Other') {
      form.setValue('applicantTwoResidencyStatusOther', undefined);
    }
  }, [form, applicationTwoResidencyStatus]);

  useEffect(() => {
    if (hasSecondApplicant === 'false') {
      form.setValue('applicantTwoAuthorityToSubmit', undefined);
      form.setValue('applicantTwoRelationship', undefined);
      form.setValue('applicantTwoSameAddress', undefined);
      form.setValue('applicantTwoDateOfBirth', undefined);
      form.setValue('applicantTwoResidencyStatus', undefined);
      form.setValue('applicantTwoResidentialStatus', undefined);
      form.setValue('applicantTwoMaritalStatus', undefined);
    }
  }, [form, hasSecondApplicant]);

  useEffect(() => {
    if (hasDependents === 'false') {
      form.setValue('numberOfDependents', undefined);
    }
  }, [form, hasDependents]);

  const onSubmit = (data: DetailsSchema) => {
    updateFormState('userDetails', data);

    if (hasSecondApplicant === 'false') {
      updateFormState('applicantTwoEmploymentDetails', undefined);
    }

    changeCurrentStep('applicantOneEmploymentDetails', currentPageNumber + 1);
  };

  return (
    <Card className="flex flex-col w-full p-8 gap-5">
      <CardHeader className="p-0 space-y-0 gap-2">
        <CardTitle className="flex items-center gap-2 text-lg font-semibold">
          <div className="flex items-center justify-center text-sm font-bold h-6 w-6 rounded-full bg-accent text-accent-foreground">
            2
          </div>
          Your details
        </CardTitle>
        <div className="py-4">
          <Separator />
        </div>
      </CardHeader>
      <Form {...form}>
        <form className="flex flex-col gap-5" onSubmit={form.handleSubmit(onSubmit)}>
          <CardContent className="grid md:grid-cols-2 p-0 gap-10">
            <FormField
              control={form.control}
              name="name"
              render={() => (
                <FormItem>
                  <FormLabel>Name</FormLabel>
                  <FormLabel className="block text-muted-foreground">{user.name}</FormLabel>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="dateOfBirth"
              render={() => (
                <FormItem>
                  <FormLabel>Date of birth</FormLabel>
                  <FormLabel className="block text-muted-foreground">{user.birthdate}</FormLabel>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="email"
              render={() => (
                <FormItem>
                  <FormLabel>Email address</FormLabel>
                  <FormLabel className="block text-muted-foreground">{user.email}</FormLabel>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="phoneNumber"
              render={() => (
                <FormItem>
                  <FormLabel>Phone number</FormLabel>
                  <FormLabel className="block text-muted-foreground">{user.phone_number}</FormLabel>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="address"
              render={({ field, fieldState }) => (
                <FormItem className={user.address?.formatted ? undefined : 'md:col-span-2'}>
                  <FormLabel>Address</FormLabel>
                  {user.address?.formatted ? (
                    <FormLabel className="block text-muted-foreground">{user.address?.formatted}</FormLabel>
                  ) : (
                    <Input placeholder="e.g. 123 Main Street" {...field} invalid={fieldState.invalid} />
                  )}
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="city"
              render={({ field, fieldState }) => (
                <FormItem>
                  <FormLabel>City</FormLabel>
                  {user['custom:city'] ? (
                    <FormLabel className="block text-muted-foreground">{user['custom:city']}</FormLabel>
                  ) : (
                    <Input placeholder="Enter your city" {...field} invalid={fieldState.invalid} />
                  )}
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="postcode"
              render={({ field, fieldState }) => (
                <FormItem className={user['custom:postcode'] ? 'md:col-span-2' : undefined}>
                  <FormLabel>Postcode</FormLabel>
                  {user['custom:postcode'] ? (
                    <FormLabel className="block text-muted-foreground">{user['custom:postcode']}</FormLabel>
                  ) : (
                    <Input placeholder="Enter your postcode" {...field} invalid={fieldState.invalid} />
                  )}
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="grid md:col-span-2 gap-2">
              <FormField
                control={form.control}
                name="residencyStatus"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel>Residency status</FormLabel>
                    <Select onValueChange={field.onChange} defaultValue={field.value}>
                      <SelectTrigger invalid={fieldState.invalid}>
                        <SelectValue placeholder="Select your residency status" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value={residencyStatusEnum.Enum['British Citizen']}>
                          {residencyStatusEnum.Enum['British Citizen']}
                        </SelectItem>
                        <SelectItem value={residencyStatusEnum.Enum['Indefinite Leave to Remain']}>
                          {residencyStatusEnum.Enum['Indefinite Leave to Remain']}
                        </SelectItem>
                        <SelectItem value={residencyStatusEnum.Enum.Expat}>{residencyStatusEnum.Enum.Expat}</SelectItem>
                        <SelectItem value={residencyStatusEnum.Enum.Other}>{residencyStatusEnum.Enum.Other}</SelectItem>
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
              {residencyStatus === 'Other' && (
                <FormField
                  control={form.control}
                  name="residencyStatusOther"
                  render={({ field, fieldState }) => (
                    <FormItem>
                      <Input placeholder="Enter residency status" invalid={fieldState.invalid} {...field} />
                      <FormMessage />
                    </FormItem>
                  )}
                />
              )}
            </div>
            <FormField
              control={form.control}
              name="residentialStatus"
              render={({ field, fieldState }) => (
                <FormItem className="md:col-span-2">
                  <FormLabel>Residential status</FormLabel>
                  <Select onValueChange={field.onChange} defaultValue={field.value}>
                    <SelectTrigger invalid={fieldState.invalid}>
                      <SelectValue placeholder="Select your residential status" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value={residentialStatusEnum.Enum.Renting}>
                        {residentialStatusEnum.Enum.Renting}
                      </SelectItem>
                      <SelectItem value={residentialStatusEnum.Enum['Living with friends and family']}>
                        {residentialStatusEnum.Enum['Living with friends and family']}
                      </SelectItem>
                      <SelectItem value={residentialStatusEnum.Enum['Own home']}>
                        {residentialStatusEnum.Enum['Own home']}
                      </SelectItem>
                      <SelectItem value={residentialStatusEnum.Enum['Own home with finance']}>
                        {residentialStatusEnum.Enum['Own home with finance']}
                      </SelectItem>
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="maritalStatus"
              render={({ field, fieldState }) => (
                <FormItem className="md:col-span-2">
                  <FormLabel>Marital status</FormLabel>
                  <Select onValueChange={field.onChange} defaultValue={field.value}>
                    <SelectTrigger invalid={fieldState.invalid}>
                      <SelectValue placeholder="Select your marital status" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value={maritalStatusEnum.Enum.Single}>Single</SelectItem>
                      <SelectItem value={maritalStatusEnum.Enum['Married / Civil Partnership']}>
                        {maritalStatusEnum.Enum['Married / Civil Partnership']}
                      </SelectItem>
                      <SelectItem value={maritalStatusEnum.Enum.Widowed}>{maritalStatusEnum.Enum.Widowed}</SelectItem>
                      <SelectItem value={maritalStatusEnum.Enum['Divorced / Ex-civil Partnership']}>
                        {maritalStatusEnum.Enum['Divorced / Ex-civil Partnership']}
                      </SelectItem>
                      <SelectItem value={maritalStatusEnum.Enum.Engaged}>{maritalStatusEnum.Enum.Engaged}</SelectItem>
                      <SelectItem value={maritalStatusEnum.Enum.Separated}>
                        {maritalStatusEnum.Enum.Separated}
                      </SelectItem>
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="hasSecondApplicant"
              render={({ field, fieldState }) => (
                <FormItem className="flex flex-col gap-3 space-y-0">
                  <FormLabel className="leading-5">Will there be a second applicant?</FormLabel>
                  <FormControl>
                    <RadioGroup
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                      invalid={fieldState.invalid}
                      className="flex flex-col gap-2"
                    >
                      <FormItem className="flex items-center gap-2 space-y-0">
                        <FormControl>
                          <RadioGroupItem value={trueFalseEnum.enum.true} />
                        </FormControl>
                        <FormLabel className="leading-5">{yesNoEnum.Enum.Yes}</FormLabel>
                      </FormItem>
                      <FormItem className="flex items-center gap-2 space-y-0">
                        <FormControl>
                          <RadioGroupItem value={trueFalseEnum.enum.false} />
                        </FormControl>
                        <FormLabel className="leading-5">{yesNoEnum.Enum.No}</FormLabel>
                      </FormItem>
                    </RadioGroup>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            {hasSecondApplicant === 'true' && (
              <>
                <FormField
                  control={form.control}
                  name="applicantTwoAuthorityToSubmit"
                  render={({ field, fieldState }) => (
                    <FormItem className="md:col-span-2">
                      <FormLabel className="leading-5">
                        Do you have the authority to submit this form for Applicant 2?
                      </FormLabel>
                      <FormControl>
                        <RadioGroup
                          onValueChange={field.onChange}
                          defaultValue={field.value}
                          invalid={fieldState.invalid}
                          className="flex flex-col gap-2"
                        >
                          <FormItem className="flex items-center gap-2 space-y-0">
                            <FormControl>
                              <RadioGroupItem value={trueFalseEnum.enum.true} />
                            </FormControl>
                            <FormLabel className="leading-5">{yesNoEnum.Enum.Yes}</FormLabel>
                          </FormItem>
                          <FormItem className="flex items-center gap-2 space-y-0">
                            <FormControl>
                              <RadioGroupItem value={trueFalseEnum.enum.false} />
                            </FormControl>
                            <FormLabel className="leading-5">{yesNoEnum.Enum.No}</FormLabel>
                          </FormItem>
                        </RadioGroup>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="applicantTwoRelationship"
                  render={({ field, fieldState }) => (
                    <FormItem className="md:col-span-2">
                      <FormLabel>Specify your relationship to Applicant 2</FormLabel>
                      <Select onValueChange={field.onChange} defaultValue={field.value}>
                        <SelectTrigger invalid={fieldState.invalid}>
                          <SelectValue placeholder="Select relationship to Applicant 2" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value={relationshipEnum.Enum['Spouse / Civil Partner']}>
                            {relationshipEnum.Enum['Spouse / Civil Partner']}
                          </SelectItem>
                          <SelectItem value={relationshipEnum.Enum['Fiancé / Fiancée']}>
                            {relationshipEnum.Enum['Fiancé / Fiancée']}
                          </SelectItem>
                          <SelectItem value={relationshipEnum.Enum.Parent}>{relationshipEnum.Enum.Parent}</SelectItem>
                          <SelectItem value={relationshipEnum.Enum.Child}>{relationshipEnum.Enum.Child}</SelectItem>
                          <SelectItem value={relationshipEnum.Enum.Sibling}>{relationshipEnum.Enum.Sibling}</SelectItem>
                          <SelectItem value={relationshipEnum.Enum.Friend}>{relationshipEnum.Enum.Friend}</SelectItem>
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="applicantTwoSameAddress"
                  render={({ field, fieldState }) => (
                    <FormItem className="flex flex-col gap-3 space-y-0 md:col-span-2">
                      <div className="flex flex-col gap-1">
                        <FormLabel className="leading-5">Do you share the same address as Applicant 2?</FormLabel>
                      </div>
                      <FormControl>
                        <RadioGroup
                          onValueChange={field.onChange}
                          defaultValue={field.value}
                          className="flex flex-col gap-2"
                          invalid={fieldState.invalid}
                        >
                          <FormItem className="flex items-center gap-2 space-y-0">
                            <FormControl>
                              <RadioGroupItem value={trueFalseEnum.enum.true} />
                            </FormControl>
                            <FormLabel className="leading-5">{yesNoEnum.Enum.Yes}</FormLabel>
                          </FormItem>
                          <FormItem className="flex items-center gap-2 space-y-0">
                            <FormControl>
                              <RadioGroupItem value={trueFalseEnum.enum.false} />
                            </FormControl>
                            <FormLabel className="leading-5">{yesNoEnum.Enum.No}</FormLabel>
                          </FormItem>
                        </RadioGroup>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="applicantTwoDateOfBirth"
                  render={({ field, fieldState }) => (
                    <FormItem>
                      <FormLabel className="leading-5">Applicant 2: Date of birth</FormLabel>
                      <DateInput
                        onChange={(value) => field.onChange(value.toString())}
                        defaultValue={field.value ? parseDate(field.value) : undefined}
                        invalid={fieldState.invalid}
                      />
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <div className="flex flex-col md:col-span-2 gap-2">
                  <FormField
                    control={form.control}
                    name="applicantTwoResidencyStatus"
                    render={({ field, fieldState }) => (
                      <FormItem>
                        <FormLabel>Applicant 2: Residency status</FormLabel>
                        <Select onValueChange={field.onChange} defaultValue={field.value}>
                          <SelectTrigger invalid={fieldState.invalid}>
                            <SelectValue placeholder="Select residency status" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItem value="British Citizen">British Citizen</SelectItem>
                            <SelectItem value="Indefinite Leave to Remain">Indefinite Leave to Remain</SelectItem>
                            <SelectItem value="Expat">Expat</SelectItem>
                            <SelectItem value="Other">Other</SelectItem>
                          </SelectContent>
                        </Select>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  {applicationTwoResidencyStatus === 'Other' && (
                    <FormField
                      control={form.control}
                      name="applicantTwoResidencyStatusOther"
                      render={({ field, fieldState }) => (
                        <FormItem>
                          <Input placeholder="Enter residency status" invalid={fieldState.invalid} {...field} />
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  )}
                </div>
                <FormField
                  control={form.control}
                  name="applicantTwoResidentialStatus"
                  render={({ field, fieldState }) => (
                    <FormItem className="md:col-span-2">
                      <FormLabel>Applicant 2: Residential status</FormLabel>
                      <Select onValueChange={field.onChange} defaultValue={field.value}>
                        <SelectTrigger invalid={fieldState.invalid}>
                          <SelectValue placeholder="Select residential status" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value="Renting">Renting</SelectItem>
                          <SelectItem value="Living with friends and family">Living with friends and family</SelectItem>
                          <SelectItem value="Own home">Own home</SelectItem>
                          <SelectItem value="Own home with finance">Own home with finance</SelectItem>
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="applicantTwoMaritalStatus"
                  render={({ field, fieldState }) => (
                    <FormItem className="md:col-span-2">
                      <FormLabel>Applicant 2: Marital status</FormLabel>
                      <Select onValueChange={field.onChange} defaultValue={field.value}>
                        <SelectTrigger invalid={fieldState.invalid}>
                          <SelectValue placeholder="Select marital status" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value="Single">Single</SelectItem>
                          <SelectItem value="Married / Civil Partnership">Married / Civil Partnership</SelectItem>
                          <SelectItem value="Widowed">Widowed</SelectItem>
                          <SelectItem value="Divorced / Ex-civil Partnership">
                            Divorced / Ex-civil Partnership
                          </SelectItem>
                          <SelectItem value="Engaged">Engaged</SelectItem>
                          <SelectItem value="Separated">Separated</SelectItem>
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </>
            )}
            <FormField
              control={form.control}
              name="hasDependents"
              render={({ field, fieldState }) => (
                <FormItem className="flex flex-col gap-3 md:col-span-2 space-y-0">
                  <div className="flex flex-col gap-1">
                    <FormLabel className="leading-5">Do you or any other applicant have any dependents?</FormLabel>
                    <FormLabel className="leading-5 text-muted-foreground">
                      e.g. This could be a child or relative who requires care
                    </FormLabel>
                  </div>
                  <FormControl>
                    <RadioGroup
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                      invalid={fieldState.invalid}
                      className="flex flex-col gap-2"
                    >
                      <FormItem className="flex items-center gap-2 space-y-0">
                        <FormControl>
                          <RadioGroupItem value={trueFalseEnum.enum.true} />
                        </FormControl>
                        <FormLabel className="leading-5">{yesNoEnum.Enum.Yes}</FormLabel>
                      </FormItem>
                      <FormItem className="flex items-center gap-2 space-y-0">
                        <FormControl>
                          <RadioGroupItem value={trueFalseEnum.enum.false} />
                        </FormControl>
                        <FormLabel className="leading-5">{yesNoEnum.Enum.No}</FormLabel>
                      </FormItem>
                    </RadioGroup>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            {hasDependents === 'true' && (
              <FormField
                control={form.control}
                name="numberOfDependents"
                render={({ field, fieldState }) => (
                  <FormItem className="md:col-span-2">
                    <FormLabel>Number of dependents</FormLabel>
                    <Input
                      placeholder="e.g. 1"
                      invalid={fieldState.invalid}
                      {...field}
                      onChange={(e) => onNumberInputChange(e, field)}
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />
            )}
            {Object.keys(form.formState.errors).length > 0 && <Error />}
          </CardContent>
          <Separator className="my-4" />
          <CardFooter className="flex flex-col-reverse p-0 gap-3 md:flex-row md:justify-end">
            <Button
              variant="outline"
              className="w-full md:w-auto"
              size="lg"
              onClick={() => changeCurrentStep('timeFrame', currentPageNumber - 1)}
            >
              Back
            </Button>
            <Button type="submit" className="w-full md:w-auto" size="lg">
              Next
            </Button>
          </CardFooter>
        </form>
      </Form>
    </Card>
  );
}
