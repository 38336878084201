import { faker } from '@faker-js/faker';

import type {
  GetRevolutOpenBankingCallback200,
  GetRevolutOpenBankingCallback505,
  GetRevolutOpenBankingCallbackQueryParams,
  GetRevolutOpenBankingCallbackQueryResponse,
} from '../types/GetRevolutOpenBankingCallback';
import { createRevolutOpenBankingCallbackGetResponse } from './createRevolutOpenBankingCallbackGetResponse';

export function createGetRevolutOpenBankingCallbackQueryParams(): NonNullable<GetRevolutOpenBankingCallbackQueryParams> {
  return { company: faker.string.alpha(), code: faker.string.alpha() };
}

/**
 * @description 200 response
 */
export function createGetRevolutOpenBankingCallback200(): NonNullable<GetRevolutOpenBankingCallback200> {
  return createRevolutOpenBankingCallbackGetResponse();
}

/**
 * @description 505 response
 */
export function createGetRevolutOpenBankingCallback505(): NonNullable<GetRevolutOpenBankingCallback505> {
  return undefined;
}

/**
 * @description 200 response
 */
export function createGetRevolutOpenBankingCallbackQueryResponse(): NonNullable<GetRevolutOpenBankingCallbackQueryResponse> {
  return createRevolutOpenBankingCallbackGetResponse();
}
