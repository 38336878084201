import { http } from 'msw';

import { createGetFinanceDetailsQueryResponse } from '../mocks/createGetFinanceDetails';

export const getFinanceDetailsHandler = http.get('*/finance_details', function handler(info) {
  return new Response(JSON.stringify(createGetFinanceDetailsQueryResponse()), {
    headers: {
      'Content-Type': 'application/json',
    },
  });
});
