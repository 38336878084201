import { http } from 'msw';

import { createPostSignUpMutationResponse } from '../mocks/createPostSignUp';

export const postSignUpHandler = http.post('*/sign_up', function handler(info) {
  return new Response(JSON.stringify(createPostSignUpMutationResponse()), {
    headers: {
      'Content-Type': 'application/json',
    },
  });
});
