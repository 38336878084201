import { z } from 'zod';

import { oneOffPaymentNoticeGetResponseSchema } from './oneOffPaymentNoticeGetResponseSchema';

export const getOneOffPaymentNoticeQueryParamsSchema = z.object({ reference: z.string() });
/**
 * @description 200 response
 */
export const getOneOffPaymentNotice200Schema = z.lazy(() => oneOffPaymentNoticeGetResponseSchema);
/**
 * @description 505 response
 */
export const getOneOffPaymentNotice505Schema = z.any();
/**
 * @description 200 response
 */
export const getOneOffPaymentNoticeQueryResponseSchema = z.lazy(() => oneOffPaymentNoticeGetResponseSchema);
