import type {
  PostDeleteAccount200,
  PostDeleteAccount505,
  PostDeleteAccountMutationRequest,
  PostDeleteAccountMutationResponse,
} from '../types/PostDeleteAccount';
import { createDeleteAccountPostRequest } from './createDeleteAccountPostRequest';
import { createDeleteAccountPostResponse } from './createDeleteAccountPostResponse';

/**
 * @description 200 response
 */
export function createPostDeleteAccount200(): NonNullable<PostDeleteAccount200> {
  return createDeleteAccountPostResponse();
}

/**
 * @description 505 response
 */
export function createPostDeleteAccount505(): NonNullable<PostDeleteAccount505> {
  return undefined;
}

export function createPostDeleteAccountMutationRequest(): NonNullable<PostDeleteAccountMutationRequest> {
  return createDeleteAccountPostRequest();
}

/**
 * @description 200 response
 */
export function createPostDeleteAccountMutationResponse(): NonNullable<PostDeleteAccountMutationResponse> {
  return createDeleteAccountPostResponse();
}
