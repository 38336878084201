import { http } from 'msw';

import { createGetMarketingPreferencesQueryResponse } from '../mocks/createGetMarketingPreferences';

export const getMarketingPreferencesHandler = http.get('*/marketing_preferences', function handler(info) {
  return new Response(JSON.stringify(createGetMarketingPreferencesQueryResponse()), {
    headers: {
      'Content-Type': 'application/json',
    },
  });
});
