import { z } from 'zod';

import { changeMonthlyPaymentPostRequestSchema } from './changeMonthlyPaymentPostRequestSchema';
import { changeMonthlyPaymentPostResponseSchema } from './changeMonthlyPaymentPostResponseSchema';

/**
 * @description 200 response
 */
export const postChangeMonthlyPayment200Schema = z.lazy(() => changeMonthlyPaymentPostResponseSchema);
/**
 * @description 505 response
 */
export const postChangeMonthlyPayment505Schema = z.any();

export const postChangeMonthlyPaymentMutationRequestSchema = z.lazy(() => changeMonthlyPaymentPostRequestSchema);
/**
 * @description 200 response
 */
export const postChangeMonthlyPaymentMutationResponseSchema = z.lazy(() => changeMonthlyPaymentPostResponseSchema);
