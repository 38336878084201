import { faker } from '@faker-js/faker';

import type { BankDetailsGetResponse } from '../types/BankDetailsGetResponse';

export function createBankDetailsGetResponse(
  data: NonNullable<Partial<BankDetailsGetResponse>> = {}
): NonNullable<BankDetailsGetResponse> {
  return {
    ...{
      data: { account_number: faker.string.alpha(), sort_code: faker.string.alpha() },
      response: {
        date: faker.string.alpha(),
        messages: faker.helpers.arrayElements([faker.string.alpha()]) as any,
        status: faker.number.int(),
        type: faker.string.alpha(),
      },
    },
    ...data,
  };
}
