import { z } from 'zod';

import { dipPostRequestSchema } from './dipPostRequestSchema';
import { dipPostResponseSchema } from './dipPostResponseSchema';

/**
 * @description 200 response
 */
export const postDip200Schema = z.lazy(() => dipPostResponseSchema);
/**
 * @description 505 response
 */
export const postDip505Schema = z.any();

export const postDipMutationRequestSchema = z.lazy(() => dipPostRequestSchema);
/**
 * @description 200 response
 */
export const postDipMutationResponseSchema = z.lazy(() => dipPostResponseSchema);
