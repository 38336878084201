import LogoSmall from '@repo/assets/brand/images/logo-small';
import { Spinner } from '@repo/ui/components/ui/spinner';

export function InitialLoading() {
  return (
    <div className="flex flex-1 h-[100vh] justify-center items-center">
      <div className="flex flex-col gap-2">
        <img src={String(LogoSmall)} alt="Pfida Logo" className="h-14" />
        <Spinner size="large" />
      </div>
    </div>
  );
}
