import type {
  GetChangeRetirementDate200,
  GetChangeRetirementDate505,
  GetChangeRetirementDateQueryResponse,
} from '../types/GetChangeRetirementDate';
import { createChangeRetirementDateGetResponse } from './createChangeRetirementDateGetResponse';

/**
 * @description 200 response
 */
export function createGetChangeRetirementDate200(): NonNullable<GetChangeRetirementDate200> {
  return createChangeRetirementDateGetResponse();
}

/**
 * @description 505 response
 */
export function createGetChangeRetirementDate505(): NonNullable<GetChangeRetirementDate505> {
  return undefined;
}

/**
 * @description 200 response
 */
export function createGetChangeRetirementDateQueryResponse(): NonNullable<GetChangeRetirementDateQueryResponse> {
  return createChangeRetirementDateGetResponse();
}
