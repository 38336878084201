import { z } from 'zod';

import { verifyUserAttributePostRequestSchema } from './verifyUserAttributePostRequestSchema';
import { verifyUserAttributePostResponseSchema } from './verifyUserAttributePostResponseSchema';

/**
 * @description 200 response
 */
export const postVerifyUserAttribute200Schema = z.lazy(() => verifyUserAttributePostResponseSchema);
/**
 * @description 505 response
 */
export const postVerifyUserAttribute505Schema = z.any();

export const postVerifyUserAttributeMutationRequestSchema = z.lazy(() => verifyUserAttributePostRequestSchema);
/**
 * @description 200 response
 */
export const postVerifyUserAttributeMutationResponseSchema = z.lazy(() => verifyUserAttributePostResponseSchema);
