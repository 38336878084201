import type {
  PostAccountType200,
  PostAccountType505,
  PostAccountTypeMutationRequest,
  PostAccountTypeMutationResponse,
} from '../types/PostAccountType';
import { createAccountTypePostRequest } from './createAccountTypePostRequest';
import { createAccountTypePostResponse } from './createAccountTypePostResponse';

/**
 * @description 200 response
 */
export function createPostAccountType200(): NonNullable<PostAccountType200> {
  return createAccountTypePostResponse();
}

/**
 * @description 505 response
 */
export function createPostAccountType505(): NonNullable<PostAccountType505> {
  return undefined;
}

export function createPostAccountTypeMutationRequest(): NonNullable<PostAccountTypeMutationRequest> {
  return createAccountTypePostRequest();
}

/**
 * @description 200 response
 */
export function createPostAccountTypeMutationResponse(): NonNullable<PostAccountTypeMutationResponse> {
  return createAccountTypePostResponse();
}
