import { faker } from '@faker-js/faker';

import type { ToggleMfaGetResponse } from '../types/ToggleMfaGetResponse';

export function createToggleMfaGetResponse(
  data: NonNullable<Partial<ToggleMfaGetResponse>> = {}
): NonNullable<ToggleMfaGetResponse> {
  return {
    ...{
      data: { mfa_enabled: faker.datatype.boolean() },
      response: {
        date: faker.string.alpha(),
        messages: faker.helpers.arrayElements([faker.string.alpha()]) as any,
        status: faker.number.int(),
        type: faker.string.alpha(),
      },
    },
    ...data,
  };
}
