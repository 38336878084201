import { z } from 'zod';

import { subscriptionFormGetResponseSchema } from './subscriptionFormGetResponseSchema';

/**
 * @description 200 response
 */
export const getSubscriptionForm200Schema = z.lazy(() => subscriptionFormGetResponseSchema);
/**
 * @description 505 response
 */
export const getSubscriptionForm505Schema = z.any();
/**
 * @description 200 response
 */
export const getSubscriptionFormQueryResponseSchema = z.lazy(() => subscriptionFormGetResponseSchema);
