import { MifeParcel } from '@/components/MifeParcel';
import { isTenant } from '@repo/utils';
import { createFileRoute } from '@tanstack/react-router';

export function canAccessDocument() {
  return isTenant('pfida');
}

function Documents() {
  return <MifeParcel />;
}

export const Route = createFileRoute('/_authenticated/_pfida/documents')({
  component: Documents,
});
