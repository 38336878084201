import type {
  PostFinanceRequirements200,
  PostFinanceRequirements505,
  PostFinanceRequirementsMutationRequest,
  PostFinanceRequirementsMutationResponse,
} from '../types/PostFinanceRequirements';
import { createFinanceRequirementsPostRequest } from './createFinanceRequirementsPostRequest';
import { createFinanceRequirementsPostResponse } from './createFinanceRequirementsPostResponse';

/**
 * @description 200 response
 */
export function createPostFinanceRequirements200(): NonNullable<PostFinanceRequirements200> {
  return createFinanceRequirementsPostResponse();
}

/**
 * @description 505 response
 */
export function createPostFinanceRequirements505(): NonNullable<PostFinanceRequirements505> {
  return undefined;
}

export function createPostFinanceRequirementsMutationRequest(): NonNullable<PostFinanceRequirementsMutationRequest> {
  return createFinanceRequirementsPostRequest();
}

/**
 * @description 200 response
 */
export function createPostFinanceRequirementsMutationResponse(): NonNullable<PostFinanceRequirementsMutationResponse> {
  return createFinanceRequirementsPostResponse();
}
