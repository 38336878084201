import type {
  QueryKey,
  QueryObserverOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query';
import { queryOptions, useQuery, useSuspenseQuery } from '@tanstack/react-query';

import type { GetInvestmentDetails505, GetInvestmentDetailsQueryResponse } from '../types/GetInvestmentDetails';
import client from '../../client';

type GetInvestmentDetailsClient = typeof client<GetInvestmentDetailsQueryResponse, GetInvestmentDetails505, never>;
type GetInvestmentDetails = {
  data: GetInvestmentDetailsQueryResponse;
  error: GetInvestmentDetails505;
  request: never;
  pathParams: never;
  queryParams: never;
  headerParams: never;
  response: GetInvestmentDetailsQueryResponse;
  client: {
    parameters: Partial<Parameters<GetInvestmentDetailsClient>[0]>;
    return: Awaited<ReturnType<GetInvestmentDetailsClient>>;
  };
};
export const getInvestmentDetailsQueryKey = () => ['USER_BACKEND', { url: '/investment_details' }] as const;
export type GetInvestmentDetailsQueryKey = ReturnType<typeof getInvestmentDetailsQueryKey>;
export function getInvestmentDetailsQueryOptions(options: GetInvestmentDetails['client']['parameters'] = {}) {
  const queryKey = getInvestmentDetailsQueryKey();
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<GetInvestmentDetails['data'], GetInvestmentDetails['error']>({
        method: 'get',
        url: `/investment_details`,
        ...options,
      });
      return res.data;
    },
  });
}
/**
 * @link /investment_details
 */
export function useGetInvestmentDetails<
  TData = GetInvestmentDetails['response'],
  TQueryData = GetInvestmentDetails['response'],
  TQueryKey extends QueryKey = GetInvestmentDetailsQueryKey,
>(
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetInvestmentDetails['response'],
        GetInvestmentDetails['error'],
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: GetInvestmentDetails['client']['parameters'];
  } = {}
): UseQueryResult<TData, GetInvestmentDetails['error']> & {
  queryKey: TQueryKey;
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getInvestmentDetailsQueryKey();
  const query = useQuery({
    ...(getInvestmentDetailsQueryOptions(clientOptions) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetInvestmentDetails['error']> & {
    queryKey: TQueryKey;
  };
  query.queryKey = queryKey as TQueryKey;
  return query;
}
export const getInvestmentDetailsSuspenseQueryKey = () => ['USER_BACKEND', { url: '/investment_details' }] as const;
export type GetInvestmentDetailsSuspenseQueryKey = ReturnType<typeof getInvestmentDetailsSuspenseQueryKey>;
export function getInvestmentDetailsSuspenseQueryOptions(options: GetInvestmentDetails['client']['parameters'] = {}) {
  const queryKey = getInvestmentDetailsSuspenseQueryKey();
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<GetInvestmentDetails['data'], GetInvestmentDetails['error']>({
        method: 'get',
        url: `/investment_details`,
        ...options,
      });
      return res.data;
    },
  });
}
/**
 * @link /investment_details
 */
export function useGetInvestmentDetailsSuspense<
  TData = GetInvestmentDetails['response'],
  TQueryKey extends QueryKey = GetInvestmentDetailsSuspenseQueryKey,
>(
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<GetInvestmentDetails['response'], GetInvestmentDetails['error'], TData, TQueryKey>
    >;
    client?: GetInvestmentDetails['client']['parameters'];
  } = {}
): UseSuspenseQueryResult<TData, GetInvestmentDetails['error']> & {
  queryKey: TQueryKey;
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getInvestmentDetailsSuspenseQueryKey();
  const query = useSuspenseQuery({
    ...(getInvestmentDetailsSuspenseQueryOptions(clientOptions) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<TData, GetInvestmentDetails['error']> & {
    queryKey: TQueryKey;
  };
  query.queryKey = queryKey as TQueryKey;
  return query;
}
