import { faker } from '@faker-js/faker';

import type { EquityInvestmentPageVisitedPostResponse } from '../types/EquityInvestmentPageVisitedPostResponse';

export function createEquityInvestmentPageVisitedPostResponse(
  data: NonNullable<Partial<EquityInvestmentPageVisitedPostResponse>> = {}
): NonNullable<EquityInvestmentPageVisitedPostResponse> {
  return {
    ...{
      data: {},
      response: {
        date: faker.string.alpha(),
        messages: faker.helpers.arrayElements([faker.string.alpha()]) as any,
        status: faker.number.int(),
        type: faker.string.alpha(),
      },
    },
    ...data,
  };
}
