import type { GetUserDetails200, GetUserDetails505, GetUserDetailsQueryResponse } from '../types/GetUserDetails';
import { createUserDetailsGetResponse } from './createUserDetailsGetResponse';

/**
 * @description 200 response
 */
export function createGetUserDetails200(): NonNullable<GetUserDetails200> {
  return createUserDetailsGetResponse();
}

/**
 * @description 505 response
 */
export function createGetUserDetails505(): NonNullable<GetUserDetails505> {
  return undefined;
}

/**
 * @description 200 response
 */
export function createGetUserDetailsQueryResponse(): NonNullable<GetUserDetailsQueryResponse> {
  return createUserDetailsGetResponse();
}
