import { z } from 'zod';

import { updateProfilePostRequestSchema } from './updateProfilePostRequestSchema';
import { updateProfilePostResponseSchema } from './updateProfilePostResponseSchema';

/**
 * @description 200 response
 */
export const postUpdateProfile200Schema = z.lazy(() => updateProfilePostResponseSchema);
/**
 * @description 505 response
 */
export const postUpdateProfile505Schema = z.any();

export const postUpdateProfileMutationRequestSchema = z.lazy(() => updateProfilePostRequestSchema);
/**
 * @description 200 response
 */
export const postUpdateProfileMutationResponseSchema = z.lazy(() => updateProfilePostResponseSchema);
