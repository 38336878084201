import { z } from 'zod';

import { signUpConfirmPostRequestSchema } from './signUpConfirmPostRequestSchema';
import { signUpConfirmPostResponseSchema } from './signUpConfirmPostResponseSchema';

/**
 * @description 200 response
 */
export const postSignUpConfirm200Schema = z.lazy(() => signUpConfirmPostResponseSchema);
/**
 * @description 505 response
 */
export const postSignUpConfirm505Schema = z.any();

export const postSignUpConfirmMutationRequestSchema = z.lazy(() => signUpConfirmPostRequestSchema);
/**
 * @description 200 response
 */
export const postSignUpConfirmMutationResponseSchema = z.lazy(() => signUpConfirmPostResponseSchema);
