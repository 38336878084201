import { http } from 'msw';

import { createPostIfaApprovalMutationResponse } from '../mocks/createPostIfaApproval';

export const postIfaApprovalHandler = http.post('*/ifa_approval', function handler(info) {
  return new Response(JSON.stringify(createPostIfaApprovalMutationResponse()), {
    headers: {
      'Content-Type': 'application/json',
    },
  });
});
