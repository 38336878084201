import { useEffect, useState } from 'react';

export function useIsReady(conditions: Array<boolean | number | string>): boolean {
  const [ready, setReady] = useState<boolean>(false);

  useEffect(() => {
    setReady(conditions.every(Boolean));
  }, [conditions]);

  return ready;
}
