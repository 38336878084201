import { faker } from '@faker-js/faker';

import type { ChangeExpectedPaymentSettingGetResponse } from '../types/ChangeExpectedPaymentSettingGetResponse';

export function createChangeExpectedPaymentSettingGetResponse(
  data: NonNullable<Partial<ChangeExpectedPaymentSettingGetResponse>> = {}
): NonNullable<ChangeExpectedPaymentSettingGetResponse> {
  return {
    ...{
      data: {
        expected_payment_setting: {
          reference: faker.string.alpha(),
          reset_after_next_payment: faker.datatype.boolean(),
          user_can_change: faker.datatype.boolean(),
          username: faker.string.alpha(),
        },
        settings: faker.helpers.arrayElements([
          { display_name: faker.string.alpha(), setting: faker.string.alpha() },
        ]) as any,
      },
      response: {
        date: faker.string.alpha(),
        messages: faker.helpers.arrayElements([faker.string.alpha()]) as any,
        status: faker.number.int(),
        type: faker.string.alpha(),
      },
    },
    ...data,
  };
}
