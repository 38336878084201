import { faker } from '@faker-js/faker';

import type {
  GetChangeExpectedPaymentSetting200,
  GetChangeExpectedPaymentSetting505,
  GetChangeExpectedPaymentSettingQueryParams,
  GetChangeExpectedPaymentSettingQueryResponse,
} from '../types/GetChangeExpectedPaymentSetting';
import { createChangeExpectedPaymentSettingGetResponse } from './createChangeExpectedPaymentSettingGetResponse';

export function createGetChangeExpectedPaymentSettingQueryParams(): NonNullable<GetChangeExpectedPaymentSettingQueryParams> {
  return { reference: faker.string.alpha() };
}

/**
 * @description 200 response
 */
export function createGetChangeExpectedPaymentSetting200(): NonNullable<GetChangeExpectedPaymentSetting200> {
  return createChangeExpectedPaymentSettingGetResponse();
}

/**
 * @description 505 response
 */
export function createGetChangeExpectedPaymentSetting505(): NonNullable<GetChangeExpectedPaymentSetting505> {
  return undefined;
}

/**
 * @description 200 response
 */
export function createGetChangeExpectedPaymentSettingQueryResponse(): NonNullable<GetChangeExpectedPaymentSettingQueryResponse> {
  return createChangeExpectedPaymentSettingGetResponse();
}
