import { faker } from '@faker-js/faker';

import type { CalculatorHistoryGetResponse } from '../types/CalculatorHistoryGetResponse';

export function createCalculatorHistoryGetResponse(
  data: NonNullable<Partial<CalculatorHistoryGetResponse>> = {}
): NonNullable<CalculatorHistoryGetResponse> {
  return {
    ...{
      data: {
        results: faker.helpers.arrayElements([
          {
            accounts_records: faker.string.alpha(),
            age: faker.number.int(),
            annual_income: faker.number.float(),
            annual_increase: faker.number.float(),
            answer: faker.string.alpha(),
            arrangement_fee: faker.number.float(),
            average_annual_increase: faker.number.float(),
            buy_to_let: faker.datatype.boolean(),
            ccj_bankruptcy: faker.string.alpha(),
            commercial_let: faker.string.alpha(),
            continuous_employment: faker.string.alpha(),
            country: faker.string.alpha(),
            created_at: faker.string.alpha(),
            deposit: faker.number.float(),
            employment_type: faker.string.alpha(),
            finance_amount: faker.number.float(),
            finance_purpose: faker.string.alpha(),
            first_time_buyer: faker.string.alpha(),
            have_inhabited: faker.string.alpha(),
            main_residence: faker.string.alpha(),
            message: faker.string.alpha(),
            monthly_affordability: faker.number.float(),
            monthly_payment: faker.number.float(),
            monthly_spend: faker.number.float(),
            nationality: faker.string.alpha(),
            net_monthly_income: faker.number.float(),
            own_other_lets: faker.string.alpha(),
            problem_params: faker.string.alpha(),
            property_location: faker.string.alpha(),
            property_type: faker.string.alpha(),
            property_value: faker.number.float(),
            term: faker.number.int(),
            total_payment_best_case: faker.number.float(),
            total_payment_mid_range: faker.number.float(),
            total_payment_worst_case: faker.number.float(),
            type: faker.string.alpha(),
            username: faker.string.alpha(),
            will_inhabit: faker.string.alpha(),
          },
        ]) as any,
      },
      response: {
        date: faker.string.alpha(),
        messages: faker.helpers.arrayElements([faker.string.alpha()]) as any,
        status: faker.number.int(),
        type: faker.string.alpha(),
      },
    },
    ...data,
  };
}
