import { http } from 'msw';

import { createGetCostCalculatorAuthenticatedQueryResponse } from '../mocks/createGetCostCalculatorAuthenticated';

export const getCostCalculatorAuthenticatedHandler = http.get(
  '*/cost_calculator_authenticated',
  function handler(info) {
    return new Response(JSON.stringify(createGetCostCalculatorAuthenticatedQueryResponse()), {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
);
