import { faker } from '@faker-js/faker';

import type { FinanceDetailsGetResponse } from '../types/FinanceDetailsGetResponse';

export function createFinanceDetailsGetResponse(
  data: NonNullable<Partial<FinanceDetailsGetResponse>> = {}
): NonNullable<FinanceDetailsGetResponse> {
  return {
    ...{
      data: {
        acquisition_cost: faker.string.alpha(),
        deposit: faker.number.float(),
        equity_released: faker.number.float(),
        expected_payment_setting: faker.number.int(),
        latest_projected_payments: {},
        lease_term_decimal: faker.number.float(),
        lease_term_elapsed_decimal: faker.number.float(),
        lease_term_elapsed_months: faker.number.float(),
        lease_term_elapsed_string: faker.string.alpha(),
        lease_term_elapsed_years: faker.number.float(),
        lease_term_months: faker.number.float(),
        lease_term_string: faker.number.float(),
        lease_term_years: faker.number.float(),
        next_equity_payment: faker.number.float(),
        next_payment_date: faker.string.alpha(),
        next_payment_remaining: faker.string.alpha(),
        next_payment_remaining_unformatted: faker.number.float(),
        next_rent_payment: faker.number.float(),
        next_total_monthly_payment: faker.string.alpha(),
        next_total_monthly_payment_ex_rent_arrears: faker.number.float(),
        next_total_monthly_payment_ex_rent_arrears_formatted: faker.string.alpha(),
        next_total_monthly_payment_ex_rent_arrears_paid: faker.string.alpha(),
        next_total_monthly_payment_progress_percentage: faker.number.float(),
        next_total_monthly_payment_unformatted: faker.number.float(),
        next_total_monthly_recommended_payment: faker.string.alpha(),
        original_projected_payments: {},
        overpaid: faker.number.float(),
        product_name: faker.string.alpha(),
        reference: faker.string.alpha(),
        remaining_finance: faker.string.alpha(),
        remaining_finance_rounded: faker.number.float(),
        remaining_term_decimal: faker.number.float(),
        remaining_term_months: faker.number.float(),
        remaining_term_string: faker.string.alpha(),
        remaining_term_years: faker.number.float(),
        rent_arrears: faker.number.float(),
        rent_arrears_remaining: faker.number.float(),
        rent_arrears_remaining_rounded: faker.number.float(),
        rent_arrears_rounded: faker.number.float(),
        saving: faker.number.float(),
        term_elapsed_decimal: faker.number.float(),
        term_elapsed_months: faker.number.float(),
        term_elapsed_string: faker.string.alpha(),
        term_elapsed_years: faker.number.float(),
        total_actually_paid: faker.string.alpha(),
        total_equity: faker.string.alpha(),
        total_equity_without_deposit: faker.string.alpha(),
        total_equity_without_deposit_rounded: faker.number.float(),
        total_expected_paid: faker.string.alpha(),
        total_payments_made: faker.string.alpha(),
        total_payments_made_ex_rent_arrears: faker.string.alpha(),
        total_payments_made_ex_rent_arrears_unformatted: faker.number.float(),
        total_payments_made_unformatted: faker.number.float(),
        total_rent: faker.string.alpha(),
        total_term_decimal: faker.number.float(),
        total_term_months: faker.number.float(),
        total_term_string: faker.string.alpha(),
        total_term_years: faker.number.float(),
        transaction_history: faker.helpers.arrayElements([
          {
            actual_payment: faker.string.alpha(),
            date: faker.string.alpha(),
            equity: faker.string.alpha(),
            expected_payment: faker.string.alpha(),
            late: faker.datatype.boolean(),
            outstanding_equity_amount: faker.string.alpha(),
            rent: faker.string.alpha(),
            type: faker.string.alpha(),
          },
        ]) as any,
        unexpected_payment_outcome: faker.number.int(),
      },
      response: {
        date: faker.string.alpha(),
        messages: faker.helpers.arrayElements([faker.string.alpha()]) as any,
        status: faker.number.int(),
        type: faker.string.alpha(),
      },
    },
    ...data,
  };
}
