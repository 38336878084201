import { z } from 'zod';

import { resendConfirmationCodePostRequestSchema } from './resendConfirmationCodePostRequestSchema';
import { resendConfirmationCodePostResponseSchema } from './resendConfirmationCodePostResponseSchema';

/**
 * @description 200 response
 */
export const postResendConfirmationCode200Schema = z.lazy(() => resendConfirmationCodePostResponseSchema);
/**
 * @description 505 response
 */
export const postResendConfirmationCode505Schema = z.any();

export const postResendConfirmationCodeMutationRequestSchema = z.lazy(() => resendConfirmationCodePostRequestSchema);
/**
 * @description 200 response
 */
export const postResendConfirmationCodeMutationResponseSchema = z.lazy(() => resendConfirmationCodePostResponseSchema);
