import type {
  PostAffordabilityCalculator200,
  PostAffordabilityCalculator505,
  PostAffordabilityCalculatorMutationRequest,
  PostAffordabilityCalculatorMutationResponse,
} from '../types/PostAffordabilityCalculator';
import { createAffordabilityCalculatorPostRequest } from './createAffordabilityCalculatorPostRequest';
import { createAffordabilityCalculatorPostResponse } from './createAffordabilityCalculatorPostResponse';

/**
 * @description 200 response
 */
export function createPostAffordabilityCalculator200(): NonNullable<PostAffordabilityCalculator200> {
  return createAffordabilityCalculatorPostResponse();
}

/**
 * @description 505 response
 */
export function createPostAffordabilityCalculator505(): NonNullable<PostAffordabilityCalculator505> {
  return undefined;
}

export function createPostAffordabilityCalculatorMutationRequest(): NonNullable<PostAffordabilityCalculatorMutationRequest> {
  return createAffordabilityCalculatorPostRequest();
}

/**
 * @description 200 response
 */
export function createPostAffordabilityCalculatorMutationResponse(): NonNullable<PostAffordabilityCalculatorMutationResponse> {
  return createAffordabilityCalculatorPostResponse();
}
