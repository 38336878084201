import { MifeParcel } from '@/components/MifeParcel';
import { useAuth } from '@/features/auth/store/authStore';
import { eventBus } from '@/libs/eventBus';
import { createFileRoute, useNavigate } from '@tanstack/react-router';
import { useEffect } from 'react';

export const Route = createFileRoute('/_unauthenticated/(auth-routes)/login')({
  component: Login,
});

function Login() {
  const search = Route.useSearch();
  const signIn = useAuth.use.signIn();
  const navigate = useNavigate();

  useEffect(() => {
    eventBus.on('mife:login', (event) => {
      signIn(event.IdToken);
      navigate({ to: event.redirectTo ?? search.to });
    });

    return () =>
      eventBus.off('mife:login', (event) => {
        signIn(event.IdToken);
        navigate({ to: event.redirectTo ?? search.to });
      });
  }, [navigate, search.to, signIn]);

  return <MifeParcel />;
}
