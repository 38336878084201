import { z } from 'zod';

/**
 * @description Success response containing the ID of the created application
 */
export const listDipApplications200Schema = z.object({
  data: z.array(
    z.object({
      applicationId: z.string(),
      submittedAt: z.string().datetime().optional(),
      productName: z.string(),
      agreementReference: z.string(),
      status: z.string(),
    })
  ),
  status: z.number(),
});

/**
 * @description Default error response
 */
export const listDipApplications500Schema = z.object({
  message: z.enum(['Internal Server Error']),
  status: z.number(),
});

export const listDipApplicationsQueryResponseSchema = z.lazy(() => listDipApplications200Schema);
