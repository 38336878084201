import { z } from 'zod';

import { affordabilityCalculatorPostRequestSchema } from './affordabilityCalculatorPostRequestSchema';
import { affordabilityCalculatorPostResponseSchema } from './affordabilityCalculatorPostResponseSchema';

/**
 * @description 200 response
 */
export const postAffordabilityCalculator200Schema = z.lazy(() => affordabilityCalculatorPostResponseSchema);
/**
 * @description 505 response
 */
export const postAffordabilityCalculator505Schema = z.any();

export const postAffordabilityCalculatorMutationRequestSchema = z.lazy(() => affordabilityCalculatorPostRequestSchema);
/**
 * @description 200 response
 */
export const postAffordabilityCalculatorMutationResponseSchema = z.lazy(
  () => affordabilityCalculatorPostResponseSchema
);
