import { RouteNotFoundErrorBoundary } from '@/components/errors/routeNotFoundErrorBoundary';
import { createRouter, RouterProvider } from '@tanstack/react-router';

import { RouteErrorBoundary } from '@/components/errors/routeErrorBoundary';
import { routeTree } from '../../routeTree.gen';
import { queryClient } from '../tanstackQuery';

// Create a new router instance
export const router = createRouter({
  routeTree,
  context: {
    queryClient,
  },

  // Fuzzy matching to preserve as much parent layout as possible for the user gives them more context to navigate to a useful location based on where they thought they would arrive.
  notFoundMode: 'fuzzy',
  // This allows preloading routes for the application.
  // Read more here https://tanstack.com/router/latest/docs/framework/react/guide/preloading
  defaultPreload: 'intent',
  // Because Tanstac Query has its own caching mechanism, we don't want Tanstack Router to handle this for us
  // We can let Tanstack Query to control the freshness of preloads by using `staleTime`
  // https://tanstack.com/router/latest/docs/framework/react/guide/preloading#preloading-with-external-libraries
  defaultPreloadStaleTime: 0,
  defaultErrorComponent: RouteErrorBoundary,
  defaultNotFoundComponent: RouteNotFoundErrorBoundary,
});

// Register the router instance for type safety
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

export function Router() {
  return <RouterProvider router={router} />;
}
