import { http } from 'msw';

import { createPostDipMutationResponse } from '../mocks/createPostDip';

export const postDipHandler = http.post('*/dip', function handler(info) {
  return new Response(JSON.stringify(createPostDipMutationResponse()), {
    headers: {
      'Content-Type': 'application/json',
    },
  });
});
