import { z } from 'zod';

export const listUserObjectsGetResponseSchema = z.object({
  data: z.object({
    any_unread: z.boolean().optional(),
    user_objects_v2: z
      .array(
        z.object({ display_name: z.string(), last_modified: z.string(), relative_key: z.string(), unread: z.boolean() })
      )
      .optional(),
  }),
  response: z.object({ date: z.string(), messages: z.array(z.string()), status: z.number(), type: z.string() }),
});
