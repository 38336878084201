import {
  alertDescriptionStyles,
  alertTitleStyles,
  alertVariants,
  Card,
  Icon,
  type IconProps,
} from '@pfida-finance/ui/components';
import { cn } from '@pfida-finance/ui/utils';

export type ErrorProps = React.ComponentProps<'div'>;

export function Error({ children, className }: ErrorProps) {
  return (
    <div className={cn('flex items-center justify-center p-4', className)}>
      <Card className="w-full max-w-2xl p-6">
        <div className="flex flex-col items-center space-y-6">{children}</div>
      </Card>
    </div>
  );
}

export type ErrorIconProps = { name: IconProps['name'] };

export function ErrorIcon({ name }: ErrorIconProps) {
  return (
    <div className={cn('w-16 h-16 rounded-full bg-destructive/10 flex items-center justify-center')}>
      <Icon name={name} className="w-10 h-10 text-destructive" />
    </div>
  );
}

export type ErrorTitleProps = React.ComponentProps<'h1'>;

export function ErrorTitle({ children, className }: ErrorTitleProps) {
  return <h1 className={cn('text-2xl font-semibold tracking-tight', className)}>{children}</h1>;
}

export type ErrorMessageProps = React.ComponentProps<'p'>;

export function ErrorMessage({ children, className }: ErrorMessageProps) {
  return <p className={cn('text-muted-foreground text-center', className)}>{children}</p>;
}

export type ErrorCauseProps = {
  errorMessage: string;
  errorTitle?: string | number;
};

export function ErrorCause({ errorTitle, errorMessage }: ErrorCauseProps) {
  return (
    <div className={alertVariants({ variant: 'destructive' })}>
      {errorTitle && <p className={alertTitleStyles()}>{errorTitle}</p>}
      <p className={alertDescriptionStyles()}>{errorMessage}</p>
    </div>
  );
}

export type ErrorFooterProps = React.ComponentProps<'div'>;

export function ErrorFooter({ children, className }: ErrorFooterProps) {
  return <div className={cn('mt-6 flex justify-center gap-2', className)}>{children}</div>;
}
