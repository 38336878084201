import { z } from 'zod';

import { verifyMfaTokenPostRequestSchema } from './verifyMfaTokenPostRequestSchema';
import { verifyMfaTokenPostResponseSchema } from './verifyMfaTokenPostResponseSchema';

/**
 * @description 200 response
 */
export const postVerifyMfaToken200Schema = z.lazy(() => verifyMfaTokenPostResponseSchema);
/**
 * @description 505 response
 */
export const postVerifyMfaToken505Schema = z.any();

export const postVerifyMfaTokenMutationRequestSchema = z.lazy(() => verifyMfaTokenPostRequestSchema);
/**
 * @description 200 response
 */
export const postVerifyMfaTokenMutationResponseSchema = z.lazy(() => verifyMfaTokenPostResponseSchema);
