import type {
  GetUserPoolDetails200,
  GetUserPoolDetails505,
  GetUserPoolDetailsQueryResponse,
} from '../types/GetUserPoolDetails';
import { createUserPoolDetailsGetResponse } from './createUserPoolDetailsGetResponse';

/**
 * @description 200 response
 */
export function createGetUserPoolDetails200(): NonNullable<GetUserPoolDetails200> {
  return createUserPoolDetailsGetResponse();
}

/**
 * @description 505 response
 */
export function createGetUserPoolDetails505(): NonNullable<GetUserPoolDetails505> {
  return undefined;
}

/**
 * @description 200 response
 */
export function createGetUserPoolDetailsQueryResponse(): NonNullable<GetUserPoolDetailsQueryResponse> {
  return createUserPoolDetailsGetResponse();
}
