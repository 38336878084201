import { http } from 'msw';

import { createPostForgotPasswordMutationResponse } from '../mocks/createPostForgotPassword';

export const postForgotPasswordHandler = http.post('*/forgot_password', function handler(info) {
  return new Response(JSON.stringify(createPostForgotPasswordMutationResponse()), {
    headers: {
      'Content-Type': 'application/json',
    },
  });
});
