import { z } from 'zod';

import { costCalculatorAuthenticatedPostRequestSchema } from './costCalculatorAuthenticatedPostRequestSchema';
import { costCalculatorAuthenticatedPostResponseSchema } from './costCalculatorAuthenticatedPostResponseSchema';

/**
 * @description 200 response
 */
export const postCostCalculatorAuthenticated200Schema = z.lazy(() => costCalculatorAuthenticatedPostResponseSchema);
/**
 * @description 505 response
 */
export const postCostCalculatorAuthenticated505Schema = z.any();

export const postCostCalculatorAuthenticatedMutationRequestSchema = z.lazy(
  () => costCalculatorAuthenticatedPostRequestSchema
);
/**
 * @description 200 response
 */
export const postCostCalculatorAuthenticatedMutationResponseSchema = z.lazy(
  () => costCalculatorAuthenticatedPostResponseSchema
);
