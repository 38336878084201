import { faker } from '@faker-js/faker';

import type { AccountTypeGetResponse } from '../types/AccountTypeGetResponse';

export function createAccountTypeGetResponse(
  data: NonNullable<Partial<AccountTypeGetResponse>> = {}
): NonNullable<AccountTypeGetResponse> {
  return {
    ...{
      data: { account_types: faker.helpers.arrayElements([faker.string.alpha()]) as any, value: faker.string.alpha() },
      response: {
        date: faker.string.alpha(),
        messages: faker.helpers.arrayElements([faker.string.alpha()]) as any,
        status: faker.number.int(),
        type: faker.string.alpha(),
      },
    },
    ...data,
  };
}
