import { http } from 'msw';

import { createGetChangeExpectedPaymentSettingQueryResponse } from '../mocks/createGetChangeExpectedPaymentSetting';

export const getChangeExpectedPaymentSettingHandler = http.get(
  '*/change_expected_payment_setting',
  function handler(info) {
    return new Response(JSON.stringify(createGetChangeExpectedPaymentSettingQueryResponse()), {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
);
