import type {
  QueryKey,
  QueryObserverOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query';
import { queryOptions, useQuery, useSuspenseQuery } from '@tanstack/react-query';

import type { GetListUserObjects505, GetListUserObjectsQueryResponse } from '../types/GetListUserObjects';
import client from '../../client';
import { getListUserObjectsQueryResponseSchema } from '../schemas/getListUserObjectsSchema';

type GetListUserObjectsClient = typeof client<GetListUserObjectsQueryResponse, GetListUserObjects505, never>;
type GetListUserObjects = {
  data: GetListUserObjectsQueryResponse;
  error: GetListUserObjects505;
  request: never;
  pathParams: never;
  queryParams: never;
  headerParams: never;
  response: GetListUserObjectsQueryResponse;
  client: {
    parameters: Partial<Parameters<GetListUserObjectsClient>[0]>;
    return: Awaited<ReturnType<GetListUserObjectsClient>>;
  };
};
export const getListUserObjectsQueryKey = () => ['USER_BACKEND', { url: '/list_user_objects' }] as const;
export type GetListUserObjectsQueryKey = ReturnType<typeof getListUserObjectsQueryKey>;
export function getListUserObjectsQueryOptions(options: GetListUserObjects['client']['parameters'] = {}) {
  const queryKey = getListUserObjectsQueryKey();
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<GetListUserObjects['data'], GetListUserObjects['error']>({
        method: 'get',
        url: `/list_user_objects`,
        ...options,
      });
      return getListUserObjectsQueryResponseSchema.parse(res.data);
    },
  });
}
/**
 * @link /list_user_objects
 */
export function useGetListUserObjects<
  TData = GetListUserObjects['response'],
  TQueryData = GetListUserObjects['response'],
  TQueryKey extends QueryKey = GetListUserObjectsQueryKey,
>(
  options: {
    query?: Partial<
      QueryObserverOptions<GetListUserObjects['response'], GetListUserObjects['error'], TData, TQueryData, TQueryKey>
    >;
    client?: GetListUserObjects['client']['parameters'];
  } = {}
): UseQueryResult<TData, GetListUserObjects['error']> & {
  queryKey: TQueryKey;
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getListUserObjectsQueryKey();
  const query = useQuery({
    ...(getListUserObjectsQueryOptions(clientOptions) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetListUserObjects['error']> & {
    queryKey: TQueryKey;
  };
  query.queryKey = queryKey as TQueryKey;
  return query;
}
export const getListUserObjectsSuspenseQueryKey = () => ['USER_BACKEND', { url: '/list_user_objects' }] as const;
export type GetListUserObjectsSuspenseQueryKey = ReturnType<typeof getListUserObjectsSuspenseQueryKey>;
export function getListUserObjectsSuspenseQueryOptions(options: GetListUserObjects['client']['parameters'] = {}) {
  const queryKey = getListUserObjectsSuspenseQueryKey();
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<GetListUserObjects['data'], GetListUserObjects['error']>({
        method: 'get',
        url: `/list_user_objects`,
        ...options,
      });
      return getListUserObjectsQueryResponseSchema.parse(res.data);
    },
  });
}
/**
 * @link /list_user_objects
 */
export function useGetListUserObjectsSuspense<
  TData = GetListUserObjects['response'],
  TQueryKey extends QueryKey = GetListUserObjectsSuspenseQueryKey,
>(
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<GetListUserObjects['response'], GetListUserObjects['error'], TData, TQueryKey>
    >;
    client?: GetListUserObjects['client']['parameters'];
  } = {}
): UseSuspenseQueryResult<TData, GetListUserObjects['error']> & {
  queryKey: TQueryKey;
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getListUserObjectsSuspenseQueryKey();
  const query = useSuspenseQuery({
    ...(getListUserObjectsSuspenseQueryOptions(clientOptions) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<TData, GetListUserObjects['error']> & {
    queryKey: TQueryKey;
  };
  query.queryKey = queryKey as TQueryKey;
  return query;
}
