import { z } from 'zod';

import { changeRetirementDatePostRequestSchema } from './changeRetirementDatePostRequestSchema';
import { changeRetirementDatePostResponseSchema } from './changeRetirementDatePostResponseSchema';

/**
 * @description 200 response
 */
export const postChangeRetirementDate200Schema = z.lazy(() => changeRetirementDatePostResponseSchema);
/**
 * @description 505 response
 */
export const postChangeRetirementDate505Schema = z.any();

export const postChangeRetirementDateMutationRequestSchema = z.lazy(() => changeRetirementDatePostRequestSchema);
/**
 * @description 200 response
 */
export const postChangeRetirementDateMutationResponseSchema = z.lazy(() => changeRetirementDatePostResponseSchema);
