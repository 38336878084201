import { z } from 'zod';

import { oneOffPaymentNoticeDeleteRequestSchema } from './oneOffPaymentNoticeDeleteRequestSchema';
import { oneOffPaymentNoticeDeleteResponseSchema } from './oneOffPaymentNoticeDeleteResponseSchema';

/**
 * @description 200 response
 */
export const deleteOneOffPaymentNotice200Schema = z.lazy(() => oneOffPaymentNoticeDeleteResponseSchema);
/**
 * @description 505 response
 */
export const deleteOneOffPaymentNotice505Schema = z.any();

export const deleteOneOffPaymentNoticeMutationRequestSchema = z.lazy(() => oneOffPaymentNoticeDeleteRequestSchema);
/**
 * @description 200 response
 */
export const deleteOneOffPaymentNoticeMutationResponseSchema = z.lazy(() => oneOffPaymentNoticeDeleteResponseSchema);
