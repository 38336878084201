import { z } from 'zod';

import { investorDeclarationPostRequestSchema } from './investorDeclarationPostRequestSchema';
import { investorDeclarationPostResponseSchema } from './investorDeclarationPostResponseSchema';

/**
 * @description 200 response
 */
export const postInvestorDeclaration200Schema = z.lazy(() => investorDeclarationPostResponseSchema);
/**
 * @description 505 response
 */
export const postInvestorDeclaration505Schema = z.any();

export const postInvestorDeclarationMutationRequestSchema = z.lazy(() => investorDeclarationPostRequestSchema);
/**
 * @description 200 response
 */
export const postInvestorDeclarationMutationResponseSchema = z.lazy(() => investorDeclarationPostResponseSchema);
