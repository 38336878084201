import { z } from 'zod';

import { changeExpectedPaymentSettingPostRequestSchema } from './changeExpectedPaymentSettingPostRequestSchema';
import { changeExpectedPaymentSettingPostResponseSchema } from './changeExpectedPaymentSettingPostResponseSchema';

/**
 * @description 200 response
 */
export const postChangeExpectedPaymentSetting200Schema = z.lazy(() => changeExpectedPaymentSettingPostResponseSchema);
/**
 * @description 505 response
 */
export const postChangeExpectedPaymentSetting505Schema = z.any();

export const postChangeExpectedPaymentSettingMutationRequestSchema = z.lazy(
  () => changeExpectedPaymentSettingPostRequestSchema
);
/**
 * @description 200 response
 */
export const postChangeExpectedPaymentSettingMutationResponseSchema = z.lazy(
  () => changeExpectedPaymentSettingPostResponseSchema
);
