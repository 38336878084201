import { http } from 'msw';

import { createGetCostCalculatorQueryResponse } from '../mocks/createGetCostCalculator';

export const getCostCalculatorHandler = http.get('*/cost_calculator', function handler(info) {
  return new Response(JSON.stringify(createGetCostCalculatorQueryResponse()), {
    headers: {
      'Content-Type': 'application/json',
    },
  });
});
