import { http } from 'msw';

import { createGetOneOffPaymentNoticeQueryResponse } from '../mocks/createGetOneOffPaymentNotice';

export const getOneOffPaymentNoticeHandler = http.get('*/one_off_payment_notice', function handler(info) {
  return new Response(JSON.stringify(createGetOneOffPaymentNoticeQueryResponse()), {
    headers: {
      'Content-Type': 'application/json',
    },
  });
});
