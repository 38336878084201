import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import type { RequestConfig } from '../../client.ts';
import client from '../../client.ts';
import { submitDipApplicationMutationResponseSchema } from '../schemas/submitDipApplicationSchema.ts';
import type {
  SubmitDipApplication400,
  SubmitDipApplication404,
  SubmitDipApplication500,
  SubmitDipApplicationMutationRequest,
  SubmitDipApplicationMutationResponse,
  SubmitDipApplicationPathParams,
} from '../types/SubmitDipApplication.ts';

export const submitDipApplicationMutationKey = () =>
  [{ url: '/user/application/{agreementReference}/{applicationId}' }] as const;

export type SubmitDipApplicationMutationKey = ReturnType<typeof submitDipApplicationMutationKey>;

/**
 * @description User API to submit the DIP Application form
 * @summary Submit DIP Application
 * {@link /user/application/:agreementReference/:applicationId}
 */
async function submitDipApplication(
  {
    agreementReference,
    applicationId,
    data,
  }: {
    agreementReference: SubmitDipApplicationPathParams['agreementReference'];
    applicationId: SubmitDipApplicationPathParams['applicationId'];
    data: SubmitDipApplicationMutationRequest;
  },
  config: Partial<RequestConfig<SubmitDipApplicationMutationRequest>> = {}
) {
  const res = await client<
    SubmitDipApplicationMutationResponse,
    SubmitDipApplication400 | SubmitDipApplication404 | SubmitDipApplication500,
    SubmitDipApplicationMutationRequest
  >({ method: 'PUT', url: `/user/application/${agreementReference}/${applicationId}`, data, ...config });
  return submitDipApplicationMutationResponseSchema.parse(res.data);
}

/**
 * @description User API to submit the DIP Application form
 * @summary Submit DIP Application
 * {@link /user/application/:agreementReference/:applicationId}
 */
export function useSubmitDipApplication(
  options: {
    mutation?: UseMutationOptions<
      SubmitDipApplicationMutationResponse,
      SubmitDipApplication400 | SubmitDipApplication404 | SubmitDipApplication500,
      {
        agreementReference: SubmitDipApplicationPathParams['agreementReference'];
        applicationId: SubmitDipApplicationPathParams['applicationId'];
        data: SubmitDipApplicationMutationRequest;
      }
    >;
    client?: Partial<RequestConfig<SubmitDipApplicationMutationRequest>>;
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? submitDipApplicationMutationKey();
  return useMutation<
    SubmitDipApplicationMutationResponse,
    SubmitDipApplication400 | SubmitDipApplication404 | SubmitDipApplication500,
    {
      agreementReference: SubmitDipApplicationPathParams['agreementReference'];
      applicationId: SubmitDipApplicationPathParams['applicationId'];
      data: SubmitDipApplicationMutationRequest;
    }
  >({
    mutationFn: async ({ agreementReference, applicationId, data }) => {
      return submitDipApplication({ agreementReference, applicationId, data }, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}
