import type {
  PostChangeZakatDate200,
  PostChangeZakatDate505,
  PostChangeZakatDateMutationRequest,
  PostChangeZakatDateMutationResponse,
} from '../types/PostChangeZakatDate';
import { createChangeZakatDatePostRequest } from './createChangeZakatDatePostRequest';
import { createChangeZakatDatePostResponse } from './createChangeZakatDatePostResponse';

/**
 * @description 200 response
 */
export function createPostChangeZakatDate200(): NonNullable<PostChangeZakatDate200> {
  return createChangeZakatDatePostResponse();
}

/**
 * @description 505 response
 */
export function createPostChangeZakatDate505(): NonNullable<PostChangeZakatDate505> {
  return undefined;
}

export function createPostChangeZakatDateMutationRequest(): NonNullable<PostChangeZakatDateMutationRequest> {
  return createChangeZakatDatePostRequest();
}

/**
 * @description 200 response
 */
export function createPostChangeZakatDateMutationResponse(): NonNullable<PostChangeZakatDateMutationResponse> {
  return createChangeZakatDatePostResponse();
}
