import { z } from 'zod';

import { costCalculatorPostRequestSchema } from './costCalculatorPostRequestSchema';
import { costCalculatorPostResponseSchema } from './costCalculatorPostResponseSchema';

/**
 * @description 200 response
 */
export const postCostCalculator200Schema = z.lazy(() => costCalculatorPostResponseSchema);
/**
 * @description 505 response
 */
export const postCostCalculator505Schema = z.any();

export const postCostCalculatorMutationRequestSchema = z.lazy(() => costCalculatorPostRequestSchema);
/**
 * @description 200 response
 */
export const postCostCalculatorMutationResponseSchema = z.lazy(() => costCalculatorPostResponseSchema);
