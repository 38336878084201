import { http } from 'msw';

import { createPostDownloadTransactionsStatementMutationResponse } from '../mocks/createPostDownloadTransactionsStatement';

export const postDownloadTransactionsStatementHandler = http.post(
  '*/download_transactions_statement',
  function handler(info) {
    return new Response(JSON.stringify(createPostDownloadTransactionsStatementMutationResponse()), {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
);
