import { http } from 'msw';

import { createPostEligibilityMutationResponse } from '../mocks/createPostEligibility';

export const postEligibilityHandler = http.post('*/eligibility', function handler(info) {
  return new Response(JSON.stringify(createPostEligibilityMutationResponse()), {
    headers: {
      'Content-Type': 'application/json',
    },
  });
});
