import { z } from 'zod';

import { hearAboutGetResponseSchema } from './hearAboutGetResponseSchema';

/**
 * @description 200 response
 */
export const getHearAbout200Schema = z.lazy(() => hearAboutGetResponseSchema);
/**
 * @description 505 response
 */
export const getHearAbout505Schema = z.any();
/**
 * @description 200 response
 */
export const getHearAboutQueryResponseSchema = z.lazy(() => hearAboutGetResponseSchema);
