import { z } from 'zod';

import { referralsGetResponseSchema } from './referralsGetResponseSchema';

/**
 * @description 200 response
 */
export const getReferrals200Schema = z.lazy(() => referralsGetResponseSchema);
/**
 * @description 505 response
 */
export const getReferrals505Schema = z.any();
/**
 * @description 200 response
 */
export const getReferralsQueryResponseSchema = z.lazy(() => referralsGetResponseSchema);
