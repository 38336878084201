import { z } from 'zod';

import { investorDeclarationGetResponseSchema } from './investorDeclarationGetResponseSchema';

/**
 * @description 200 response
 */
export const getInvestorDeclaration200Schema = z.lazy(() => investorDeclarationGetResponseSchema);
/**
 * @description 505 response
 */
export const getInvestorDeclaration505Schema = z.any();
/**
 * @description 200 response
 */
export const getInvestorDeclarationQueryResponseSchema = z.lazy(() => investorDeclarationGetResponseSchema);
