import { z } from 'zod';

import { changeInvestorCreditSplitPostRequestSchema } from './changeInvestorCreditSplitPostRequestSchema';
import { changeInvestorCreditSplitPostResponseSchema } from './changeInvestorCreditSplitPostResponseSchema';

/**
 * @description 200 response
 */
export const postChangeInvestorCreditSplit200Schema = z.lazy(() => changeInvestorCreditSplitPostResponseSchema);
/**
 * @description 505 response
 */
export const postChangeInvestorCreditSplit505Schema = z.any();

export const postChangeInvestorCreditSplitMutationRequestSchema = z.lazy(
  () => changeInvestorCreditSplitPostRequestSchema
);
/**
 * @description 200 response
 */
export const postChangeInvestorCreditSplitMutationResponseSchema = z.lazy(
  () => changeInvestorCreditSplitPostResponseSchema
);
