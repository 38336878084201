import { faker } from '@faker-js/faker';

import type { InvestorDeclarationGetResponse } from '../types/InvestorDeclarationGetResponse';

export function createInvestorDeclarationGetResponse(
  data: NonNullable<Partial<InvestorDeclarationGetResponse>> = {}
): NonNullable<InvestorDeclarationGetResponse> {
  return {
    ...{
      data: { action: faker.string.alpha() },
      response: {
        date: faker.string.alpha(),
        messages: faker.helpers.arrayElements([faker.string.alpha()]) as any,
        status: faker.number.int(),
        type: faker.string.alpha(),
      },
    },
    ...data,
  };
}
