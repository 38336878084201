import { http } from 'msw';

import { createPostChangePasswordMutationResponse } from '../mocks/createPostChangePassword';

export const postChangePasswordHandler = http.post('*/change_password', function handler(info) {
  return new Response(JSON.stringify(createPostChangePasswordMutationResponse()), {
    headers: {
      'Content-Type': 'application/json',
    },
  });
});
