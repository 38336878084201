import { z } from 'zod';

import { toggleDividendsReinvestGetResponseSchema } from './toggleDividendsReinvestGetResponseSchema';

export const getToggleDividendsReinvestQueryParamsSchema = z.object({ investment_product: z.string() });
/**
 * @description 200 response
 */
export const getToggleDividendsReinvest200Schema = z.lazy(() => toggleDividendsReinvestGetResponseSchema);
/**
 * @description 505 response
 */
export const getToggleDividendsReinvest505Schema = z.any();
/**
 * @description 200 response
 */
export const getToggleDividendsReinvestQueryResponseSchema = z.lazy(() => toggleDividendsReinvestGetResponseSchema);
