import { z } from 'zod';

import { changeExpectedPaymentSettingGetResponseSchema } from './changeExpectedPaymentSettingGetResponseSchema';

export const getChangeExpectedPaymentSettingQueryParamsSchema = z
  .object({ reference: z.string().optional() })
  .optional();
/**
 * @description 200 response
 */
export const getChangeExpectedPaymentSetting200Schema = z.lazy(() => changeExpectedPaymentSettingGetResponseSchema);
/**
 * @description 505 response
 */
export const getChangeExpectedPaymentSetting505Schema = z.any();
/**
 * @description 200 response
 */
export const getChangeExpectedPaymentSettingQueryResponseSchema = z.lazy(
  () => changeExpectedPaymentSettingGetResponseSchema
);
