import { useAuth } from '@/features/auth/store/authStore';
import background from '@repo/assets/shared/images/login-background';
import { createFileRoute, Outlet, redirect } from '@tanstack/react-router';
import { z } from 'zod';

export const UnauthenticatedSearchQueryValidation = z.object({
  referral: z.string().optional(),
  to: z.string().catch('/dashboard'),
  company: z.string().optional(),
});

export const Route = createFileRoute('/_unauthenticated')({
  validateSearch: UnauthenticatedSearchQueryValidation,
  beforeLoad: async () => {
    const { isAuthenticated } = useAuth.getState();

    if (isAuthenticated()) {
      redirect({
        to: '/',
        throw: true,
      });
    }
  },

  component: () => (
    <div style={{ backgroundImage: `url(${background})` }} className="bg-img min-h-screen bg-cover flex flex-col">
      <Outlet />
    </div>
  ),
});
