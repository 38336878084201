import { http } from 'msw';

import { createPostChangeRetirementDateMutationResponse } from '../mocks/createPostChangeRetirementDate';

export const postChangeRetirementDateHandler = http.post('*/change_retirement_date', function handler(info) {
  return new Response(JSON.stringify(createPostChangeRetirementDateMutationResponse()), {
    headers: {
      'Content-Type': 'application/json',
    },
  });
});
