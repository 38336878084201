import { http } from 'msw';

import { createGetGetUserObjectQueryResponse } from '../mocks/createGetGetUserObject';

export const getGetUserObjectHandler = http.get('*/get_user_object', function handler(info) {
  return new Response(JSON.stringify(createGetGetUserObjectQueryResponse()), {
    headers: {
      'Content-Type': 'application/json',
    },
  });
});
