import type {
  GetMarketingPreferences200,
  GetMarketingPreferences505,
  GetMarketingPreferencesQueryResponse,
} from '../types/GetMarketingPreferences';
import { createMarketingPreferencesGetResponse } from './createMarketingPreferencesGetResponse';

/**
 * @description 200 response
 */
export function createGetMarketingPreferences200(): NonNullable<GetMarketingPreferences200> {
  return createMarketingPreferencesGetResponse();
}

/**
 * @description 505 response
 */
export function createGetMarketingPreferences505(): NonNullable<GetMarketingPreferences505> {
  return undefined;
}

/**
 * @description 200 response
 */
export function createGetMarketingPreferencesQueryResponse(): NonNullable<GetMarketingPreferencesQueryResponse> {
  return createMarketingPreferencesGetResponse();
}
