import { z } from 'zod';

import { loginAuthChallengePostRequestSchema } from './loginAuthChallengePostRequestSchema';
import { loginAuthChallengePostResponseSchema } from './loginAuthChallengePostResponseSchema';

/**
 * @description 200 response
 */
export const postLoginAuthChallenge200Schema = z.lazy(() => loginAuthChallengePostResponseSchema);
/**
 * @description 505 response
 */
export const postLoginAuthChallenge505Schema = z.any();

export const postLoginAuthChallengeMutationRequestSchema = z.lazy(() => loginAuthChallengePostRequestSchema);
/**
 * @description 200 response
 */
export const postLoginAuthChallengeMutationResponseSchema = z.lazy(() => loginAuthChallengePostResponseSchema);
