import type {
  GetListCountries200,
  GetListCountries505,
  GetListCountriesQueryResponse,
} from '../types/GetListCountries';
import { createListCountriesGetResponse } from './createListCountriesGetResponse';

/**
 * @description 200 response
 */
export function createGetListCountries200(): NonNullable<GetListCountries200> {
  return createListCountriesGetResponse();
}

/**
 * @description 505 response
 */
export function createGetListCountries505(): NonNullable<GetListCountries505> {
  return undefined;
}

/**
 * @description 200 response
 */
export function createGetListCountriesQueryResponse(): NonNullable<GetListCountriesQueryResponse> {
  return createListCountriesGetResponse();
}
