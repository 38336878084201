import { faker } from '@faker-js/faker';

import type { ReferralsGetResponse } from '../types/ReferralsGetResponse';

export function createReferralsGetResponse(
  data: NonNullable<Partial<ReferralsGetResponse>> = {}
): NonNullable<ReferralsGetResponse> {
  return {
    ...{
      data: {
        company: {
          pending_referrals: faker.number.int(),
          total_referral_credit: faker.string.alpha(),
          total_referrals: faker.number.int(),
        },
        referral_link: faker.string.alpha(),
        show_referrals: faker.datatype.boolean(),
      },
      response: {
        date: faker.string.alpha(),
        messages: faker.helpers.arrayElements([faker.string.alpha()]) as any,
        status: faker.number.int(),
        type: faker.string.alpha(),
      },
    },
    ...data,
  };
}
