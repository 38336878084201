import { z } from 'zod';
import { trueFalseEnum } from './common';

export const residencyStatusEnum = z.enum(['British Citizen', 'Indefinite Leave to Remain', 'Expat', 'Other'], {
  required_error: 'Residency status is required',
});
export const residentialStatusEnum = z.enum(
  ['Renting', 'Living with friends and family', 'Own home', 'Own home with finance'],
  { required_error: 'Residential status is required' }
);
export const maritalStatusEnum = z.enum(
  ['Single', 'Married / Civil Partnership', 'Widowed', 'Divorced / Ex-civil Partnership', 'Engaged', 'Separated'],
  { required_error: 'Marital status is required' }
);
export const relationshipEnum = z.enum([
  'Spouse / Civil Partner',
  'Fiancé / Fiancée',
  'Parent',
  'Child',
  'Sibling',
  'Friend',
]);

export const detailsSchema = z
  .object({
    name: z.string(),
    dateOfBirth: z.string(),
    email: z.string(),
    phoneNumber: z.string(),
    address: z.string(),
    city: z.string(),
    postcode: z.string(),
    residencyStatus: residencyStatusEnum,
    residencyStatusOther: z.string().optional(),
    residentialStatus: residentialStatusEnum,
    maritalStatus: maritalStatusEnum,
    hasSecondApplicant: trueFalseEnum,
    applicantTwoAuthorityToSubmit: trueFalseEnum.optional(),
    applicantTwoRelationship: relationshipEnum.optional(),
    applicantTwoSameAddress: trueFalseEnum.optional(),
    applicantTwoDateOfBirth: z.string().optional(),
    applicantTwoResidencyStatus: residencyStatusEnum.optional(),
    applicantTwoResidencyStatusOther: z.string().optional(),
    applicantTwoResidentialStatus: residentialStatusEnum.optional(),
    applicantTwoMaritalStatus: maritalStatusEnum.optional(),
    hasDependents: trueFalseEnum,
    numberOfDependents: z.string().optional(),
  })
  .superRefine((input, ctx) => {
    if (input.residencyStatus === residencyStatusEnum.Enum.Other && !input.residencyStatusOther) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Residency status is required',
        path: ['residencyStatusOther'],
      });
    }

    if (input.hasSecondApplicant === 'true') {
      if (!input.applicantTwoAuthorityToSubmit) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'This field is required',
          path: ['applicantTwoAuthorityToSubmit'],
        });
      }
      if (!input.applicantTwoRelationship) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Relationship is required',
          path: ['applicantTwoRelationship'],
        });
      }

      if (input.applicantTwoSameAddress === undefined) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Please select an option',
          path: ['applicantTwoSameAddress'],
        });
      }

      if (!input.applicantTwoDateOfBirth) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Enter a valid date',
          path: ['applicantTwoDateOfBirth'],
        });
      } else {
        const applicantTwoDateOfBirth = new Date(input.applicantTwoDateOfBirth);
        if (applicantTwoDateOfBirth > new Date()) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'The date cannot be in the future. Please enter a valid past date',
            path: ['applicantTwoDateOfBirth'],
          });
        }

        if (applicantTwoDateOfBirth < new Date('1900-01-01')) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Please enter a date after 01 January 1900',
            path: ['applicantTwoDateOfBirth'],
          });
        }
      }

      if (!input.applicantTwoResidencyStatus) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Residency status is required',
          path: ['applicantTwoResidencyStatus'],
        });
      }

      if (input.applicantTwoResidencyStatus === 'Other' && !input.applicantTwoResidencyStatusOther) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Residency status is required',
          path: ['applicantTwoResidencyStatusOther'],
        });
      }

      if (!input.applicantTwoResidentialStatus) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Residential status is required',
          path: ['applicantTwoResidentialStatus'],
        });
      }

      if (!input.applicantTwoMaritalStatus) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Marital status is required',
          path: ['applicantTwoMaritalStatus'],
        });
      }
    }

    if (input.hasDependents === 'true' && !input.numberOfDependents) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Number of dependents is required',
        path: ['numberOfDependents'],
      });
    }
  });

export type DetailsSchema = z.infer<typeof detailsSchema>;
