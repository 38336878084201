import { z } from 'zod';

export const subscriptionFormGetResponseSchema = z.object({
  data: z.object({
    next_step: z.string().optional(),
    subscription_forms: z
      .array(
        z.object({
          investment_amount: z.number(),
          investment_product: z.string(),
          source_of_investments: z.array(
            z.object({ amount: z.number(), bank_info: z.string(), info: z.string(), source: z.string() })
          ),
        })
      )
      .optional(),
  }),
  response: z.object({ date: z.string(), messages: z.array(z.string()), status: z.number(), type: z.string() }),
});
