import type {
  PostLoginAuthChallenge200,
  PostLoginAuthChallenge505,
  PostLoginAuthChallengeMutationRequest,
  PostLoginAuthChallengeMutationResponse,
} from '../types/PostLoginAuthChallenge';
import { createLoginAuthChallengePostRequest } from './createLoginAuthChallengePostRequest';
import { createLoginAuthChallengePostResponse } from './createLoginAuthChallengePostResponse';

/**
 * @description 200 response
 */
export function createPostLoginAuthChallenge200(): NonNullable<PostLoginAuthChallenge200> {
  return createLoginAuthChallengePostResponse();
}

/**
 * @description 505 response
 */
export function createPostLoginAuthChallenge505(): NonNullable<PostLoginAuthChallenge505> {
  return undefined;
}

export function createPostLoginAuthChallengeMutationRequest(): NonNullable<PostLoginAuthChallengeMutationRequest> {
  return createLoginAuthChallengePostRequest();
}

/**
 * @description 200 response
 */
export function createPostLoginAuthChallengeMutationResponse(): NonNullable<PostLoginAuthChallengeMutationResponse> {
  return createLoginAuthChallengePostResponse();
}
