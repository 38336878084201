import { useState } from 'react';
import { mifeName } from '@repo/single-spa';
import { Spinner } from '@repo/ui/components/ui/spinner';
import { mountRootParcel } from 'single-spa';
import Parcel from 'single-spa-react/parcel';

async function getParcel() {
  const parcel = await import(/* @vite-ignore */ mifeName);
  return parcel.vueParcel();
}

function MifeLoadingSpinner() {
  return (
    <div className="min-h-full flex flex-col justify-center items-center flex-1">
      <Spinner size="large" />
    </div>
  );
}

export function MifeParcel() {
  // We do not want to handle errors here, we want the error boundary set on a route to capture it.
  const [status, setStatus] = useState<'loading' | 'success' | 'error'>('loading');

  return (
    <>
      {status === 'loading' && <MifeLoadingSpinner />}
      <Parcel
        wrapWith="div"
        wrapClassName="single-spa-application:@mife/version-one"
        wrapStyle={{
          overflow: 'visible',
        }}
        mountParcel={(...params) => mountRootParcel(...params)}
        config={getParcel}
        handleError={(error) => setStatus('error')}
        parcelDidMount={() => setStatus('success')}
      />
    </>
  );
}
