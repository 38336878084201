import { http } from 'msw';

import { createGetBankDetailsQueryResponse } from '../mocks/createGetBankDetails';

export const getBankDetailsHandler = http.get('*/bank_details', function handler(info) {
  return new Response(JSON.stringify(createGetBankDetailsQueryResponse()), {
    headers: {
      'Content-Type': 'application/json',
    },
  });
});
