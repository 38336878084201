export const convertStringsToBooleans = (obj: Record<any, any>) =>
  Object.fromEntries(Object.entries(obj).map(([key, value]) => [key, transformValue(value)]));

function transformValue(value: any): any {
  if (value === undefined || value === null) return value;

  if (value === 'true') return true;

  if (value === 'false') return false;

  if (typeof value === 'object' && !Array.isArray(value)) {
    return convertStringsToBooleans(value);
  }

  return value;
}
