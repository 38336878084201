import { z } from 'zod';

import { forgotPasswordPostRequestSchema } from './forgotPasswordPostRequestSchema';
import { forgotPasswordPostResponseSchema } from './forgotPasswordPostResponseSchema';

/**
 * @description 200 response
 */
export const postForgotPassword200Schema = z.lazy(() => forgotPasswordPostResponseSchema);
/**
 * @description 505 response
 */
export const postForgotPassword505Schema = z.any();

export const postForgotPasswordMutationRequestSchema = z.lazy(() => forgotPasswordPostRequestSchema);
/**
 * @description 200 response
 */
export const postForgotPasswordMutationResponseSchema = z.lazy(() => forgotPasswordPostResponseSchema);
