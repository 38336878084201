import { z } from 'zod';

import { toggleDocumentUnreadPostRequestSchema } from './toggleDocumentUnreadPostRequestSchema';
import { toggleDocumentUnreadPostResponseSchema } from './toggleDocumentUnreadPostResponseSchema';

/**
 * @description 200 response
 */
export const postToggleDocumentUnread200Schema = z.lazy(() => toggleDocumentUnreadPostResponseSchema);
/**
 * @description 505 response
 */
export const postToggleDocumentUnread505Schema = z.any();

export const postToggleDocumentUnreadMutationRequestSchema = z.lazy(() => toggleDocumentUnreadPostRequestSchema);
/**
 * @description 200 response
 */
export const postToggleDocumentUnreadMutationResponseSchema = z.lazy(() => toggleDocumentUnreadPostResponseSchema);
