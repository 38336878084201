import { http } from 'msw';

import { createPostToggleDividendsReinvestMutationResponse } from '../mocks/createPostToggleDividendsReinvest';

export const postToggleDividendsReinvestHandler = http.post('*/toggle_dividends_reinvest', function handler(info) {
  return new Response(JSON.stringify(createPostToggleDividendsReinvestMutationResponse()), {
    headers: {
      'Content-Type': 'application/json',
    },
  });
});
