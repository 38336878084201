import type {
  QueryKey,
  QueryObserverOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query';
import { queryOptions, useQuery, useSuspenseQuery } from '@tanstack/react-query';

import type { GetReferrals505, GetReferralsQueryResponse } from '../types/GetReferrals';
import client from '../../client';
import { getReferralsQueryResponseSchema } from '../schemas/getReferralsSchema';

type GetReferralsClient = typeof client<GetReferralsQueryResponse, GetReferrals505, never>;
type GetReferrals = {
  data: GetReferralsQueryResponse;
  error: GetReferrals505;
  request: never;
  pathParams: never;
  queryParams: never;
  headerParams: never;
  response: GetReferralsQueryResponse;
  client: {
    parameters: Partial<Parameters<GetReferralsClient>[0]>;
    return: Awaited<ReturnType<GetReferralsClient>>;
  };
};
export const getReferralsQueryKey = () => ['USER_BACKEND', { url: '/referrals' }] as const;
export type GetReferralsQueryKey = ReturnType<typeof getReferralsQueryKey>;
export function getReferralsQueryOptions(options: GetReferrals['client']['parameters'] = {}) {
  const queryKey = getReferralsQueryKey();
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<GetReferrals['data'], GetReferrals['error']>({
        method: 'get',
        url: `/referrals`,
        ...options,
      });
      return getReferralsQueryResponseSchema.parse(res.data);
    },
  });
}
/**
 * @link /referrals
 */
export function useGetReferrals<
  TData = GetReferrals['response'],
  TQueryData = GetReferrals['response'],
  TQueryKey extends QueryKey = GetReferralsQueryKey,
>(
  options: {
    query?: Partial<
      QueryObserverOptions<GetReferrals['response'], GetReferrals['error'], TData, TQueryData, TQueryKey>
    >;
    client?: GetReferrals['client']['parameters'];
  } = {}
): UseQueryResult<TData, GetReferrals['error']> & {
  queryKey: TQueryKey;
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getReferralsQueryKey();
  const query = useQuery({
    ...(getReferralsQueryOptions(clientOptions) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetReferrals['error']> & {
    queryKey: TQueryKey;
  };
  query.queryKey = queryKey as TQueryKey;
  return query;
}
export const getReferralsSuspenseQueryKey = () => ['USER_BACKEND', { url: '/referrals' }] as const;
export type GetReferralsSuspenseQueryKey = ReturnType<typeof getReferralsSuspenseQueryKey>;
export function getReferralsSuspenseQueryOptions(options: GetReferrals['client']['parameters'] = {}) {
  const queryKey = getReferralsSuspenseQueryKey();
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<GetReferrals['data'], GetReferrals['error']>({
        method: 'get',
        url: `/referrals`,
        ...options,
      });
      return getReferralsQueryResponseSchema.parse(res.data);
    },
  });
}
/**
 * @link /referrals
 */
export function useGetReferralsSuspense<
  TData = GetReferrals['response'],
  TQueryKey extends QueryKey = GetReferralsSuspenseQueryKey,
>(
  options: {
    query?: Partial<UseSuspenseQueryOptions<GetReferrals['response'], GetReferrals['error'], TData, TQueryKey>>;
    client?: GetReferrals['client']['parameters'];
  } = {}
): UseSuspenseQueryResult<TData, GetReferrals['error']> & {
  queryKey: TQueryKey;
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getReferralsSuspenseQueryKey();
  const query = useSuspenseQuery({
    ...(getReferralsSuspenseQueryOptions(clientOptions) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<TData, GetReferrals['error']> & {
    queryKey: TQueryKey;
  };
  query.queryKey = queryKey as TQueryKey;
  return query;
}
