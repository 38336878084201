import { faker } from '@faker-js/faker';

import type { OneOffPaymentNoticeGetResponse } from '../types/OneOffPaymentNoticeGetResponse';

export function createOneOffPaymentNoticeGetResponse(
  data: NonNullable<Partial<OneOffPaymentNoticeGetResponse>> = {}
): NonNullable<OneOffPaymentNoticeGetResponse> {
  return {
    ...{
      data: {
        one_off_payment_notices: faker.helpers.arrayElements([
          { amount: faker.number.float(), date: faker.string.alpha(), reference: faker.string.alpha() },
        ]) as any,
      },
      response: {
        date: faker.string.alpha(),
        messages: faker.helpers.arrayElements([faker.string.alpha()]) as any,
        status: faker.number.int(),
        type: faker.string.alpha(),
      },
    },
    ...data,
  };
}
