import { faker } from '@faker-js/faker';

import type { ToggleDividendsReinvestGetResponse } from '../types/ToggleDividendsReinvestGetResponse';

export function createToggleDividendsReinvestGetResponse(
  data: NonNullable<Partial<ToggleDividendsReinvestGetResponse>> = {}
): NonNullable<ToggleDividendsReinvestGetResponse> {
  return {
    ...{
      data: { value: faker.number.int() },
      response: {
        date: faker.string.alpha(),
        messages: faker.helpers.arrayElements([faker.string.alpha()]) as any,
        status: faker.number.int(),
        type: faker.string.alpha(),
      },
    },
    ...data,
  };
}
