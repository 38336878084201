import { z } from 'zod';

export const changeExpectedPaymentSettingGetResponseSchema = z.object({
  data: z.object({
    expected_payment_setting: z
      .object({
        reference: z.string().optional(),
        reset_after_next_payment: z.boolean().optional(),
        user_can_change: z.boolean().optional(),
        username: z.string().optional(),
      })
      .optional(),
    settings: z.array(z.object({ display_name: z.string(), setting: z.string() })).optional(),
  }),
  response: z.object({ date: z.string(), messages: z.array(z.string()), status: z.number(), type: z.string() }),
});
