import { z } from 'zod';

import { marketingPreferencesGetResponseSchema } from './marketingPreferencesGetResponseSchema';

/**
 * @description 200 response
 */
export const getMarketingPreferences200Schema = z.lazy(() => marketingPreferencesGetResponseSchema);
/**
 * @description 505 response
 */
export const getMarketingPreferences505Schema = z.any();
/**
 * @description 200 response
 */
export const getMarketingPreferencesQueryResponseSchema = z.lazy(() => marketingPreferencesGetResponseSchema);
