import { z } from 'zod';

import { ifaApprovalPostRequestSchema } from './ifaApprovalPostRequestSchema';
import { ifaApprovalPostResponseSchema } from './ifaApprovalPostResponseSchema';

/**
 * @description 200 response
 */
export const postIfaApproval200Schema = z.lazy(() => ifaApprovalPostResponseSchema);
/**
 * @description 505 response
 */
export const postIfaApproval505Schema = z.any();

export const postIfaApprovalMutationRequestSchema = z.lazy(() => ifaApprovalPostRequestSchema);
/**
 * @description 200 response
 */
export const postIfaApprovalMutationResponseSchema = z.lazy(() => ifaApprovalPostResponseSchema);
