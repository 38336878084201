import type {
  PostAffordabilityCalculatorAuthenticated200,
  PostAffordabilityCalculatorAuthenticated505,
  PostAffordabilityCalculatorAuthenticatedMutationRequest,
  PostAffordabilityCalculatorAuthenticatedMutationResponse,
} from '../types/PostAffordabilityCalculatorAuthenticated';
import { createAffordabilityCalculatorAuthenticatedPostRequest } from './createAffordabilityCalculatorAuthenticatedPostRequest';
import { createAffordabilityCalculatorAuthenticatedPostResponse } from './createAffordabilityCalculatorAuthenticatedPostResponse';

/**
 * @description 200 response
 */
export function createPostAffordabilityCalculatorAuthenticated200(): NonNullable<PostAffordabilityCalculatorAuthenticated200> {
  return createAffordabilityCalculatorAuthenticatedPostResponse();
}

/**
 * @description 505 response
 */
export function createPostAffordabilityCalculatorAuthenticated505(): NonNullable<PostAffordabilityCalculatorAuthenticated505> {
  return undefined;
}

export function createPostAffordabilityCalculatorAuthenticatedMutationRequest(): NonNullable<PostAffordabilityCalculatorAuthenticatedMutationRequest> {
  return createAffordabilityCalculatorAuthenticatedPostRequest();
}

/**
 * @description 200 response
 */
export function createPostAffordabilityCalculatorAuthenticatedMutationResponse(): NonNullable<PostAffordabilityCalculatorAuthenticatedMutationResponse> {
  return createAffordabilityCalculatorAuthenticatedPostResponse();
}
