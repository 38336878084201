import { z } from 'zod';

export const loginAuthChallengePostRequestSchema = z.object({
  challenge_name: z.string(),
  challenge_responses: z.object({
    NEW_PASSWORD: z.string().optional(),
    SOFTWARE_TOKEN_MFA_CODE: z.string().optional(),
    USERNAME: z.string().optional(),
    USER_CODE: z.string().optional(),
  }),
  client: z.string(),
  client_secret: z.string(),
  session: z.string(),
});
