import { faker } from '@faker-js/faker';

import type { ChangeExpectedPaymentSettingPostResponse } from '../types/ChangeExpectedPaymentSettingPostResponse';

export function createChangeExpectedPaymentSettingPostResponse(
  data: NonNullable<Partial<ChangeExpectedPaymentSettingPostResponse>> = {}
): NonNullable<ChangeExpectedPaymentSettingPostResponse> {
  return {
    ...{
      data: {},
      response: {
        date: faker.string.alpha(),
        messages: faker.helpers.arrayElements([faker.string.alpha()]) as any,
        status: faker.number.int(),
        type: faker.string.alpha(),
      },
    },
    ...data,
  };
}
