import { z } from 'zod';
import { trueFalseEnum } from './common';

export const productTypeEnum = z.enum(['Buy-to-Let Purchase Plan', 'Home Provision Scheme'], {
  required_error: 'Please select an option',
});
export const financePurposeEnum = z.enum(['New Purchase', 'Refinance Buy-to-let Property', 'Other'], {
  required_error: 'Purpose of finance is required',
});
export const propertyLocationEnum = z.enum(['England', 'Scotland', 'Wales'], {
  required_error: 'Please select an option',
});

export const propertyAndFinanceSchema = z
  .object({
    productType: productTypeEnum,
    isPropertyMainResidence: trueFalseEnum.optional(),
    financePurpose: financePurposeEnum,
    propertyLocation: propertyLocationEnum,
    estimatedPropertyValue: z
      .string({ required_error: 'Estimated property value is required' })
      .min(1, { message: 'Estimated property value is required' }),
    downPayment: z
      .string({ required_error: 'Down payment is required' })
      .min(1, { message: 'Down payment is required' }),
    termYears: z
      .string({
        required_error: 'Term required in years must be between 1 and 30',
      })
      .regex(/\b([1-9]|[12][0-9]|30)\b/, 'Term required in years must be between 1 and 30'),
    additionalInformation: z.string().optional(),
    signedBy: z
      .string({ required_error: 'Applicant name is required' })
      .min(1, { message: 'Applicant name is required' }),
  })
  .superRefine((input, ctx) => {
    if (input.productType === productTypeEnum.Enum['Home Provision Scheme']) {
      if (!input.isPropertyMainResidence) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Please select an option',
          path: ['isPropertyMainResidence'],
        });
      }
    }
  });

export type PropertyAndFinanceSchema = z.infer<typeof propertyAndFinanceSchema>;
