import { z } from 'zod';

import { changeInvestorCreditSplitGetResponseSchema } from './changeInvestorCreditSplitGetResponseSchema';

export const getChangeInvestorCreditSplitQueryParamsSchema = z.object({ company: z.string() });
/**
 * @description 200 response
 */
export const getChangeInvestorCreditSplit200Schema = z.lazy(() => changeInvestorCreditSplitGetResponseSchema);
/**
 * @description 505 response
 */
export const getChangeInvestorCreditSplit505Schema = z.any();
/**
 * @description 200 response
 */
export const getChangeInvestorCreditSplitQueryResponseSchema = z.lazy(() => changeInvestorCreditSplitGetResponseSchema);
