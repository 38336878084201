import { faker } from '@faker-js/faker';

import type { HearAboutGetResponse } from '../types/HearAboutGetResponse';

export function createHearAboutGetResponse(
  data: NonNullable<Partial<HearAboutGetResponse>> = {}
): NonNullable<HearAboutGetResponse> {
  return {
    ...{
      data: {
        events: faker.helpers.arrayElements([faker.string.alpha()]) as any,
        hear_about: faker.helpers.arrayElements([faker.string.alpha()]) as any,
      },
      response: {
        date: faker.string.alpha(),
        messages: faker.helpers.arrayElements([faker.string.alpha()]) as any,
        status: faker.number.int(),
        type: faker.string.alpha(),
      },
    },
    ...data,
  };
}
