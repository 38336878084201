import { z } from 'zod';

import { sendSubscriptionFormPostRequestSchema } from './sendSubscriptionFormPostRequestSchema';
import { sendSubscriptionFormPostResponseSchema } from './sendSubscriptionFormPostResponseSchema';

/**
 * @description 200 response
 */
export const postSendSubscriptionForm200Schema = z.lazy(() => sendSubscriptionFormPostResponseSchema);
/**
 * @description 505 response
 */
export const postSendSubscriptionForm505Schema = z.any();

export const postSendSubscriptionFormMutationRequestSchema = z.lazy(() => sendSubscriptionFormPostRequestSchema);
/**
 * @description 200 response
 */
export const postSendSubscriptionFormMutationResponseSchema = z.lazy(() => sendSubscriptionFormPostResponseSchema);
