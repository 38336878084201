import { http } from 'msw';

import { createGetListCountriesQueryResponse } from '../mocks/createGetListCountries';

export const getListCountriesHandler = http.get('*/list_countries', function handler(info) {
  return new Response(JSON.stringify(createGetListCountriesQueryResponse()), {
    headers: {
      'Content-Type': 'application/json',
    },
  });
});
