import { z } from 'zod';

import { revolutOpenBankingCallbackGetResponseSchema } from './revolutOpenBankingCallbackGetResponseSchema';

export const getRevolutOpenBankingCallbackQueryParamsSchema = z.object({ company: z.string(), code: z.string() });
/**
 * @description 200 response
 */
export const getRevolutOpenBankingCallback200Schema = z.lazy(() => revolutOpenBankingCallbackGetResponseSchema);
/**
 * @description 505 response
 */
export const getRevolutOpenBankingCallback505Schema = z.any();
/**
 * @description 200 response
 */
export const getRevolutOpenBankingCallbackQueryResponseSchema = z.lazy(
  () => revolutOpenBankingCallbackGetResponseSchema
);
