import { faker } from '@faker-js/faker';

import type {
  GetOneOffPaymentNotice200,
  GetOneOffPaymentNotice505,
  GetOneOffPaymentNoticeQueryParams,
  GetOneOffPaymentNoticeQueryResponse,
} from '../types/GetOneOffPaymentNotice';
import { createOneOffPaymentNoticeGetResponse } from './createOneOffPaymentNoticeGetResponse';

export function createGetOneOffPaymentNoticeQueryParams(): NonNullable<GetOneOffPaymentNoticeQueryParams> {
  return { reference: faker.string.alpha() };
}

/**
 * @description 200 response
 */
export function createGetOneOffPaymentNotice200(): NonNullable<GetOneOffPaymentNotice200> {
  return createOneOffPaymentNoticeGetResponse();
}

/**
 * @description 505 response
 */
export function createGetOneOffPaymentNotice505(): NonNullable<GetOneOffPaymentNotice505> {
  return undefined;
}

/**
 * @description 200 response
 */
export function createGetOneOffPaymentNoticeQueryResponse(): NonNullable<GetOneOffPaymentNoticeQueryResponse> {
  return createOneOffPaymentNoticeGetResponse();
}
