import { http } from 'msw';

import { createDeleteOneOffPaymentNoticeMutationResponse } from '../mocks/createDeleteOneOffPaymentNotice';

export const deleteOneOffPaymentNoticeHandler = http.delete('*/one_off_payment_notice', function handler(info) {
  return new Response(JSON.stringify(createDeleteOneOffPaymentNoticeMutationResponse()), {
    headers: {
      'Content-Type': 'application/json',
    },
  });
});
