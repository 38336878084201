import { z } from 'zod';

export const oneOffPaymentNoticeGetResponseSchema = z.object({
  data: z.object({
    one_off_payment_notices: z
      .array(z.object({ amount: z.number(), date: z.string(), reference: z.string() }))
      .optional(),
  }),
  response: z.object({ date: z.string(), messages: z.array(z.string()), status: z.number(), type: z.string() }),
});
