import { http } from 'msw';

import { createPostChangeInvestorCreditSplitMutationResponse } from '../mocks/createPostChangeInvestorCreditSplit';

export const postChangeInvestorCreditSplitHandler = http.post('*/change_investor_credit_split', function handler(info) {
  return new Response(JSON.stringify(createPostChangeInvestorCreditSplitMutationResponse()), {
    headers: {
      'Content-Type': 'application/json',
    },
  });
});
