import { http } from 'msw';

import { createGetChangeUnexpectedPaymentOutcomeQueryResponse } from '../mocks/createGetChangeUnexpectedPaymentOutcome';

export const getChangeUnexpectedPaymentOutcomeHandler = http.get(
  '*/change_unexpected_payment_outcome',
  function handler(info) {
    return new Response(JSON.stringify(createGetChangeUnexpectedPaymentOutcomeQueryResponse()), {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
);
