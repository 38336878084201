import { http } from 'msw';

import { createPostLoginMutationResponse } from '../mocks/createPostLogin';

export const postLoginHandler = http.post('*/login', function handler(info) {
  return new Response(JSON.stringify(createPostLoginMutationResponse()), {
    headers: {
      'Content-Type': 'application/json',
    },
  });
});
