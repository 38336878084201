import { z } from 'zod';

import { eligibilityPostRequestSchema } from './eligibilityPostRequestSchema';
import { eligibilityPostResponseSchema } from './eligibilityPostResponseSchema';

/**
 * @description 200 response
 */
export const postEligibility200Schema = z.lazy(() => eligibilityPostResponseSchema);
/**
 * @description 505 response
 */
export const postEligibility505Schema = z.any();

export const postEligibilityMutationRequestSchema = z.lazy(() => eligibilityPostRequestSchema);
/**
 * @description 200 response
 */
export const postEligibilityMutationResponseSchema = z.lazy(() => eligibilityPostResponseSchema);
