import { faker } from '@faker-js/faker';

import type { ChangeUnexpectedPaymentOutcomeGetResponse } from '../types/ChangeUnexpectedPaymentOutcomeGetResponse';

export function createChangeUnexpectedPaymentOutcomeGetResponse(
  data: NonNullable<Partial<ChangeUnexpectedPaymentOutcomeGetResponse>> = {}
): NonNullable<ChangeUnexpectedPaymentOutcomeGetResponse> {
  return {
    ...{
      data: {
        settings: faker.helpers.arrayElements([
          { display_name: faker.string.alpha(), setting: faker.string.alpha() },
        ]) as any,
      },
      response: {
        date: faker.string.alpha(),
        messages: faker.helpers.arrayElements([faker.string.alpha()]) as any,
        status: faker.number.int(),
        type: faker.string.alpha(),
      },
    },
    ...data,
  };
}
