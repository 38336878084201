import { z } from 'zod';

export const calculatorHistoryGetResponseSchema = z.object({
  data: z.object({
    results: z
      .array(
        z.object({
          accounts_records: z.string().optional(),
          age: z.number().optional(),
          annual_income: z.number().optional(),
          annual_increase: z.number().optional(),
          answer: z.string().optional(),
          arrangement_fee: z.number().optional(),
          average_annual_increase: z.number().optional(),
          buy_to_let: z.boolean().optional(),
          ccj_bankruptcy: z.string().optional(),
          commercial_let: z.string().optional(),
          continuous_employment: z.string().optional(),
          country: z.string().optional(),
          created_at: z.string(),
          deposit: z.number().optional(),
          employment_type: z.string().optional(),
          finance_amount: z.number().optional(),
          finance_purpose: z.string().optional(),
          first_time_buyer: z.string().optional(),
          have_inhabited: z.string().optional(),
          main_residence: z.string().optional(),
          message: z.string().optional(),
          monthly_affordability: z.number().optional(),
          monthly_payment: z.number().optional(),
          monthly_spend: z.number().optional(),
          nationality: z.string().optional(),
          net_monthly_income: z.number().optional(),
          own_other_lets: z.string().optional(),
          problem_params: z.string().optional(),
          property_location: z.string().optional(),
          property_type: z.string().optional(),
          property_value: z.number().optional(),
          term: z.number().optional(),
          total_payment_best_case: z.number().optional(),
          total_payment_mid_range: z.number().optional(),
          total_payment_worst_case: z.number().optional(),
          type: z.string(),
          username: z.string(),
          will_inhabit: z.string().optional(),
        })
      )
      .optional(),
  }),
  response: z.object({ date: z.string(), messages: z.array(z.string()), status: z.number(), type: z.string() }),
});
