import { http } from 'msw';

import { createGetChangeZakatDateQueryResponse } from '../mocks/createGetChangeZakatDate';

export const getChangeZakatDateHandler = http.get('*/change_zakat_date', function handler(info) {
  return new Response(JSON.stringify(createGetChangeZakatDateQueryResponse()), {
    headers: {
      'Content-Type': 'application/json',
    },
  });
});
