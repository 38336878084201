import type {
  GetEquityInvestmentPageVisited200,
  GetEquityInvestmentPageVisited505,
  GetEquityInvestmentPageVisitedQueryResponse,
} from '../types/GetEquityInvestmentPageVisited';
import { createEquityInvestmentPageVisitedGetResponse } from './createEquityInvestmentPageVisitedGetResponse';

/**
 * @description 200 response
 */
export function createGetEquityInvestmentPageVisited200(): NonNullable<GetEquityInvestmentPageVisited200> {
  return createEquityInvestmentPageVisitedGetResponse();
}

/**
 * @description 505 response
 */
export function createGetEquityInvestmentPageVisited505(): NonNullable<GetEquityInvestmentPageVisited505> {
  return undefined;
}

/**
 * @description 200 response
 */
export function createGetEquityInvestmentPageVisitedQueryResponse(): NonNullable<GetEquityInvestmentPageVisitedQueryResponse> {
  return createEquityInvestmentPageVisitedGetResponse();
}
