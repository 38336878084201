import './libs/sentry/sentryInit';

import * as Sentry from '@sentry/react';
import ReactDOM from 'react-dom/client';

import '@pfida-finance/ui/fonts/figtree';
import '@pfida-finance/ui/fonts/jeko';
import '@pfida-finance/ui/global.css';
import '@repo/ui/globals.css';
import './globals.css';

import { mifeName } from '@repo/single-spa';
import { registerApplication } from 'single-spa';

import { hydrateAuth } from './features/auth/store/authStore';
import { Router } from './libs/tanstackRouter/router';
import { AppProviders } from './providers';
import { initAnalytics } from './scripts/initialiseAnalytics';

registerApplication({
  name: mifeName,
  app: () => import(/* @vite-ignore */ mifeName).then((data) => data.vueParcel()),
  activeWhen: ['/'],
});

hydrateAuth();
initAnalytics();

function App() {
  return (
    <AppProviders>
      <Router />
    </AppProviders>
  );
}

// Render the app
const rootElement = document.getElementById('app')!;
if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement, {
    // Callback called when React automatically recovers from errors.
    onRecoverableError: Sentry.reactErrorHandler(),
  });
  root.render(<App />);
}
