import { z } from 'zod';

import { additionalUploadsPostRequestSchema } from './additionalUploadsPostRequestSchema';
import { additionalUploadsPostResponseSchema } from './additionalUploadsPostResponseSchema';

/**
 * @description 200 response
 */
export const postAdditionalUploads200Schema = z.lazy(() => additionalUploadsPostResponseSchema);
/**
 * @description 505 response
 */
export const postAdditionalUploads505Schema = z.any();

export const postAdditionalUploadsMutationRequestSchema = z.lazy(() => additionalUploadsPostRequestSchema);
/**
 * @description 200 response
 */
export const postAdditionalUploadsMutationResponseSchema = z.lazy(() => additionalUploadsPostResponseSchema);
