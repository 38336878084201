import { http } from 'msw';

import { createPostAccountTypeMutationResponse } from '../mocks/createPostAccountType';

export const postAccountTypeHandler = http.post('*/account_type', function handler(info) {
  return new Response(JSON.stringify(createPostAccountTypeMutationResponse()), {
    headers: {
      'Content-Type': 'application/json',
    },
  });
});
