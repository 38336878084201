import { z } from 'zod';

import { getUserObjectGetResponseSchema } from './getUserObjectGetResponseSchema';

export const getGetUserObjectQueryParamsSchema = z.object({ relative_key: z.string() });
/**
 * @description 200 response
 */
export const getGetUserObject200Schema = z.lazy(() => getUserObjectGetResponseSchema);
/**
 * @description 505 response
 */
export const getGetUserObject505Schema = z.any();
/**
 * @description 200 response
 */
export const getGetUserObjectQueryResponseSchema = z.lazy(() => getUserObjectGetResponseSchema);
