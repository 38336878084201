import type {
  PostResendConfirmationCode200,
  PostResendConfirmationCode505,
  PostResendConfirmationCodeMutationRequest,
  PostResendConfirmationCodeMutationResponse,
} from '../types/PostResendConfirmationCode';
import { createResendConfirmationCodePostRequest } from './createResendConfirmationCodePostRequest';
import { createResendConfirmationCodePostResponse } from './createResendConfirmationCodePostResponse';

/**
 * @description 200 response
 */
export function createPostResendConfirmationCode200(): NonNullable<PostResendConfirmationCode200> {
  return createResendConfirmationCodePostResponse();
}

/**
 * @description 505 response
 */
export function createPostResendConfirmationCode505(): NonNullable<PostResendConfirmationCode505> {
  return undefined;
}

export function createPostResendConfirmationCodeMutationRequest(): NonNullable<PostResendConfirmationCodeMutationRequest> {
  return createResendConfirmationCodePostRequest();
}

/**
 * @description 200 response
 */
export function createPostResendConfirmationCodeMutationResponse(): NonNullable<PostResendConfirmationCodeMutationResponse> {
  return createResendConfirmationCodePostResponse();
}
