import { http } from 'msw';

import { createGetInvestorDeclarationQueryResponse } from '../mocks/createGetInvestorDeclaration';

export const getInvestorDeclarationHandler = http.get('*/investor_declaration', function handler(info) {
  return new Response(JSON.stringify(createGetInvestorDeclarationQueryResponse()), {
    headers: {
      'Content-Type': 'application/json',
    },
  });
});
