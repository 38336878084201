import { z } from 'zod';

import { financeRequirementsPostRequestSchema } from './financeRequirementsPostRequestSchema';
import { financeRequirementsPostResponseSchema } from './financeRequirementsPostResponseSchema';

/**
 * @description 200 response
 */
export const postFinanceRequirements200Schema = z.lazy(() => financeRequirementsPostResponseSchema);
/**
 * @description 505 response
 */
export const postFinanceRequirements505Schema = z.any();

export const postFinanceRequirementsMutationRequestSchema = z.lazy(() => financeRequirementsPostRequestSchema);
/**
 * @description 200 response
 */
export const postFinanceRequirementsMutationResponseSchema = z.lazy(() => financeRequirementsPostResponseSchema);
