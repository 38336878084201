import { faker } from '@faker-js/faker';

import type { RevolutOpenBankingCallbackGetResponse } from '../types/RevolutOpenBankingCallbackGetResponse';

export function createRevolutOpenBankingCallbackGetResponse(
  data: NonNullable<Partial<RevolutOpenBankingCallbackGetResponse>> = {}
): NonNullable<RevolutOpenBankingCallbackGetResponse> {
  return {
    ...{
      data: { success: faker.string.alpha() },
      response: {
        date: faker.string.alpha(),
        messages: faker.helpers.arrayElements([faker.string.alpha()]) as any,
        status: faker.number.int(),
        type: faker.string.alpha(),
      },
    },
    ...data,
  };
}
