import type { GetReferrals200, GetReferrals505, GetReferralsQueryResponse } from '../types/GetReferrals';
import { createReferralsGetResponse } from './createReferralsGetResponse';

/**
 * @description 200 response
 */
export function createGetReferrals200(): NonNullable<GetReferrals200> {
  return createReferralsGetResponse();
}

/**
 * @description 505 response
 */
export function createGetReferrals505(): NonNullable<GetReferrals505> {
  return undefined;
}

/**
 * @description 200 response
 */
export function createGetReferralsQueryResponse(): NonNullable<GetReferralsQueryResponse> {
  return createReferralsGetResponse();
}
