import { z } from 'zod';

export const costCalculatorPostResponseSchema = z.object({
  data: z.object({
    annual_increase: z.number().optional(),
    arrangement_fee: z.number().optional(),
    average_annual_increase: z.number().optional(),
    buy_to_let: z.string().optional(),
    deposit: z.string().optional(),
    finance_amount: z.number().optional(),
    monthly_payment: z.number().optional(),
    property_value: z.string().optional(),
    term: z.string().optional(),
    total_payment_best_case: z.number().optional(),
    total_payment_mid_range: z.number().optional(),
    total_payment_worst_case: z.number().optional(),
  }),
  response: z.object({ date: z.string(), messages: z.array(z.string()), status: z.number(), type: z.string() }),
});
