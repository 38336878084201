import { z } from 'zod';

export const signUpPostRequestSchema = z.object({
  address: z.string(),
  birthdate: z.string(),
  city: z.string(),
  client: z.string(),
  client_secret: z.string(),
  email: z.string(),
  name: z.string(),
  password: z.string(),
  phone_number: z.string(),
  postcode: z.string(),
  username: z.string(),
});
