import type {
  GetChangeZakatDate200,
  GetChangeZakatDate505,
  GetChangeZakatDateQueryResponse,
} from '../types/GetChangeZakatDate';
import { createChangeZakatDateGetResponse } from './createChangeZakatDateGetResponse';

/**
 * @description 200 response
 */
export function createGetChangeZakatDate200(): NonNullable<GetChangeZakatDate200> {
  return createChangeZakatDateGetResponse();
}

/**
 * @description 505 response
 */
export function createGetChangeZakatDate505(): NonNullable<GetChangeZakatDate505> {
  return undefined;
}

/**
 * @description 200 response
 */
export function createGetChangeZakatDateQueryResponse(): NonNullable<GetChangeZakatDateQueryResponse> {
  return createChangeZakatDateGetResponse();
}
