import { http } from 'msw';

import { createPostLoginAuthChallengeMutationResponse } from '../mocks/createPostLoginAuthChallenge';

export const postLoginAuthChallengeHandler = http.post('*/login_auth_challenge', function handler(info) {
  return new Response(JSON.stringify(createPostLoginAuthChallengeMutationResponse()), {
    headers: {
      'Content-Type': 'application/json',
    },
  });
});
