import { http } from 'msw';

import { createPostSignUpConfirmMutationResponse } from '../mocks/createPostSignUpConfirm';

export const postSignUpConfirmHandler = http.post('*/sign_up_confirm', function handler(info) {
  return new Response(JSON.stringify(createPostSignUpConfirmMutationResponse()), {
    headers: {
      'Content-Type': 'application/json',
    },
  });
});
