import { http } from 'msw';

import { createPostChangeZakatDateMutationResponse } from '../mocks/createPostChangeZakatDate';

export const postChangeZakatDateHandler = http.post('*/change_zakat_date', function handler(info) {
  return new Response(JSON.stringify(createPostChangeZakatDateMutationResponse()), {
    headers: {
      'Content-Type': 'application/json',
    },
  });
});
