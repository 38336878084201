import { ChangeEvent } from 'react';
import { ControllerRenderProps, FieldPath, FieldValues } from 'react-hook-form';

export const onNumberInputChange = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  e: ChangeEvent<HTMLInputElement>,
  field: ControllerRenderProps<TFieldValues, TName>
) => {
  e.target.value = e.target.value.replace(/\D/g, '');
  field.onChange(e);
};
