import { z } from 'zod';

import { referralsPostRequestSchema } from './referralsPostRequestSchema';
import { referralsPostResponseSchema } from './referralsPostResponseSchema';

/**
 * @description 200 response
 */
export const postReferrals200Schema = z.lazy(() => referralsPostResponseSchema);
/**
 * @description 505 response
 */
export const postReferrals505Schema = z.any();

export const postReferralsMutationRequestSchema = z.lazy(() => referralsPostRequestSchema);
/**
 * @description 200 response
 */
export const postReferralsMutationResponseSchema = z.lazy(() => referralsPostResponseSchema);
