import { z } from 'zod';
import { trueFalseEnum } from './common';

const fieldRequiredMessage = 'This field is required';

export const employmentTypeEnum = z.enum(['Employed', 'Self-employed', 'Contractor', 'Retired', 'No employment'], {
  required_error: 'Please select an option',
});
export const employedTypeEnum = z.enum(['Permanent', 'Temporary'], { required_error: 'Please select an option' });
export const yearsOfIncomeProofEnum = z.enum(['1 year', '2 years+'], { required_error: 'Please select an option' });
export const monthsLeftOnContractEnum = z.enum(['Under 3 months', '3-6 months', '6-12 months', '12 months+'], {
  required_error: 'Please select an option',
});

export const applicantXEmploymentSchema = z
  .object({
    employmentType: employmentTypeEnum,
    anyOtherIncome: trueFalseEnum.optional(),

    // Conditional fields when anyOtherIncome = 'yes'
    otherIncomeTypes: z.array(z.string()).optional(),
    totalOtherIncomeAverage: z.string().optional(),

    // Conditional fields when employmentType = 'Employed'
    employmentStartDate: z.string().optional(),
    employedType: employedTypeEnum.optional(),
    grossAnnualIncome: z.string().optional(),
    netMonthlyIncome: z.string().optional(),
    anyOtherEmployment: trueFalseEnum.optional(),
    otherEmploymentDetails: z
      .string({ required_error: 'Please provide details about your other employment' })
      .optional(),

    // Conditional fields when employmentType = 'Self-employed'
    tradingLengthYears: z.string().optional(),
    yearsOfIncomeProof: yearsOfIncomeProofEnum.optional(),
    grossIncomeRecentYear: z.string().optional(),
    netIncomeRecentYear: z.string().optional(),
    grossIncomePreviousYear: z.string().optional(),
    netIncomePreviousYear: z.string().optional(),

    // Conditional fields when employmentType = 'Contractor'
    monthsLeftOnContract: monthsLeftOnContractEnum.optional(),
    willContractBeRenewed: trueFalseEnum.optional(),
  })
  .superRefine((input, ctx) => {
    if (!input.anyOtherIncome) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Please select an option',
        path: ['anyOtherIncome'],
      });
    }

    if (input.anyOtherIncome === 'true') {
      if (!input.otherIncomeTypes?.length) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Please select at least one type of income',
          path: ['otherIncomeTypes'],
        });
      }
      if (!input.totalOtherIncomeAverage) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: fieldRequiredMessage,
          path: ['totalOtherIncomeAverage'],
        });
      }
    }

    if (
      ([employmentTypeEnum.Enum.Employed, employmentTypeEnum.Enum.Contractor] as string[]).includes(
        input.employmentType
      )
    ) {
      if (!input.employmentStartDate) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Employment start date is required',
          path: ['employmentStartDate'],
        });
      } else {
        const employmentStartDate = new Date(input.employmentStartDate);
        if (employmentStartDate > new Date()) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'The date cannot be in the future. Please enter a valid past date',
            path: ['employmentStartDate'],
          });
        }

        if (employmentStartDate < new Date('1900-01-01')) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Please enter a date after 01 January 1900',
            path: ['employmentStartDate'],
          });
        }
      }

      if (!input.grossAnnualIncome) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Gross Annual Income is required',
          path: ['grossAnnualIncome'],
        });
      }
      if (!input.netMonthlyIncome) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Net Monthly Income is required',
          path: ['netMonthlyIncome'],
        });
      }
    }

    if (input.employmentType === employmentTypeEnum.Enum.Employed) {
      if (!input.employedType) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Please select an option',
          path: ['employedType'],
        });
      }
    }

    if (input.employmentType === employmentTypeEnum.Enum['Self-employed']) {
      if (!input.tradingLengthYears) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Please specify how long you have been trading',
          path: ['tradingLengthYears'],
        });
      }
      if (!input.yearsOfIncomeProof) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Please select an option',
          path: ['yearsOfIncomeProof'],
        });
      }
      if (input.yearsOfIncomeProof) {
        if (!input.grossIncomeRecentYear) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Please enter an amount',
            path: ['grossIncomeRecentYear'],
          });
        }
        if (!input.netIncomeRecentYear) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Please enter an amount',
            path: ['netIncomeRecentYear'],
          });
        }
        if (input.yearsOfIncomeProof === yearsOfIncomeProofEnum.Enum['2 years+']) {
          if (!input.grossIncomePreviousYear) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Please enter an amount',
              path: ['grossIncomePreviousYear'],
            });
          }
          if (!input.netIncomePreviousYear) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Please enter an amount',
              path: ['netIncomePreviousYear'],
            });
          }
        }
      }
    }

    if (
      (
        [
          employmentTypeEnum.Enum.Employed,
          employmentTypeEnum.Enum['Self-employed'],
          employmentTypeEnum.Enum.Contractor,
        ] as string[]
      ).includes(input.employmentType)
    ) {
      if (!input.anyOtherEmployment) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Please select an option',
          path: ['anyOtherEmployment'],
        });
      }

      if (input.anyOtherEmployment === 'true' && !input.otherEmploymentDetails) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: fieldRequiredMessage,
          path: ['otherEmploymentDetails'],
        });
      }
    }

    if (input.employmentType === employmentTypeEnum.Enum.Contractor) {
      if (!input.monthsLeftOnContract) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: fieldRequiredMessage,
          path: ['monthsLeftOnContact'],
        });
      }
      if (!input.willContractBeRenewed) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: fieldRequiredMessage,
          path: ['willContractBeRenewed'],
        });
      }
    }
  });

export type ApplicantXEmploymentSchema = z.infer<typeof applicantXEmploymentSchema>;
