import { http } from 'msw';

import { createPostToggleDocumentUnreadMutationResponse } from '../mocks/createPostToggleDocumentUnread';

export const postToggleDocumentUnreadHandler = http.post('*/toggle_document_unread', function handler(info) {
  return new Response(JSON.stringify(createPostToggleDocumentUnreadMutationResponse()), {
    headers: {
      'Content-Type': 'application/json',
    },
  });
});
