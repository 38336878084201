import type {
  PostUpdateProfile200,
  PostUpdateProfile505,
  PostUpdateProfileMutationRequest,
  PostUpdateProfileMutationResponse,
} from '../types/PostUpdateProfile';
import { createUpdateProfilePostRequest } from './createUpdateProfilePostRequest';
import { createUpdateProfilePostResponse } from './createUpdateProfilePostResponse';

/**
 * @description 200 response
 */
export function createPostUpdateProfile200(): NonNullable<PostUpdateProfile200> {
  return createUpdateProfilePostResponse();
}

/**
 * @description 505 response
 */
export function createPostUpdateProfile505(): NonNullable<PostUpdateProfile505> {
  return undefined;
}

export function createPostUpdateProfileMutationRequest(): NonNullable<PostUpdateProfileMutationRequest> {
  return createUpdateProfilePostRequest();
}

/**
 * @description 200 response
 */
export function createPostUpdateProfileMutationResponse(): NonNullable<PostUpdateProfileMutationResponse> {
  return createUpdateProfilePostResponse();
}
