import { http } from 'msw';

import { createPostInvestorDeclarationMutationResponse } from '../mocks/createPostInvestorDeclaration';

export const postInvestorDeclarationHandler = http.post('*/investor_declaration', function handler(info) {
  return new Response(JSON.stringify(createPostInvestorDeclarationMutationResponse()), {
    headers: {
      'Content-Type': 'application/json',
    },
  });
});
