import { faker } from '@faker-js/faker';

import type { ListUserObjectsGetResponse } from '../types/ListUserObjectsGetResponse';

export function createListUserObjectsGetResponse(
  data: NonNullable<Partial<ListUserObjectsGetResponse>> = {}
): NonNullable<ListUserObjectsGetResponse> {
  return {
    ...{
      data: {
        any_unread: faker.datatype.boolean(),
        user_objects_v2: faker.helpers.arrayElements([
          {
            display_name: faker.string.alpha(),
            last_modified: faker.string.alpha(),
            relative_key: faker.string.alpha(),
            unread: faker.datatype.boolean(),
          },
        ]) as any,
      },
      response: {
        date: faker.string.alpha(),
        messages: faker.helpers.arrayElements([faker.string.alpha()]) as any,
        status: faker.number.int(),
        type: faker.string.alpha(),
      },
    },
    ...data,
  };
}
