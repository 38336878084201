import { z } from 'zod';

import { changePasswordPostRequestSchema } from './changePasswordPostRequestSchema';
import { changePasswordPostResponseSchema } from './changePasswordPostResponseSchema';

/**
 * @description 200 response
 */
export const postChangePassword200Schema = z.lazy(() => changePasswordPostResponseSchema);
/**
 * @description 505 response
 */
export const postChangePassword505Schema = z.any();

export const postChangePasswordMutationRequestSchema = z.lazy(() => changePasswordPostRequestSchema);
/**
 * @description 200 response
 */
export const postChangePasswordMutationResponseSchema = z.lazy(() => changePasswordPostResponseSchema);
