import { http } from 'msw';

import { createGetChangeRetirementDateQueryResponse } from '../mocks/createGetChangeRetirementDate';

export const getChangeRetirementDateHandler = http.get('*/change_retirement_date', function handler(info) {
  return new Response(JSON.stringify(createGetChangeRetirementDateQueryResponse()), {
    headers: {
      'Content-Type': 'application/json',
    },
  });
});
