import { HomeIcon } from '@repo/assets/shared';
import image404 from '@repo/assets/shared/images/404';
import background from '@repo/assets/shared/images/login-background';
import { Button } from '@repo/ui/components/ui/button';
import { Link, NotFoundRouteProps } from '@tanstack/react-router';

export function RouteNotFoundErrorBoundary(props: NotFoundRouteProps) {
  return (
    <div style={{ backgroundImage: `url(${background})` }} className="bg-img flex min-h-screen bg-cover">
      <div className="mx-auto flex flex-col items-center justify-center text-center sm:w-3/4 md:w-3/5 lg:w-3/4 xl:w-1/2">
        {/* <img src={String(dupa)} alt="background" className="h-full w-full border-2 border-black object-cover" /> */}
        <img src={String(image404)} alt="graphic-404" className="mx-auto mb-4 max-w-full" />
        <h1 className="d-theme-heading-color mx-2 mb-4 text-6xl sm:mx-0">404</h1>
        <h2 className="d-theme-heading-color mx-4 mb-8 sm:mx-0 sm:text-3xl md:text-5xl">Page Not Found</h2>
        <p className="sm:mb-base mx-4 mb-4 sm:mx-0">Oops! The page you are looking for does not exist.</p>
        <Button asChild>
          <Link to="/">
            Back to Home <HomeIcon className="ml-2" size={20} />
          </Link>
        </Button>
      </div>
    </div>
  );
}
