import { http } from 'msw';

import { createGetEquityInvestmentPageVisitedQueryResponse } from '../mocks/createGetEquityInvestmentPageVisited';

export const getEquityInvestmentPageVisitedHandler = http.get(
  '*/equity_investment_page_visited',
  function handler(info) {
    return new Response(JSON.stringify(createGetEquityInvestmentPageVisitedQueryResponse()), {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
);
