import { http } from 'msw';

import { createPostCostCalculatorAuthenticatedMutationResponse } from '../mocks/createPostCostCalculatorAuthenticated';

export const postCostCalculatorAuthenticatedHandler = http.post(
  '*/cost_calculator_authenticated',
  function handler(info) {
    return new Response(JSON.stringify(createPostCostCalculatorAuthenticatedMutationResponse()), {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
);
