import { z } from 'zod';

import { accountTypePostRequestSchema } from './accountTypePostRequestSchema';
import { accountTypePostResponseSchema } from './accountTypePostResponseSchema';

/**
 * @description 200 response
 */
export const postAccountType200Schema = z.lazy(() => accountTypePostResponseSchema);
/**
 * @description 505 response
 */
export const postAccountType505Schema = z.any();

export const postAccountTypeMutationRequestSchema = z.lazy(() => accountTypePostRequestSchema);
/**
 * @description 200 response
 */
export const postAccountTypeMutationResponseSchema = z.lazy(() => accountTypePostResponseSchema);
