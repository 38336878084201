import { GrowthBook, GrowthBookProvider as Provider } from '@growthbook/growthbook-react';
import { clientEnv } from '@repo/utils';
import type { PropsWithChildren } from 'react';
import { useEffect } from 'react';

const clientKey = clientEnv.VITE_GROWTHBOOK;

export type AppFeatures = {
  'enable-dip': boolean;
};

export type AppFeaturesNames = keyof AppFeatures;

export const growthbook = new GrowthBook<AppFeatures>({
  apiHost: 'https://cdn.growthbook.io',
  clientKey,
  enableDevMode: clientEnv.VITE_APP_MODE === 'development',
  subscribeToChanges: true,
});

export type Growthbook = typeof growthbook;

export function GrowthbookProvider({ children }: PropsWithChildren) {
  useEffect(() => {
    growthbook.init();
  }, []);

  return <Provider growthbook={growthbook}>{children}</Provider>;
}
