import { faker } from '@faker-js/faker';

import type { IfaApprovalGetResponse } from '../types/IfaApprovalGetResponse';

export function createIfaApprovalGetResponse(
  data: NonNullable<Partial<IfaApprovalGetResponse>> = {}
): NonNullable<IfaApprovalGetResponse> {
  return {
    ...{
      data: {
        ifa_details: { company_name: faker.string.alpha(), email: faker.string.alpha(), name: faker.string.alpha() },
        users: faker.helpers.arrayElements([{ email: faker.string.alpha(), name: faker.string.alpha() }]) as any,
      },
      response: {
        date: faker.string.alpha(),
        messages: faker.helpers.arrayElements([faker.string.alpha()]) as any,
        status: faker.number.int(),
        type: faker.string.alpha(),
      },
    },
    ...data,
  };
}
