import type {
  PostSendSubscriptionForm200,
  PostSendSubscriptionForm505,
  PostSendSubscriptionFormMutationRequest,
  PostSendSubscriptionFormMutationResponse,
} from '../types/PostSendSubscriptionForm';
import { createSendSubscriptionFormPostRequest } from './createSendSubscriptionFormPostRequest';
import { createSendSubscriptionFormPostResponse } from './createSendSubscriptionFormPostResponse';

/**
 * @description 200 response
 */
export function createPostSendSubscriptionForm200(): NonNullable<PostSendSubscriptionForm200> {
  return createSendSubscriptionFormPostResponse();
}

/**
 * @description 505 response
 */
export function createPostSendSubscriptionForm505(): NonNullable<PostSendSubscriptionForm505> {
  return undefined;
}

export function createPostSendSubscriptionFormMutationRequest(): NonNullable<PostSendSubscriptionFormMutationRequest> {
  return createSendSubscriptionFormPostRequest();
}

/**
 * @description 200 response
 */
export function createPostSendSubscriptionFormMutationResponse(): NonNullable<PostSendSubscriptionFormMutationResponse> {
  return createSendSubscriptionFormPostResponse();
}
