import type {
  PostMarketingPreferences200,
  PostMarketingPreferences505,
  PostMarketingPreferencesMutationRequest,
  PostMarketingPreferencesMutationResponse,
} from '../types/PostMarketingPreferences';
import { createMarketingPreferencesPostRequest } from './createMarketingPreferencesPostRequest';
import { createMarketingPreferencesPostResponse } from './createMarketingPreferencesPostResponse';

/**
 * @description 200 response
 */
export function createPostMarketingPreferences200(): NonNullable<PostMarketingPreferences200> {
  return createMarketingPreferencesPostResponse();
}

/**
 * @description 505 response
 */
export function createPostMarketingPreferences505(): NonNullable<PostMarketingPreferences505> {
  return undefined;
}

export function createPostMarketingPreferencesMutationRequest(): NonNullable<PostMarketingPreferencesMutationRequest> {
  return createMarketingPreferencesPostRequest();
}

/**
 * @description 200 response
 */
export function createPostMarketingPreferencesMutationResponse(): NonNullable<PostMarketingPreferencesMutationResponse> {
  return createMarketingPreferencesPostResponse();
}
