import { growthbook } from '@/libs/growthbook';
import { useNavigate } from '@tanstack/react-router';
import { Step, useDipApplicationFormContext } from '../context/dipApplicationFormContext';
import { Applicant2Employment } from './steps/applicant2Employment';
import { ApplicantXEmployment } from './steps/applicantXEmployment';
import { Details } from './steps/details';
import { End } from './steps/end';
import { OutgoingsAndCommitments } from './steps/outgoingsAndCommitments';
import { PropertyAndFinance } from './steps/propertyAndFinance';
import { Start } from './steps/start';
import { Timeframe } from './steps/timeframe';

const steps: Record<Step, React.ReactNode> = {
  start: <Start />,
  timeFrame: <Timeframe />,
  userDetails: <Details />,
  applicantOneEmploymentDetails: <ApplicantXEmployment applicantNumber="1" />,
  applicantTwoEmploymentDetails: <Applicant2Employment />,
  outgoingsAndCommitments: <OutgoingsAndCommitments />,
  propertyAndFinance: <PropertyAndFinance />,
  end: <End />,
};

export function DipApplicationForm() {
  const { currentStep } = useDipApplicationFormContext();
  const navigate = useNavigate();

  if (!growthbook.isOn('enable-dip')) {
    navigate({ to: '/dashboard' });
  }

  return <div className="flex flex-col items-center gap-6 w-full max-w-[768px] mx-auto">{steps[currentStep]}</div>;
}
