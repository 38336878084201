import { faker } from '@faker-js/faker';

import type { DeleteAccountPostResponse } from '../types/DeleteAccountPostResponse';

export function createDeleteAccountPostResponse(
  data: NonNullable<Partial<DeleteAccountPostResponse>> = {}
): NonNullable<DeleteAccountPostResponse> {
  return {
    ...{
      data: {},
      response: {
        date: faker.string.alpha(),
        messages: faker.helpers.arrayElements([faker.string.alpha()]) as any,
        status: faker.number.int(),
        type: faker.string.alpha(),
      },
    },
    ...data,
  };
}
