import type {
  PostOneOffPaymentNotice200,
  PostOneOffPaymentNotice505,
  PostOneOffPaymentNoticeMutationRequest,
  PostOneOffPaymentNoticeMutationResponse,
} from '../types/PostOneOffPaymentNotice';
import { createOneOffPaymentNoticePostRequest } from './createOneOffPaymentNoticePostRequest';
import { createOneOffPaymentNoticePostResponse } from './createOneOffPaymentNoticePostResponse';

/**
 * @description 200 response
 */
export function createPostOneOffPaymentNotice200(): NonNullable<PostOneOffPaymentNotice200> {
  return createOneOffPaymentNoticePostResponse();
}

/**
 * @description 505 response
 */
export function createPostOneOffPaymentNotice505(): NonNullable<PostOneOffPaymentNotice505> {
  return undefined;
}

export function createPostOneOffPaymentNoticeMutationRequest(): NonNullable<PostOneOffPaymentNoticeMutationRequest> {
  return createOneOffPaymentNoticePostRequest();
}

/**
 * @description 200 response
 */
export function createPostOneOffPaymentNoticeMutationResponse(): NonNullable<PostOneOffPaymentNoticeMutationResponse> {
  return createOneOffPaymentNoticePostResponse();
}
