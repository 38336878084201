import type {
  PostToggleMfa200,
  PostToggleMfa505,
  PostToggleMfaMutationRequest,
  PostToggleMfaMutationResponse,
} from '../types/PostToggleMfa';
import { createToggleMfaPostRequest } from './createToggleMfaPostRequest';
import { createToggleMfaPostResponse } from './createToggleMfaPostResponse';

/**
 * @description 200 response
 */
export function createPostToggleMfa200(): NonNullable<PostToggleMfa200> {
  return createToggleMfaPostResponse();
}

/**
 * @description 505 response
 */
export function createPostToggleMfa505(): NonNullable<PostToggleMfa505> {
  return undefined;
}

export function createPostToggleMfaMutationRequest(): NonNullable<PostToggleMfaMutationRequest> {
  return createToggleMfaPostRequest();
}

/**
 * @description 200 response
 */
export function createPostToggleMfaMutationResponse(): NonNullable<PostToggleMfaMutationResponse> {
  return createToggleMfaPostResponse();
}
