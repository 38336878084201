import type {
  PostInvestorDeclaration200,
  PostInvestorDeclaration505,
  PostInvestorDeclarationMutationRequest,
  PostInvestorDeclarationMutationResponse,
} from '../types/PostInvestorDeclaration';
import { createInvestorDeclarationPostRequest } from './createInvestorDeclarationPostRequest';
import { createInvestorDeclarationPostResponse } from './createInvestorDeclarationPostResponse';

/**
 * @description 200 response
 */
export function createPostInvestorDeclaration200(): NonNullable<PostInvestorDeclaration200> {
  return createInvestorDeclarationPostResponse();
}

/**
 * @description 505 response
 */
export function createPostInvestorDeclaration505(): NonNullable<PostInvestorDeclaration505> {
  return undefined;
}

export function createPostInvestorDeclarationMutationRequest(): NonNullable<PostInvestorDeclarationMutationRequest> {
  return createInvestorDeclarationPostRequest();
}

/**
 * @description 200 response
 */
export function createPostInvestorDeclarationMutationResponse(): NonNullable<PostInvestorDeclarationMutationResponse> {
  return createInvestorDeclarationPostResponse();
}
