import type {
  PostLogin200,
  PostLogin505,
  PostLoginMutationRequest,
  PostLoginMutationResponse,
} from '../types/PostLogin';
import { createLoginPostRequest } from './createLoginPostRequest';
import { createLoginPostResponse } from './createLoginPostResponse';

/**
 * @description 200 response
 */
export function createPostLogin200(): NonNullable<PostLogin200> {
  return createLoginPostResponse();
}

/**
 * @description 505 response
 */
export function createPostLogin505(): NonNullable<PostLogin505> {
  return undefined;
}

export function createPostLoginMutationRequest(): NonNullable<PostLoginMutationRequest> {
  return createLoginPostRequest();
}

/**
 * @description 200 response
 */
export function createPostLoginMutationResponse(): NonNullable<PostLoginMutationResponse> {
  return createLoginPostResponse();
}
