import { z } from 'zod';

import { marketingPreferencesPostRequestSchema } from './marketingPreferencesPostRequestSchema';
import { marketingPreferencesPostResponseSchema } from './marketingPreferencesPostResponseSchema';

/**
 * @description 200 response
 */
export const postMarketingPreferences200Schema = z.lazy(() => marketingPreferencesPostResponseSchema);
/**
 * @description 505 response
 */
export const postMarketingPreferences505Schema = z.any();

export const postMarketingPreferencesMutationRequestSchema = z.lazy(() => marketingPreferencesPostRequestSchema);
/**
 * @description 200 response
 */
export const postMarketingPreferencesMutationResponseSchema = z.lazy(() => marketingPreferencesPostResponseSchema);
