import type {
  GetInvestorDeclaration200,
  GetInvestorDeclaration505,
  GetInvestorDeclarationQueryResponse,
} from '../types/GetInvestorDeclaration';
import { createInvestorDeclarationGetResponse } from './createInvestorDeclarationGetResponse';

/**
 * @description 200 response
 */
export function createGetInvestorDeclaration200(): NonNullable<GetInvestorDeclaration200> {
  return createInvestorDeclarationGetResponse();
}

/**
 * @description 505 response
 */
export function createGetInvestorDeclaration505(): NonNullable<GetInvestorDeclaration505> {
  return undefined;
}

/**
 * @description 200 response
 */
export function createGetInvestorDeclarationQueryResponse(): NonNullable<GetInvestorDeclarationQueryResponse> {
  return createInvestorDeclarationGetResponse();
}
