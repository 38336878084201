import { z } from 'zod';

import { bankDetailsGetResponseSchema } from './bankDetailsGetResponseSchema';

/**
 * @description 200 response
 */
export const getBankDetails200Schema = z.lazy(() => bankDetailsGetResponseSchema);
/**
 * @description 505 response
 */
export const getBankDetails505Schema = z.any();
/**
 * @description 200 response
 */
export const getBankDetailsQueryResponseSchema = z.lazy(() => bankDetailsGetResponseSchema);
