import type {
  GetCalculatorHistory200,
  GetCalculatorHistory505,
  GetCalculatorHistoryQueryResponse,
} from '../types/GetCalculatorHistory';
import { createCalculatorHistoryGetResponse } from './createCalculatorHistoryGetResponse';

/**
 * @description 200 response
 */
export function createGetCalculatorHistory200(): NonNullable<GetCalculatorHistory200> {
  return createCalculatorHistoryGetResponse();
}

/**
 * @description 505 response
 */
export function createGetCalculatorHistory505(): NonNullable<GetCalculatorHistory505> {
  return undefined;
}

/**
 * @description 200 response
 */
export function createGetCalculatorHistoryQueryResponse(): NonNullable<GetCalculatorHistoryQueryResponse> {
  return createCalculatorHistoryGetResponse();
}
