import type {
  PostChangeMonthlyPayment200,
  PostChangeMonthlyPayment505,
  PostChangeMonthlyPaymentMutationRequest,
  PostChangeMonthlyPaymentMutationResponse,
} from '../types/PostChangeMonthlyPayment';
import { createChangeMonthlyPaymentPostRequest } from './createChangeMonthlyPaymentPostRequest';
import { createChangeMonthlyPaymentPostResponse } from './createChangeMonthlyPaymentPostResponse';

/**
 * @description 200 response
 */
export function createPostChangeMonthlyPayment200(): NonNullable<PostChangeMonthlyPayment200> {
  return createChangeMonthlyPaymentPostResponse();
}

/**
 * @description 505 response
 */
export function createPostChangeMonthlyPayment505(): NonNullable<PostChangeMonthlyPayment505> {
  return undefined;
}

export function createPostChangeMonthlyPaymentMutationRequest(): NonNullable<PostChangeMonthlyPaymentMutationRequest> {
  return createChangeMonthlyPaymentPostRequest();
}

/**
 * @description 200 response
 */
export function createPostChangeMonthlyPaymentMutationResponse(): NonNullable<PostChangeMonthlyPaymentMutationResponse> {
  return createChangeMonthlyPaymentPostResponse();
}
