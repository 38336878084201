import { http } from 'msw';

import { createPostChangeUnexpectedPaymentOutcomeMutationResponse } from '../mocks/createPostChangeUnexpectedPaymentOutcome';

export const postChangeUnexpectedPaymentOutcomeHandler = http.post(
  '*/change_unexpected_payment_outcome',
  function handler(info) {
    return new Response(JSON.stringify(createPostChangeUnexpectedPaymentOutcomeMutationResponse()), {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
);
