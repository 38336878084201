import { z } from 'zod';

export const affordabilityCalculatorAuthenticatedPostResponseSchema = z.object({
  data: z.object({
    annual_income: z.string().optional(),
    deposit: z.string().optional(),
    finance_amount: z.string().optional(),
    monthly_affordability: z.string().optional(),
    monthly_spend: z.string().optional(),
    net_monthly_income: z.string().optional(),
  }),
  response: z.object({ date: z.string(), messages: z.array(z.string()), status: z.number(), type: z.string() }),
});
