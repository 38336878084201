import { clientEnv } from '@repo/utils';
import * as Sentry from '@sentry/react';

import { router } from '../tanstackRouter/router';

const EXTRA_KEY = 'ROUTE_TO';

const transport = Sentry.makeMultiplexedTransport(Sentry.makeFetchTransport, (args) => {
  const event = args.getEvent();
  if (event && event.extra && EXTRA_KEY in event.extra && Array.isArray(event.extra[EXTRA_KEY])) {
    return event.extra[EXTRA_KEY];
  }
  return [];
});

Sentry.init({
  dsn: 'https://21b7212caa7771a65f2ffae55cbdfe51@o4506071093084160.ingest.us.sentry.io/4506178970648576',
  environment: clientEnv.VITE_APP_MODE,
  tunnel: 'https://sentry.pfida.com/envelope',
  integrations: [
    Sentry.moduleMetadataIntegration(),
    Sentry.tanstackRouterBrowserTracingIntegration(router),
    Sentry.zodErrorsIntegration(),
  ],
  transport,
  beforeSend: handleSentryBeforeSend,
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0,
  tracePropagationTargets: ['https://api-dev.pfida.com/', 'https://api.pfida.com/'],
});

function handleSentryBeforeSend(event: Sentry.ErrorEvent, hint: Sentry.EventHint) {
  addExtraKey(event, hint);
  return event;
}

function addExtraKey(event: Sentry.ErrorEvent, hint: Sentry.EventHint) {
  if (event?.exception?.values?.[0]?.stacktrace?.frames) {
    const { frames } = event.exception.values[0].stacktrace;
    // Find the last frame with module metadata containing a DSN
    const routeTo = frames
      .filter((frame) => frame.module_metadata && frame.module_metadata.dsn)
      .map((v) => v.module_metadata)
      .slice(-1); // using top frame only - you may want to customize this according to your needs

    if (routeTo.length) {
      // eslint-disable-next-line no-param-reassign
      event.extra = {
        ...event.extra,
        [EXTRA_KEY]: routeTo,
      };
    }
  }
}
