import { z } from 'zod';

import { downloadTransactionsStatementPostRequestSchema } from './downloadTransactionsStatementPostRequestSchema';
import { downloadTransactionsStatementPostResponseSchema } from './downloadTransactionsStatementPostResponseSchema';

/**
 * @description 200 response
 */
export const postDownloadTransactionsStatement200Schema = z.lazy(() => downloadTransactionsStatementPostResponseSchema);
/**
 * @description 505 response
 */
export const postDownloadTransactionsStatement505Schema = z.any();

export const postDownloadTransactionsStatementMutationRequestSchema = z.lazy(
  () => downloadTransactionsStatementPostRequestSchema
);
/**
 * @description 200 response
 */
export const postDownloadTransactionsStatementMutationResponseSchema = z.lazy(
  () => downloadTransactionsStatementPostResponseSchema
);
