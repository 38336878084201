import type { GetAccountType200, GetAccountType505, GetAccountTypeQueryResponse } from '../types/GetAccountType';
import { createAccountTypeGetResponse } from './createAccountTypeGetResponse';

/**
 * @description 200 response
 */
export function createGetAccountType200(): NonNullable<GetAccountType200> {
  return createAccountTypeGetResponse();
}

/**
 * @description 505 response
 */
export function createGetAccountType505(): NonNullable<GetAccountType505> {
  return undefined;
}

/**
 * @description 200 response
 */
export function createGetAccountTypeQueryResponse(): NonNullable<GetAccountTypeQueryResponse> {
  return createAccountTypeGetResponse();
}
