import type { QueryKey, UseSuspenseQueryOptions, UseSuspenseQueryResult } from '@tanstack/react-query';
import { queryOptions, useSuspenseQuery } from '@tanstack/react-query';
import type { RequestConfig } from '../../client.ts';
import client from '../../client.ts';
import { listDipApplicationsQueryResponseSchema } from '../schemas/listDipApplicationsSchema.ts';
import type { ListDipApplications500, ListDipApplicationsQueryResponse } from '../types/ListDipApplications.ts';

export const listDipApplicationsSuspenseQueryKey = () => ['DIP_COMPONENT', { url: '/user/applications' }] as const;

export type ListDipApplicationsSuspenseQueryKey = ReturnType<typeof listDipApplicationsSuspenseQueryKey>;

/**
 * @description User API to retrieve a list of DIP applications.
 * @summary Get DIP Applications by User
 * {@link /user/applications}
 */
async function listDipApplications(config: Partial<RequestConfig> = {}) {
  const res = await client<ListDipApplicationsQueryResponse, ListDipApplications500, unknown>({
    method: 'GET',
    url: `/user/applications`,
    ...config,
  });
  return listDipApplicationsQueryResponseSchema.parse(res.data);
}

export function listDipApplicationsSuspenseQueryOptions(config: Partial<RequestConfig> = {}) {
  const queryKey = listDipApplicationsSuspenseQueryKey();
  return queryOptions({
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return listDipApplications(config);
    },
  });
}

/**
 * @description User API to retrieve a list of DIP applications.
 * @summary Get DIP Applications by User
 * {@link /user/applications}
 */
export function useListDipApplicationsSuspense<
  TData = ListDipApplicationsQueryResponse,
  TQueryData = ListDipApplicationsQueryResponse,
  TQueryKey extends QueryKey = ListDipApplicationsSuspenseQueryKey,
>(
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<ListDipApplicationsQueryResponse, ListDipApplications500, TData, TQueryKey>
    >;
    client?: Partial<RequestConfig>;
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? listDipApplicationsSuspenseQueryKey();
  const query = useSuspenseQuery({
    ...(listDipApplicationsSuspenseQueryOptions(config) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<TData, ListDipApplications500> & {
    queryKey: TQueryKey;
  };
  query.queryKey = queryKey as TQueryKey;
  return query;
}
