/* eslint-disable @typescript-eslint/no-throw-literal */
import { AuthenticatedAppShell } from '@/components/authenticatedAppShell';
import { signOut, useAuth } from '@/features/auth/store/authStore';
import { canAccessInvestorType } from '@/features/misc/utils/canAccessInvestorType';
import {
  getInvestmentDetailsQueryOptions,
  getInvestorDeclarationSuspenseQueryOptions,
  getListUserObjectsSuspenseQueryOptions,
  getReferralsSuspenseQueryOptions,
  useGetInvestmentDetailsSuspense,
  useGetInvestorDeclarationSuspense,
  useGetListUserObjectsSuspense,
  useGetReferralsSuspense,
} from '@repo/api/services/userBackend/gen';
import { isTenant } from '@repo/utils';
import { Outlet, createFileRoute, redirect } from '@tanstack/react-router';
import { useMemo } from 'react';
import { z } from 'zod';

import { canAccessAccountSettings } from './_authenticated/_pfida/account-settings';
import { canAccessDocument } from './_authenticated/_pfida/documents';
import { canAccessInvestorHub } from './_authenticated/_pfida/investor-hub';
import { canAccessReferrals } from './_authenticated/_pfida/referrals';

const AuthenticatedSearchQueryValidation = z.object({
  referral: z.string().optional(),
  company: z.string().optional(),
});

export const Route = createFileRoute('/_authenticated')({
  validateSearch: AuthenticatedSearchQueryValidation,
  beforeLoad: async ({ location, search }) => {
    const { isAuthenticated } = useAuth.getState();

    if (!isAuthenticated()) {
      signOut();
      throw redirect({
        to: '/login',
        search: {
          to: location.href,
          referral: search.referral,
          company: search.company,
        },
      });
    }
  },
  loader: ({ context: { queryClient } }) => {
    const { isAuthenticated } = useAuth.getState();

    if (isAuthenticated()) {
      queryClient.ensureQueryData(getListUserObjectsSuspenseQueryOptions());
      queryClient.ensureQueryData(getReferralsSuspenseQueryOptions());
      queryClient.ensureQueryData(getInvestorDeclarationSuspenseQueryOptions());
      queryClient.ensureQueryData(getInvestmentDetailsQueryOptions());
    }
  },
  component: () => <Authenticated />,
});

function Authenticated() {
  const userListObjects = useGetListUserObjectsSuspense();
  const accessInvestorDeclaration = useGetInvestorDeclarationSuspense();
  const referrals = useGetReferralsSuspense();
  const investmentDetails = useGetInvestmentDetailsSuspense();

  const numberOfUnreadDocuments = useMemo(() => {
    if (userListObjects.data.data.any_unread) {
      return userListObjects.data.data.user_objects_v2?.length;
    }

    return 0;
  }, [userListObjects.data.data.any_unread, userListObjects.data.data.user_objects_v2?.length]);

  const routesToShow = useMemo(
    () => ({
      '/dashboard': true,
      '/investor_type': canAccessInvestorType(accessInvestorDeclaration.data.data.action),
      '/referrals': canAccessReferrals(referrals.data.data.show_referrals),
      '/documents': canAccessDocument(),
      '/account-settings': canAccessAccountSettings(),
      '/investor-hub': canAccessInvestorHub(investmentDetails.data.data.pfidac),
      help: isTenant('pfida'),
    }),
    [accessInvestorDeclaration.data.data.action, investmentDetails.data.data.pfidac, referrals.data.data.show_referrals]
  );

  return (
    <AuthenticatedAppShell numberOfDocuments={numberOfUnreadDocuments} routesToShow={routesToShow}>
      <Outlet />
    </AuthenticatedAppShell>
  );
}
