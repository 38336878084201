import { http } from 'msw';

import { createPostBankDetailsMutationResponse } from '../mocks/createPostBankDetails';

export const postBankDetailsHandler = http.post('*/bank_details', function handler(info) {
  return new Response(JSON.stringify(createPostBankDetailsMutationResponse()), {
    headers: {
      'Content-Type': 'application/json',
    },
  });
});
