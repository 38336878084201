import { z } from 'zod';

import { calculatorHistoryGetResponseSchema } from './calculatorHistoryGetResponseSchema';

/**
 * @description 200 response
 */
export const getCalculatorHistory200Schema = z.lazy(() => calculatorHistoryGetResponseSchema);
/**
 * @description 505 response
 */
export const getCalculatorHistory505Schema = z.any();
/**
 * @description 200 response
 */
export const getCalculatorHistoryQueryResponseSchema = z.lazy(() => calculatorHistoryGetResponseSchema);
