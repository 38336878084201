import { z } from 'zod';

import { changeUnexpectedPaymentOutcomeGetResponseSchema } from './changeUnexpectedPaymentOutcomeGetResponseSchema';

/**
 * @description 200 response
 */
export const getChangeUnexpectedPaymentOutcome200Schema = z.lazy(() => changeUnexpectedPaymentOutcomeGetResponseSchema);
/**
 * @description 505 response
 */
export const getChangeUnexpectedPaymentOutcome505Schema = z.any();
/**
 * @description 200 response
 */
export const getChangeUnexpectedPaymentOutcomeQueryResponseSchema = z.lazy(
  () => changeUnexpectedPaymentOutcomeGetResponseSchema
);
