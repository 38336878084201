import type {
  PostIfaApproval200,
  PostIfaApproval505,
  PostIfaApprovalMutationRequest,
  PostIfaApprovalMutationResponse,
} from '../types/PostIfaApproval';
import { createIfaApprovalPostRequest } from './createIfaApprovalPostRequest';
import { createIfaApprovalPostResponse } from './createIfaApprovalPostResponse';

/**
 * @description 200 response
 */
export function createPostIfaApproval200(): NonNullable<PostIfaApproval200> {
  return createIfaApprovalPostResponse();
}

/**
 * @description 505 response
 */
export function createPostIfaApproval505(): NonNullable<PostIfaApproval505> {
  return undefined;
}

export function createPostIfaApprovalMutationRequest(): NonNullable<PostIfaApprovalMutationRequest> {
  return createIfaApprovalPostRequest();
}

/**
 * @description 200 response
 */
export function createPostIfaApprovalMutationResponse(): NonNullable<PostIfaApprovalMutationResponse> {
  return createIfaApprovalPostResponse();
}
