import {
  Button,
  buttonStyles,
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
  Icon,
  Separator,
} from '@pfida-finance/ui/components';
import { Link } from '@tanstack/react-router';
import { useDipApplicationFormContext } from '../../context/dipApplicationFormContext';

export function Start() {
  const { changeCurrentStep, currentPageNumber } = useDipApplicationFormContext();

  return (
    <Card className="flex flex-col w-full p-8 gap-5">
      <CardHeader className="p-0 space-y-0 gap-2">
        <CardTitle>Decision in principle form</CardTitle>
        <div className="py-4">
          <Separator />
        </div>
      </CardHeader>
      <CardContent className="flex flex-col p-0 gap-6">
        <h4 className="font-semibold text-base">Before you get started...</h4>
        <ul className="flex flex-col gap-6">
          <li className="flex gap-5">
            <div className="flex items-center justify-center h-8 min-w-8 rounded-full bg-accent">
              <Icon name="home" className="text-accent-foreground" />
            </div>
            <span className="font-normal text-sm">
              A Decision in Principle (DiP) gives an indication of how much funding you may be able to receive for your
              property purchase based on the information provided.
            </span>
          </li>
          <li className="flex gap-4">
            <div className="flex items-center justify-center h-8 min-w-8 rounded-full bg-accent">
              <Icon name="clock" className="text-accent-foreground" />
            </div>
            <span className="font-normal text-sm">
              You will need to provide details of the property value, down payment, income, and regular spending in{' '}
              <b>Pound Sterling (£).</b> Completing the form typically takes 15 to 30 minutes.
            </span>
          </li>
          <li className="flex gap-4">
            <div className="flex items-center justify-center h-8 min-w-8 rounded-full bg-accent">
              <Icon name="file-bar-chart-2" className="text-accent-foreground" />
            </div>
            <span className="font-normal text-sm">
              Have your employment documents and bank statements ready before you start. It&apos;s important that you
              answer the questions as accurately as possible, as this will help avoid any disappointment later on if
              your supporting documents don&apos;t match the figures you have given.
            </span>
          </li>
          <li className="flex gap-4">
            <div className="flex items-center justify-center h-8 min-w-8 rounded-full bg-accent">
              <Icon name="search-check" className="text-accent-foreground" />
            </div>
            <span className="font-normal text-sm">
              A DiP is an indication only and is <b>not</b> a guarantee that we will be able to provide funding for your
              property purchase application. It simply helps you understand your potential budget for property searches.
            </span>
          </li>
          <li className="flex gap-4">
            <div className="flex items-center justify-center h-8 min-w-8 rounded-full bg-accent">
              <Icon name="hand-coins" className="text-accent-foreground" />
            </div>
            <span className="font-normal text-sm">
              No fee is charged for the first or second DiP requests, but a £200 fee may apply for subsequent requests.
              Other fees, such as arrangement, survey, and solicitor fees, will apply when you make a full application.
            </span>
          </li>
          <li className="flex gap-4">
            <div className="flex items-center justify-center h-8 min-w-8 rounded-full bg-accent">
              <Icon name="info" className="text-accent-foreground" />
            </div>
            <span className="font-normal text-sm">
              By continuing, you confirm that the information you provide is accurate. If it is found that there is
              previously undisclosed information or there is a discrepancy between the answers provided on your DiP and
              your full application, Pfida may decline your case and you may forfeit part of your application fee.
            </span>
          </li>
          <li className="flex items-center gap-4">
            <div className="flex items-center justify-center h-8 min-w-8 rounded-full bg-accent">
              <Icon name="lock" className="text-accent-foreground" />
            </div>
            <span className="font-normal text-sm">
              Pfida provides a non-advised service. By continuing, you agree to have your data processed in line with
              Pfida’s{' '}
              <a
                className={buttonStyles({ variant: 'link' })}
                target="_blank"
                rel="noreferrer"
                href="https://www.pfida.com/privacy-policy-landing/"
              >
                privacy policy
              </a>
              .
            </span>
          </li>
        </ul>
      </CardContent>
      <Separator className="my-4" />
      <CardFooter className="flex flex-col-reverse p-0 gap-3 md:flex-row md:justify-end">
        <Button variant="outline" className="w-full md:w-auto" size="lg">
          <Link to="/dashboard">Cancel</Link>
        </Button>
        <Button
          className="w-full md:w-auto"
          size="lg"
          onClick={() => changeCurrentStep('timeFrame', currentPageNumber + 1)}
        >
          Get started
        </Button>
      </CardFooter>
    </Card>
  );
}
