import { eventBus } from '@/libs/eventBus';
import { queryClient } from '@/libs/tanstackQuery';
import { getTokens, parseIdToken } from '@repo/single-spa';
import { clientEnv, invariant } from '@repo/utils';
import { z } from 'zod';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { TokenSchema } from '../constants/schema';
import { hasTokenExpired } from '../utils/hasTokenExpired';
import { createSelectors } from './utils';

type AuthenticatedUser = z.infer<typeof TokenSchema>;

export type AuthState = {
  user: AuthenticatedUser | undefined;
  isAuthenticated: () => boolean;
  status: 'idle' | 'success' | 'error';
  signIn: (token: string) => void;
  signOut: () => void;
  hydrate: () => void;
};

function bootstrapApp() {
  const token = getTokens();

  const parsedToken = parseIdToken(token.idToken);

  return { ...token, ...parsedToken };
}

export const authStore = create<AuthState>()(
  devtools((set, get) => ({
    user: undefined,
    status: 'idle',
    signIn: (idToken: string) => {
      const parsedToken = parseIdToken(idToken);

      if (clientEnv.VITE_APP_MODE === 'production' && window && window.analytics) {
        window.analytics.identify({ userId: parsedToken['cognito:username'] });
      }

      set({ user: parsedToken });
    },
    signOut: () => {
      eventBus.emit('mife:logout', {
        success: true,
      });
      set({ user: undefined });
      queryClient.clear();
    },
    isAuthenticated: () => {
      const exp = get().user?.exp;

      if (!exp) {
        return false;
      }

      const expiresInMs = exp * 1000;

      return !hasTokenExpired(expiresInMs);
    },
    hydrate: () => {
      try {
        const user = bootstrapApp();
        set({ status: 'success' });
        set({ user });
      } catch (e) {
        set({ status: 'error' });
        get().signOut();
      }
    },
  }))
);

export const useAuth = createSelectors(authStore);
export const signOut = () => authStore.getState().signOut();
export const signIn = (token: string) => authStore.getState().signIn(token);
export const hydrateAuth = () => authStore.getState().hydrate();
