import { Button, Card, Icon } from '@pfida-finance/ui/components';
import { Link } from '@tanstack/react-router';

export function End() {
  return (
    <Card className="flex flex-col items-center w-full max-w-[568px] p-8 gap-5">
      <div className="flex items-center justify-center h-16 min-w-16 rounded-full bg-green-50">
        <Icon name="check" className="text-success h-10 w-10" />
      </div>
      <div className="flex flex-col items-center gap-2">
        <p className="text-2xl font-semibold leading-8">Thank you!</p>
        <p className="text-sm text-muted-foreground text-center">
          Thank you for submitting your Decision in Principle (DiP) application form.
          <br />
          <br />
          Our admin team will shortly commence processing your request and will be in touch if we need anything else. We
          usually aim to provide an outcome within two weeks.
        </p>
      </div>
      <Button variant="outline" className="w-full md:w-auto" size="lg">
        <Link to="/dashboard">Back to dashboard</Link>
      </Button>
    </Card>
  );
}
