import { z } from 'zod';

import { costCalculatorGetResponseSchema } from './costCalculatorGetResponseSchema';

/**
 * @description 200 response
 */
export const getCostCalculator200Schema = z.lazy(() => costCalculatorGetResponseSchema);
/**
 * @description 505 response
 */
export const getCostCalculator505Schema = z.any();
/**
 * @description 200 response
 */
export const getCostCalculatorQueryResponseSchema = z.lazy(() => costCalculatorGetResponseSchema);
