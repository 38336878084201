import { http } from 'msw';

import { createPostResendConfirmationCodeMutationResponse } from '../mocks/createPostResendConfirmationCode';

export const postResendConfirmationCodeHandler = http.post('*/resend_confirmation_code', function handler(info) {
  return new Response(JSON.stringify(createPostResendConfirmationCodeMutationResponse()), {
    headers: {
      'Content-Type': 'application/json',
    },
  });
});
