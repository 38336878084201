import { faker } from '@faker-js/faker';

import type {
  GetChangeInvestorCreditSplit200,
  GetChangeInvestorCreditSplit505,
  GetChangeInvestorCreditSplitQueryParams,
  GetChangeInvestorCreditSplitQueryResponse,
} from '../types/GetChangeInvestorCreditSplit';
import { createChangeInvestorCreditSplitGetResponse } from './createChangeInvestorCreditSplitGetResponse';

export function createGetChangeInvestorCreditSplitQueryParams(): NonNullable<GetChangeInvestorCreditSplitQueryParams> {
  return { company: faker.string.alpha() };
}

/**
 * @description 200 response
 */
export function createGetChangeInvestorCreditSplit200(): NonNullable<GetChangeInvestorCreditSplit200> {
  return createChangeInvestorCreditSplitGetResponse();
}

/**
 * @description 505 response
 */
export function createGetChangeInvestorCreditSplit505(): NonNullable<GetChangeInvestorCreditSplit505> {
  return undefined;
}

/**
 * @description 200 response
 */
export function createGetChangeInvestorCreditSplitQueryResponse(): NonNullable<GetChangeInvestorCreditSplitQueryResponse> {
  return createChangeInvestorCreditSplitGetResponse();
}
