import type {
  PostForgotPassword200,
  PostForgotPassword505,
  PostForgotPasswordMutationRequest,
  PostForgotPasswordMutationResponse,
} from '../types/PostForgotPassword';
import { createForgotPasswordPostRequest } from './createForgotPasswordPostRequest';
import { createForgotPasswordPostResponse } from './createForgotPasswordPostResponse';

/**
 * @description 200 response
 */
export function createPostForgotPassword200(): NonNullable<PostForgotPassword200> {
  return createForgotPasswordPostResponse();
}

/**
 * @description 505 response
 */
export function createPostForgotPassword505(): NonNullable<PostForgotPassword505> {
  return undefined;
}

export function createPostForgotPasswordMutationRequest(): NonNullable<PostForgotPasswordMutationRequest> {
  return createForgotPasswordPostRequest();
}

/**
 * @description 200 response
 */
export function createPostForgotPasswordMutationResponse(): NonNullable<PostForgotPasswordMutationResponse> {
  return createForgotPasswordPostResponse();
}
