import { faker } from '@faker-js/faker';

import type { UserDetailsGetResponse } from '../types/UserDetailsGetResponse';

export function createUserDetailsGetResponse(
  data: NonNullable<Partial<UserDetailsGetResponse>> = {}
): NonNullable<UserDetailsGetResponse> {
  return {
    ...{
      data: {
        address: faker.string.alpha(),
        birthdate: faker.string.alpha(),
        city: faker.string.alpha(),
        email: faker.string.alpha(),
        full_sign_up: faker.datatype.boolean(),
        name: faker.string.alpha(),
        phone_number: faker.string.alpha(),
        postcode: faker.string.alpha(),
        username: faker.string.alpha(),
      },
      response: {
        date: faker.string.alpha(),
        messages: faker.helpers.arrayElements([faker.string.alpha()]) as any,
        status: faker.number.int(),
        type: faker.string.alpha(),
      },
    },
    ...data,
  };
}
