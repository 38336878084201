import type {
  PostCostCalculator200,
  PostCostCalculator505,
  PostCostCalculatorMutationRequest,
  PostCostCalculatorMutationResponse,
} from '../types/PostCostCalculator';
import { createCostCalculatorPostRequest } from './createCostCalculatorPostRequest';
import { createCostCalculatorPostResponse } from './createCostCalculatorPostResponse';

/**
 * @description 200 response
 */
export function createPostCostCalculator200(): NonNullable<PostCostCalculator200> {
  return createCostCalculatorPostResponse();
}

/**
 * @description 505 response
 */
export function createPostCostCalculator505(): NonNullable<PostCostCalculator505> {
  return undefined;
}

export function createPostCostCalculatorMutationRequest(): NonNullable<PostCostCalculatorMutationRequest> {
  return createCostCalculatorPostRequest();
}

/**
 * @description 200 response
 */
export function createPostCostCalculatorMutationResponse(): NonNullable<PostCostCalculatorMutationResponse> {
  return createCostCalculatorPostResponse();
}
