import { http } from 'msw';

import { createPostChangeExpectedPaymentSettingMutationResponse } from '../mocks/createPostChangeExpectedPaymentSetting';

export const postChangeExpectedPaymentSettingHandler = http.post(
  '*/change_expected_payment_setting',
  function handler(info) {
    return new Response(JSON.stringify(createPostChangeExpectedPaymentSettingMutationResponse()), {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
);
