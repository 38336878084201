import type {
  QueryKey,
  QueryObserverOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query';
import { queryOptions, useQuery, useSuspenseQuery } from '@tanstack/react-query';

import type { GetInvestorDeclaration505, GetInvestorDeclarationQueryResponse } from '../types/GetInvestorDeclaration';
import client from '../../client';
import { getInvestorDeclarationQueryResponseSchema } from '../schemas/getInvestorDeclarationSchema';

type GetInvestorDeclarationClient = typeof client<
  GetInvestorDeclarationQueryResponse,
  GetInvestorDeclaration505,
  never
>;
type GetInvestorDeclaration = {
  data: GetInvestorDeclarationQueryResponse;
  error: GetInvestorDeclaration505;
  request: never;
  pathParams: never;
  queryParams: never;
  headerParams: never;
  response: GetInvestorDeclarationQueryResponse;
  client: {
    parameters: Partial<Parameters<GetInvestorDeclarationClient>[0]>;
    return: Awaited<ReturnType<GetInvestorDeclarationClient>>;
  };
};
export const getInvestorDeclarationQueryKey = () => ['USER_BACKEND', { url: '/investor_declaration' }] as const;
export type GetInvestorDeclarationQueryKey = ReturnType<typeof getInvestorDeclarationQueryKey>;
export function getInvestorDeclarationQueryOptions(options: GetInvestorDeclaration['client']['parameters'] = {}) {
  const queryKey = getInvestorDeclarationQueryKey();
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<GetInvestorDeclaration['data'], GetInvestorDeclaration['error']>({
        method: 'get',
        url: `/investor_declaration`,
        ...options,
      });
      return getInvestorDeclarationQueryResponseSchema.parse(res.data);
    },
  });
}
/**
 * @link /investor_declaration
 */
export function useGetInvestorDeclaration<
  TData = GetInvestorDeclaration['response'],
  TQueryData = GetInvestorDeclaration['response'],
  TQueryKey extends QueryKey = GetInvestorDeclarationQueryKey,
>(
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetInvestorDeclaration['response'],
        GetInvestorDeclaration['error'],
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: GetInvestorDeclaration['client']['parameters'];
  } = {}
): UseQueryResult<TData, GetInvestorDeclaration['error']> & {
  queryKey: TQueryKey;
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getInvestorDeclarationQueryKey();
  const query = useQuery({
    ...(getInvestorDeclarationQueryOptions(clientOptions) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetInvestorDeclaration['error']> & {
    queryKey: TQueryKey;
  };
  query.queryKey = queryKey as TQueryKey;
  return query;
}
export const getInvestorDeclarationSuspenseQueryKey = () => ['USER_BACKEND', { url: '/investor_declaration' }] as const;
export type GetInvestorDeclarationSuspenseQueryKey = ReturnType<typeof getInvestorDeclarationSuspenseQueryKey>;
export function getInvestorDeclarationSuspenseQueryOptions(
  options: GetInvestorDeclaration['client']['parameters'] = {}
) {
  const queryKey = getInvestorDeclarationSuspenseQueryKey();
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<GetInvestorDeclaration['data'], GetInvestorDeclaration['error']>({
        method: 'get',
        url: `/investor_declaration`,
        ...options,
      });
      return getInvestorDeclarationQueryResponseSchema.parse(res.data);
    },
  });
}
/**
 * @link /investor_declaration
 */
export function useGetInvestorDeclarationSuspense<
  TData = GetInvestorDeclaration['response'],
  TQueryKey extends QueryKey = GetInvestorDeclarationSuspenseQueryKey,
>(
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<GetInvestorDeclaration['response'], GetInvestorDeclaration['error'], TData, TQueryKey>
    >;
    client?: GetInvestorDeclaration['client']['parameters'];
  } = {}
): UseSuspenseQueryResult<TData, GetInvestorDeclaration['error']> & {
  queryKey: TQueryKey;
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getInvestorDeclarationSuspenseQueryKey();
  const query = useSuspenseQuery({
    ...(getInvestorDeclarationSuspenseQueryOptions(clientOptions) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<TData, GetInvestorDeclaration['error']> & {
    queryKey: TQueryKey;
  };
  query.queryKey = queryKey as TQueryKey;
  return query;
}
