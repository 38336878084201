import { http } from 'msw';

import { createPostEquityInvestmentPageVisitedMutationResponse } from '../mocks/createPostEquityInvestmentPageVisited';

export const postEquityInvestmentPageVisitedHandler = http.post(
  '*/equity_investment_page_visited',
  function handler(info) {
    return new Response(JSON.stringify(createPostEquityInvestmentPageVisitedMutationResponse()), {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
);
