import { z } from 'zod';

export const loginAuthChallengePostResponseSchema = z.object({
  data: z.object({
    AuthenticationResult: z
      .object({ AccessToken: z.string(), IdToken: z.string(), RefreshToken: z.string() })
      .optional(),
    ChallengeName: z.string().optional(),
    ChallengeParameters: z.object({}).optional(),
    SecretCode: z.string().optional(),
    Session: z.string().optional(),
  }),
  response: z.object({ date: z.string(), messages: z.array(z.string()), status: z.number(), type: z.string() }),
});
