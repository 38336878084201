import { faker } from '@faker-js/faker';

import type { SubscriptionFormGetResponse } from '../types/SubscriptionFormGetResponse';

export function createSubscriptionFormGetResponse(
  data: NonNullable<Partial<SubscriptionFormGetResponse>> = {}
): NonNullable<SubscriptionFormGetResponse> {
  return {
    ...{
      data: {
        next_step: faker.string.alpha(),
        subscription_forms: faker.helpers.arrayElements([
          {
            investment_amount: faker.number.float(),
            investment_product: faker.string.alpha(),
            source_of_investments: faker.helpers.arrayElements([
              {
                amount: faker.number.float(),
                bank_info: faker.string.alpha(),
                info: faker.string.alpha(),
                source: faker.string.alpha(),
              },
            ]) as any,
          },
        ]) as any,
      },
      response: {
        date: faker.string.alpha(),
        messages: faker.helpers.arrayElements([faker.string.alpha()]) as any,
        status: faker.number.int(),
        type: faker.string.alpha(),
      },
    },
    ...data,
  };
}
