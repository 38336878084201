import { z } from 'zod';

import { changeUnexpectedPaymentOutcomePostRequestSchema } from './changeUnexpectedPaymentOutcomePostRequestSchema';
import { changeUnexpectedPaymentOutcomePostResponseSchema } from './changeUnexpectedPaymentOutcomePostResponseSchema';

/**
 * @description 200 response
 */
export const postChangeUnexpectedPaymentOutcome200Schema = z.lazy(
  () => changeUnexpectedPaymentOutcomePostResponseSchema
);
/**
 * @description 505 response
 */
export const postChangeUnexpectedPaymentOutcome505Schema = z.any();

export const postChangeUnexpectedPaymentOutcomeMutationRequestSchema = z.lazy(
  () => changeUnexpectedPaymentOutcomePostRequestSchema
);
/**
 * @description 200 response
 */
export const postChangeUnexpectedPaymentOutcomeMutationResponseSchema = z.lazy(
  () => changeUnexpectedPaymentOutcomePostResponseSchema
);
