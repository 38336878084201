import { faker } from '@faker-js/faker';

import type { FinanceRequirementsPostResponse } from '../types/FinanceRequirementsPostResponse';

export function createFinanceRequirementsPostResponse(
  data: NonNullable<Partial<FinanceRequirementsPostResponse>> = {}
): NonNullable<FinanceRequirementsPostResponse> {
  return {
    ...{
      data: {},
      response: {
        date: faker.string.alpha(),
        messages: faker.helpers.arrayElements([faker.string.alpha()]) as any,
        status: faker.number.int(),
        type: faker.string.alpha(),
      },
    },
    ...data,
  };
}
