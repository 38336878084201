import React, { PropsWithChildren } from 'react';
import { clientEnv } from '@repo/utils';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const ReactQueryDevtools =
  clientEnv.VITE_APP_MODE === 'production'
    ? () => null // Render nothing in production
    : React.lazy(() =>
        // Lazy load in development
        import('@tanstack/react-query-devtools').then((res) => ({
          default: res.ReactQueryDevtools,
        }))
      );

export const queryClient = new QueryClient();

export function TanstackQueryProvider({ children }: PropsWithChildren) {
  return (
    <QueryClientProvider client={queryClient}>
      {children}
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
}

export * from '@tanstack/react-query';
