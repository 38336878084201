import { faker } from '@faker-js/faker';

import type { ListCountriesGetResponse } from '../types/ListCountriesGetResponse';

export function createListCountriesGetResponse(
  data: NonNullable<Partial<ListCountriesGetResponse>> = {}
): NonNullable<ListCountriesGetResponse> {
  return {
    ...{
      data: {
        countries: faker.helpers.arrayElements([{ label: faker.string.alpha(), value: faker.string.alpha() }]) as any,
      },
      response: {
        date: faker.string.alpha(),
        messages: faker.helpers.arrayElements([faker.string.alpha()]) as any,
        status: faker.number.int(),
        type: faker.string.alpha(),
      },
    },
    ...data,
  };
}
