import { http } from 'msw';

import { createPostFinanceRequirementsMutationResponse } from '../mocks/createPostFinanceRequirements';

export const postFinanceRequirementsHandler = http.post('*/finance_requirements', function handler(info) {
  return new Response(JSON.stringify(createPostFinanceRequirementsMutationResponse()), {
    headers: {
      'Content-Type': 'application/json',
    },
  });
});
