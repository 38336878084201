import { z } from 'zod';

import { signUpPostRequestSchema } from './signUpPostRequestSchema';
import { signUpPostResponseSchema } from './signUpPostResponseSchema';

/**
 * @description 200 response
 */
export const postSignUp200Schema = z.lazy(() => signUpPostResponseSchema);
/**
 * @description 505 response
 */
export const postSignUp505Schema = z.any();

export const postSignUpMutationRequestSchema = z.lazy(() => signUpPostRequestSchema);
/**
 * @description 200 response
 */
export const postSignUpMutationResponseSchema = z.lazy(() => signUpPostResponseSchema);
