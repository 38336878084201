import { z } from 'zod';

export const financeDetailsGetResponseSchema = z.object({
  data: z.object({
    acquisition_cost: z.string().optional(),
    deposit: z.number().optional(),
    equity_released: z.number().optional(),
    expected_payment_setting: z.number().optional(),
    latest_projected_payments: z.object({}).optional(),
    lease_term_decimal: z.number().optional(),
    lease_term_elapsed_decimal: z.number().optional(),
    lease_term_elapsed_months: z.number().optional(),
    lease_term_elapsed_string: z.string().optional(),
    lease_term_elapsed_years: z.number().optional(),
    lease_term_months: z.number().optional(),
    lease_term_string: z.number().optional(),
    lease_term_years: z.number().optional(),
    next_equity_payment: z.number().optional(),
    next_payment_date: z.string().optional(),
    next_payment_remaining: z.string().optional(),
    next_payment_remaining_unformatted: z.number().optional(),
    next_rent_payment: z.number().optional(),
    next_total_monthly_payment: z.string().optional(),
    next_total_monthly_payment_ex_rent_arrears: z.number().optional(),
    next_total_monthly_payment_ex_rent_arrears_formatted: z.string().optional(),
    next_total_monthly_payment_ex_rent_arrears_paid: z.string().optional(),
    next_total_monthly_payment_progress_percentage: z.number().optional(),
    next_total_monthly_payment_unformatted: z.number().optional(),
    next_total_monthly_recommended_payment: z.string().optional(),
    original_projected_payments: z.object({}).optional(),
    overpaid: z.number().optional(),
    product_name: z.string().optional(),
    reference: z.string().optional(),
    remaining_finance: z.string().optional(),
    remaining_finance_rounded: z.number().optional(),
    remaining_term_decimal: z.number().optional(),
    remaining_term_months: z.number().optional(),
    remaining_term_string: z.string().optional(),
    remaining_term_years: z.number().optional(),
    rent_arrears: z.number().optional(),
    rent_arrears_remaining: z.number().optional(),
    rent_arrears_remaining_rounded: z.number().optional(),
    rent_arrears_rounded: z.number().optional(),
    saving: z.number().optional(),
    term_elapsed_decimal: z.number().optional(),
    term_elapsed_months: z.number().optional(),
    term_elapsed_string: z.string().optional(),
    term_elapsed_years: z.number().optional(),
    total_actually_paid: z.string().optional(),
    total_equity: z.string().optional(),
    total_equity_without_deposit: z.string().optional(),
    total_equity_without_deposit_rounded: z.number().optional(),
    total_expected_paid: z.string().optional(),
    total_payments_made: z.string().optional(),
    total_payments_made_ex_rent_arrears: z.string().optional(),
    total_payments_made_ex_rent_arrears_unformatted: z.number().optional(),
    total_payments_made_unformatted: z.number().optional(),
    total_rent: z.string().optional(),
    total_term_decimal: z.number().optional(),
    total_term_months: z.number().optional(),
    total_term_string: z.string().optional(),
    total_term_years: z.number().optional(),
    transaction_history: z
      .array(
        z.object({
          actual_payment: z.string(),
          date: z.string(),
          equity: z.string(),
          expected_payment: z.string(),
          late: z.boolean(),
          outstanding_equity_amount: z.string(),
          rent: z.string(),
          type: z.string(),
        })
      )
      .optional(),
    unexpected_payment_outcome: z.number().optional(),
  }),
  response: z.object({ date: z.string(), messages: z.array(z.string()), status: z.number(), type: z.string() }),
});
