import { faker } from '@faker-js/faker';

import type { InvestorDeclarationPostResponse } from '../types/InvestorDeclarationPostResponse';

export function createInvestorDeclarationPostResponse(
  data: NonNullable<Partial<InvestorDeclarationPostResponse>> = {}
): NonNullable<InvestorDeclarationPostResponse> {
  return {
    ...{
      data: {},
      response: {
        date: faker.string.alpha(),
        messages: faker.helpers.arrayElements([faker.string.alpha()]) as any,
        status: faker.number.int(),
        type: faker.string.alpha(),
      },
    },
    ...data,
  };
}
