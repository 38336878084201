import { faker } from '@faker-js/faker';

import type { ChangeInvestorCreditSplitGetResponse } from '../types/ChangeInvestorCreditSplitGetResponse';

export function createChangeInvestorCreditSplitGetResponse(
  data: NonNullable<Partial<ChangeInvestorCreditSplitGetResponse>> = {}
): NonNullable<ChangeInvestorCreditSplitGetResponse> {
  return {
    ...{
      data: { options: faker.helpers.arrayElements([faker.string.alpha()]) as any, value: faker.string.alpha() },
      response: {
        date: faker.string.alpha(),
        messages: faker.helpers.arrayElements([faker.string.alpha()]) as any,
        status: faker.number.int(),
        type: faker.string.alpha(),
      },
    },
    ...data,
  };
}
