import { http } from 'msw';

import { createGetReferralsQueryResponse } from '../mocks/createGetReferrals';

export const getReferralsHandler = http.get('*/referrals', function handler(info) {
  return new Response(JSON.stringify(createGetReferralsQueryResponse()), {
    headers: {
      'Content-Type': 'application/json',
    },
  });
});
