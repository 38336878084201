import type { GetBankDetails200, GetBankDetails505, GetBankDetailsQueryResponse } from '../types/GetBankDetails';
import { createBankDetailsGetResponse } from './createBankDetailsGetResponse';

/**
 * @description 200 response
 */
export function createGetBankDetails200(): NonNullable<GetBankDetails200> {
  return createBankDetailsGetResponse();
}

/**
 * @description 505 response
 */
export function createGetBankDetails505(): NonNullable<GetBankDetails505> {
  return undefined;
}

/**
 * @description 200 response
 */
export function createGetBankDetailsQueryResponse(): NonNullable<GetBankDetailsQueryResponse> {
  return createBankDetailsGetResponse();
}
