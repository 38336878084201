import { http } from 'msw';

import { createPostSubscriptionFormMutationResponse } from '../mocks/createPostSubscriptionForm';

export const postSubscriptionFormHandler = http.post('*/subscription_form', function handler(info) {
  return new Response(JSON.stringify(createPostSubscriptionFormMutationResponse()), {
    headers: {
      'Content-Type': 'application/json',
    },
  });
});
