import type {
  PostCostCalculatorAuthenticated200,
  PostCostCalculatorAuthenticated505,
  PostCostCalculatorAuthenticatedMutationRequest,
  PostCostCalculatorAuthenticatedMutationResponse,
} from '../types/PostCostCalculatorAuthenticated';
import { createCostCalculatorAuthenticatedPostRequest } from './createCostCalculatorAuthenticatedPostRequest';
import { createCostCalculatorAuthenticatedPostResponse } from './createCostCalculatorAuthenticatedPostResponse';

/**
 * @description 200 response
 */
export function createPostCostCalculatorAuthenticated200(): NonNullable<PostCostCalculatorAuthenticated200> {
  return createCostCalculatorAuthenticatedPostResponse();
}

/**
 * @description 505 response
 */
export function createPostCostCalculatorAuthenticated505(): NonNullable<PostCostCalculatorAuthenticated505> {
  return undefined;
}

export function createPostCostCalculatorAuthenticatedMutationRequest(): NonNullable<PostCostCalculatorAuthenticatedMutationRequest> {
  return createCostCalculatorAuthenticatedPostRequest();
}

/**
 * @description 200 response
 */
export function createPostCostCalculatorAuthenticatedMutationResponse(): NonNullable<PostCostCalculatorAuthenticatedMutationResponse> {
  return createCostCalculatorAuthenticatedPostResponse();
}
