import type {
  PostSignUp200,
  PostSignUp505,
  PostSignUpMutationRequest,
  PostSignUpMutationResponse,
} from '../types/PostSignUp';
import { createSignUpPostRequest } from './createSignUpPostRequest';
import { createSignUpPostResponse } from './createSignUpPostResponse';

/**
 * @description 200 response
 */
export function createPostSignUp200(): NonNullable<PostSignUp200> {
  return createSignUpPostResponse();
}

/**
 * @description 505 response
 */
export function createPostSignUp505(): NonNullable<PostSignUp505> {
  return undefined;
}

export function createPostSignUpMutationRequest(): NonNullable<PostSignUpMutationRequest> {
  return createSignUpPostRequest();
}

/**
 * @description 200 response
 */
export function createPostSignUpMutationResponse(): NonNullable<PostSignUpMutationResponse> {
  return createSignUpPostResponse();
}
