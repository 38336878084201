import type {
  GetListUserObjects200,
  GetListUserObjects505,
  GetListUserObjectsQueryResponse,
} from '../types/GetListUserObjects';
import { createListUserObjectsGetResponse } from './createListUserObjectsGetResponse';

/**
 * @description 200 response
 */
export function createGetListUserObjects200(): NonNullable<GetListUserObjects200> {
  return createListUserObjectsGetResponse();
}

/**
 * @description 505 response
 */
export function createGetListUserObjects505(): NonNullable<GetListUserObjects505> {
  return undefined;
}

/**
 * @description 200 response
 */
export function createGetListUserObjectsQueryResponse(): NonNullable<GetListUserObjectsQueryResponse> {
  return createListUserObjectsGetResponse();
}
