import { z } from 'zod';

import { listCountriesGetResponseSchema } from './listCountriesGetResponseSchema';

/**
 * @description 200 response
 */
export const getListCountries200Schema = z.lazy(() => listCountriesGetResponseSchema);
/**
 * @description 505 response
 */
export const getListCountries505Schema = z.any();
/**
 * @description 200 response
 */
export const getListCountriesQueryResponseSchema = z.lazy(() => listCountriesGetResponseSchema);
