import { Alert, AlertDescription, AlertTitle } from '@pfida-finance/ui/components';

export function Error() {
  return (
    <Alert className="md:col-span-2" variant="destructive">
      <AlertTitle>Errors found</AlertTitle>
      <AlertDescription>Please correct the highlighted fields before proceeding.</AlertDescription>
    </Alert>
  );
}
