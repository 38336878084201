import { z } from 'zod';

import { deleteAccountPostRequestSchema } from './deleteAccountPostRequestSchema';
import { deleteAccountPostResponseSchema } from './deleteAccountPostResponseSchema';

/**
 * @description 200 response
 */
export const postDeleteAccount200Schema = z.lazy(() => deleteAccountPostResponseSchema);
/**
 * @description 505 response
 */
export const postDeleteAccount505Schema = z.any();

export const postDeleteAccountMutationRequestSchema = z.lazy(() => deleteAccountPostRequestSchema);
/**
 * @description 200 response
 */
export const postDeleteAccountMutationResponseSchema = z.lazy(() => deleteAccountPostResponseSchema);
