import type {
  PostChangeInvestorCreditSplit200,
  PostChangeInvestorCreditSplit505,
  PostChangeInvestorCreditSplitMutationRequest,
  PostChangeInvestorCreditSplitMutationResponse,
} from '../types/PostChangeInvestorCreditSplit';
import { createChangeInvestorCreditSplitPostRequest } from './createChangeInvestorCreditSplitPostRequest';
import { createChangeInvestorCreditSplitPostResponse } from './createChangeInvestorCreditSplitPostResponse';

/**
 * @description 200 response
 */
export function createPostChangeInvestorCreditSplit200(): NonNullable<PostChangeInvestorCreditSplit200> {
  return createChangeInvestorCreditSplitPostResponse();
}

/**
 * @description 505 response
 */
export function createPostChangeInvestorCreditSplit505(): NonNullable<PostChangeInvestorCreditSplit505> {
  return undefined;
}

export function createPostChangeInvestorCreditSplitMutationRequest(): NonNullable<PostChangeInvestorCreditSplitMutationRequest> {
  return createChangeInvestorCreditSplitPostRequest();
}

/**
 * @description 200 response
 */
export function createPostChangeInvestorCreditSplitMutationResponse(): NonNullable<PostChangeInvestorCreditSplitMutationResponse> {
  return createChangeInvestorCreditSplitPostResponse();
}
