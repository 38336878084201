import { faker } from '@faker-js/faker';

import type { EligibilityPostResponse } from '../types/EligibilityPostResponse';

export function createEligibilityPostResponse(
  data: NonNullable<Partial<EligibilityPostResponse>> = {}
): NonNullable<EligibilityPostResponse> {
  return {
    ...{
      data: { answer: faker.string.alpha(), problem_params: faker.string.alpha() },
      response: {
        date: faker.string.alpha(),
        messages: faker.helpers.arrayElements([faker.string.alpha()]) as any,
        status: faker.number.int(),
        type: faker.string.alpha(),
      },
    },
    ...data,
  };
}
