import { faker } from '@faker-js/faker';

import type {
  GetGetUserObject200,
  GetGetUserObject505,
  GetGetUserObjectQueryParams,
  GetGetUserObjectQueryResponse,
} from '../types/GetGetUserObject';
import { createGetUserObjectGetResponse } from './createGetUserObjectGetResponse';

export function createGetGetUserObjectQueryParams(): NonNullable<GetGetUserObjectQueryParams> {
  return { relative_key: faker.string.alpha() };
}

/**
 * @description 200 response
 */
export function createGetGetUserObject200(): NonNullable<GetGetUserObject200> {
  return createGetUserObjectGetResponse();
}

/**
 * @description 505 response
 */
export function createGetGetUserObject505(): NonNullable<GetGetUserObject505> {
  return undefined;
}

/**
 * @description 200 response
 */
export function createGetGetUserObjectQueryResponse(): NonNullable<GetGetUserObjectQueryResponse> {
  return createGetUserObjectGetResponse();
}
