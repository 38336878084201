import type {
  PostChangePassword200,
  PostChangePassword505,
  PostChangePasswordMutationRequest,
  PostChangePasswordMutationResponse,
} from '../types/PostChangePassword';
import { createChangePasswordPostRequest } from './createChangePasswordPostRequest';
import { createChangePasswordPostResponse } from './createChangePasswordPostResponse';

/**
 * @description 200 response
 */
export function createPostChangePassword200(): NonNullable<PostChangePassword200> {
  return createChangePasswordPostResponse();
}

/**
 * @description 505 response
 */
export function createPostChangePassword505(): NonNullable<PostChangePassword505> {
  return undefined;
}

export function createPostChangePasswordMutationRequest(): NonNullable<PostChangePasswordMutationRequest> {
  return createChangePasswordPostRequest();
}

/**
 * @description 200 response
 */
export function createPostChangePasswordMutationResponse(): NonNullable<PostChangePasswordMutationResponse> {
  return createChangePasswordPostResponse();
}
