import { z } from 'zod';

import { ifaApprovalGetResponseSchema } from './ifaApprovalGetResponseSchema';

export const getIfaApprovalQueryParamsSchema = z.object({ company: z.string() });
/**
 * @description 200 response
 */
export const getIfaApproval200Schema = z.lazy(() => ifaApprovalGetResponseSchema);
/**
 * @description 505 response
 */
export const getIfaApproval505Schema = z.any();
/**
 * @description 200 response
 */
export const getIfaApprovalQueryResponseSchema = z.lazy(() => ifaApprovalGetResponseSchema);
