import { faker } from '@faker-js/faker';

import type { DownloadTransactionsStatementPostResponse } from '../types/DownloadTransactionsStatementPostResponse';

export function createDownloadTransactionsStatementPostResponse(
  data: NonNullable<Partial<DownloadTransactionsStatementPostResponse>> = {}
): NonNullable<DownloadTransactionsStatementPostResponse> {
  return {
    ...{
      data: {},
      response: {
        date: faker.string.alpha(),
        messages: faker.helpers.arrayElements([faker.string.alpha()]) as any,
        status: faker.number.int(),
        type: faker.string.alpha(),
      },
    },
    ...data,
  };
}
