import { z } from 'zod';

import { listUserObjectsGetResponseSchema } from './listUserObjectsGetResponseSchema';

/**
 * @description 200 response
 */
export const getListUserObjects200Schema = z.lazy(() => listUserObjectsGetResponseSchema);
/**
 * @description 505 response
 */
export const getListUserObjects505Schema = z.any();
/**
 * @description 200 response
 */
export const getListUserObjectsQueryResponseSchema = z.lazy(() => listUserObjectsGetResponseSchema);
