import { faker } from '@faker-js/faker';

import type { ForgotPasswordConfirmPostResponse } from '../types/ForgotPasswordConfirmPostResponse';

export function createForgotPasswordConfirmPostResponse(
  data: NonNullable<Partial<ForgotPasswordConfirmPostResponse>> = {}
): NonNullable<ForgotPasswordConfirmPostResponse> {
  return {
    ...{
      data: {},
      response: {
        date: faker.string.alpha(),
        messages: faker.helpers.arrayElements([faker.string.alpha()]) as any,
        status: faker.number.int(),
        type: faker.string.alpha(),
      },
    },
    ...data,
  };
}
