import { z } from 'zod';

import { affordabilityCalculatorAuthenticatedPostRequestSchema } from './affordabilityCalculatorAuthenticatedPostRequestSchema';
import { affordabilityCalculatorAuthenticatedPostResponseSchema } from './affordabilityCalculatorAuthenticatedPostResponseSchema';

/**
 * @description 200 response
 */
export const postAffordabilityCalculatorAuthenticated200Schema = z.lazy(
  () => affordabilityCalculatorAuthenticatedPostResponseSchema
);
/**
 * @description 505 response
 */
export const postAffordabilityCalculatorAuthenticated505Schema = z.any();

export const postAffordabilityCalculatorAuthenticatedMutationRequestSchema = z.lazy(
  () => affordabilityCalculatorAuthenticatedPostRequestSchema
);
/**
 * @description 200 response
 */
export const postAffordabilityCalculatorAuthenticatedMutationResponseSchema = z.lazy(
  () => affordabilityCalculatorAuthenticatedPostResponseSchema
);
