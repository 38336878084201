import { http } from 'msw';

import { createPostOneOffPaymentNoticeMutationResponse } from '../mocks/createPostOneOffPaymentNotice';

export const postOneOffPaymentNoticeHandler = http.post('*/one_off_payment_notice', function handler(info) {
  return new Response(JSON.stringify(createPostOneOffPaymentNoticeMutationResponse()), {
    headers: {
      'Content-Type': 'application/json',
    },
  });
});
