import { DipApplicationForm } from '@/features/dip/components/dipApplicationForm';
import { SubmittedError } from '@/features/dip/components/submittedError';
import { DipApplicationFormProvider } from '@/features/dip/context/dipApplicationFormContext';
import { listDipApplicationsSuspenseQueryOptions } from '@repo/api/services/dipComponent/gen';
import { invariant } from '@repo/utils';
import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

const dipQueryParameters = z.object({
  applicationId: z.string(),
  agreementReference: z.number(),
});

export const Route = createFileRoute('/_authenticated/_pfida/dip')({
  loaderDeps: (deps) => ({
    search: deps.search,
  }),
  validateSearch: dipQueryParameters,
  loader: async ({ context, deps }) => {
    const data = await context.queryClient.ensureQueryData(listDipApplicationsSuspenseQueryOptions());

    const foundAgreement = data.data.find(
      (datum) => datum.agreementReference === String(deps.search.agreementReference)
    );

    invariant(foundAgreement, `Agereement with reference ${deps.search.agreementReference} doesn't exist`);

    return { submitted: foundAgreement.status === 'SUBMITTED' };
  },
  component: RouteComponent,
});

function RouteComponent() {
  const submitted = Route.useLoaderData();

  if (submitted) {
    return <SubmittedError />;
  }
  return (
    <DipApplicationFormProvider>
      <DipApplicationForm />
    </DipApplicationFormProvider>
  );
}
