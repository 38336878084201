import type {
  PostChangeUnexpectedPaymentOutcome200,
  PostChangeUnexpectedPaymentOutcome505,
  PostChangeUnexpectedPaymentOutcomeMutationRequest,
  PostChangeUnexpectedPaymentOutcomeMutationResponse,
} from '../types/PostChangeUnexpectedPaymentOutcome';
import { createChangeUnexpectedPaymentOutcomePostRequest } from './createChangeUnexpectedPaymentOutcomePostRequest';
import { createChangeUnexpectedPaymentOutcomePostResponse } from './createChangeUnexpectedPaymentOutcomePostResponse';

/**
 * @description 200 response
 */
export function createPostChangeUnexpectedPaymentOutcome200(): NonNullable<PostChangeUnexpectedPaymentOutcome200> {
  return createChangeUnexpectedPaymentOutcomePostResponse();
}

/**
 * @description 505 response
 */
export function createPostChangeUnexpectedPaymentOutcome505(): NonNullable<PostChangeUnexpectedPaymentOutcome505> {
  return undefined;
}

export function createPostChangeUnexpectedPaymentOutcomeMutationRequest(): NonNullable<PostChangeUnexpectedPaymentOutcomeMutationRequest> {
  return createChangeUnexpectedPaymentOutcomePostRequest();
}

/**
 * @description 200 response
 */
export function createPostChangeUnexpectedPaymentOutcomeMutationResponse(): NonNullable<PostChangeUnexpectedPaymentOutcomeMutationResponse> {
  return createChangeUnexpectedPaymentOutcomePostResponse();
}
