import { z } from 'zod';
import { amountStringSchema, trueFalseEnum } from './common';

export const outgoingsAndCommitmentsSchema = z
  .object({
    totalMonthlyRent: z.enum(['I will not pay any rent/board expense after completion', 'Other'], {
      required_error: 'Please select an option',
    }),
    totalMonthlyRentOther: amountStringSchema.optional(),
    inRentArrears: trueFalseEnum,
    councilTax: amountStringSchema,
    utilities: amountStringSchema,
    insurance: amountStringSchema,
    subscriptions: amountStringSchema,
    childcareAndSchool: amountStringSchema,
    groceries: amountStringSchema,
    eatingOut: amountStringSchema,
    householdShopping: amountStringSchema,
    otherShopping: amountStringSchema,
    lifestyle: amountStringSchema,
    travel: amountStringSchema,
    holidays: amountStringSchema,
    transfers: amountStringSchema,
    medical: amountStringSchema,
    childMaintenance: amountStringSchema,
    financialCommitments: trueFalseEnum,
    totalMonthlyPayment: z.string().optional(),
    financialDifficulty: trueFalseEnum,
  })
  .superRefine((input, ctx) => {
    if (input.totalMonthlyRent === 'Other' && !input.totalMonthlyRentOther) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'An amount is required',
        path: ['totalMonthlyRentOther'],
      });
    }

    if (input.financialCommitments === 'true' && !input.totalMonthlyPayment) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Total monthly payment of other financial commitments is required',
        path: ['totalMonthlyPayment'],
      });
    }
  });

export type OutgoingsAndCommitmentsSchema = z.infer<typeof outgoingsAndCommitmentsSchema>;
