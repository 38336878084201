import { http } from 'msw';

import { createGetIfaApprovalQueryResponse } from '../mocks/createGetIfaApproval';

export const getIfaApprovalHandler = http.get('*/ifa_approval', function handler(info) {
  return new Response(JSON.stringify(createGetIfaApprovalQueryResponse()), {
    headers: {
      'Content-Type': 'application/json',
    },
  });
});
