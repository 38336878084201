import type {
  PostToggleDocumentUnread200,
  PostToggleDocumentUnread505,
  PostToggleDocumentUnreadMutationRequest,
  PostToggleDocumentUnreadMutationResponse,
} from '../types/PostToggleDocumentUnread';
import { createToggleDocumentUnreadPostRequest } from './createToggleDocumentUnreadPostRequest';
import { createToggleDocumentUnreadPostResponse } from './createToggleDocumentUnreadPostResponse';

/**
 * @description 200 response
 */
export function createPostToggleDocumentUnread200(): NonNullable<PostToggleDocumentUnread200> {
  return createToggleDocumentUnreadPostResponse();
}

/**
 * @description 505 response
 */
export function createPostToggleDocumentUnread505(): NonNullable<PostToggleDocumentUnread505> {
  return undefined;
}

export function createPostToggleDocumentUnreadMutationRequest(): NonNullable<PostToggleDocumentUnreadMutationRequest> {
  return createToggleDocumentUnreadPostRequest();
}

/**
 * @description 200 response
 */
export function createPostToggleDocumentUnreadMutationResponse(): NonNullable<PostToggleDocumentUnreadMutationResponse> {
  return createToggleDocumentUnreadPostResponse();
}
