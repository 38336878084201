import { isTenant } from '@repo/utils';
import { createFileRoute, Outlet, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/_authenticated/_pfida')({
  beforeLoad: () => {
    if (!isTenant('pfida')) {
      redirect({
        to: '/',
        throw: true,
      });
    }
  },
  component: () => <Outlet />,
});
