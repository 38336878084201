import type {
  PostToggleDividendsReinvest200,
  PostToggleDividendsReinvest505,
  PostToggleDividendsReinvestMutationRequest,
  PostToggleDividendsReinvestMutationResponse,
} from '../types/PostToggleDividendsReinvest';
import { createToggleDividendsReinvestPostRequest } from './createToggleDividendsReinvestPostRequest';
import { createToggleDividendsReinvestPostResponse } from './createToggleDividendsReinvestPostResponse';

/**
 * @description 200 response
 */
export function createPostToggleDividendsReinvest200(): NonNullable<PostToggleDividendsReinvest200> {
  return createToggleDividendsReinvestPostResponse();
}

/**
 * @description 505 response
 */
export function createPostToggleDividendsReinvest505(): NonNullable<PostToggleDividendsReinvest505> {
  return undefined;
}

export function createPostToggleDividendsReinvestMutationRequest(): NonNullable<PostToggleDividendsReinvestMutationRequest> {
  return createToggleDividendsReinvestPostRequest();
}

/**
 * @description 200 response
 */
export function createPostToggleDividendsReinvestMutationResponse(): NonNullable<PostToggleDividendsReinvestMutationResponse> {
  return createToggleDividendsReinvestPostResponse();
}
