import { faker } from '@faker-js/faker';

import type { CostCalculatorPostResponse } from '../types/CostCalculatorPostResponse';

export function createCostCalculatorPostResponse(
  data: NonNullable<Partial<CostCalculatorPostResponse>> = {}
): NonNullable<CostCalculatorPostResponse> {
  return {
    ...{
      data: {
        annual_increase: faker.number.float(),
        arrangement_fee: faker.number.float(),
        average_annual_increase: faker.number.float(),
        buy_to_let: faker.string.alpha(),
        deposit: faker.string.alpha(),
        finance_amount: faker.number.float(),
        monthly_payment: faker.number.float(),
        property_value: faker.string.alpha(),
        term: faker.string.alpha(),
        total_payment_best_case: faker.number.float(),
        total_payment_mid_range: faker.number.float(),
        total_payment_worst_case: faker.number.float(),
      },
      response: {
        date: faker.string.alpha(),
        messages: faker.helpers.arrayElements([faker.string.alpha()]) as any,
        status: faker.number.int(),
        type: faker.string.alpha(),
      },
    },
    ...data,
  };
}
