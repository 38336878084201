import { http } from 'msw';

import { createGetAccountTypeQueryResponse } from '../mocks/createGetAccountType';

export const getAccountTypeHandler = http.get('*/account_type', function handler(info) {
  return new Response(JSON.stringify(createGetAccountTypeQueryResponse()), {
    headers: {
      'Content-Type': 'application/json',
    },
  });
});
