import type {
  PostReferrals200,
  PostReferrals505,
  PostReferralsMutationRequest,
  PostReferralsMutationResponse,
} from '../types/PostReferrals';
import { createReferralsPostRequest } from './createReferralsPostRequest';
import { createReferralsPostResponse } from './createReferralsPostResponse';

/**
 * @description 200 response
 */
export function createPostReferrals200(): NonNullable<PostReferrals200> {
  return createReferralsPostResponse();
}

/**
 * @description 505 response
 */
export function createPostReferrals505(): NonNullable<PostReferrals505> {
  return undefined;
}

export function createPostReferralsMutationRequest(): NonNullable<PostReferralsMutationRequest> {
  return createReferralsPostRequest();
}

/**
 * @description 200 response
 */
export function createPostReferralsMutationResponse(): NonNullable<PostReferralsMutationResponse> {
  return createReferralsPostResponse();
}
