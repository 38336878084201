import type {
  PostForgotPasswordConfirm200,
  PostForgotPasswordConfirm505,
  PostForgotPasswordConfirmMutationRequest,
  PostForgotPasswordConfirmMutationResponse,
} from '../types/PostForgotPasswordConfirm';
import { createForgotPasswordConfirmPostRequest } from './createForgotPasswordConfirmPostRequest';
import { createForgotPasswordConfirmPostResponse } from './createForgotPasswordConfirmPostResponse';

/**
 * @description 200 response
 */
export function createPostForgotPasswordConfirm200(): NonNullable<PostForgotPasswordConfirm200> {
  return createForgotPasswordConfirmPostResponse();
}

/**
 * @description 505 response
 */
export function createPostForgotPasswordConfirm505(): NonNullable<PostForgotPasswordConfirm505> {
  return undefined;
}

export function createPostForgotPasswordConfirmMutationRequest(): NonNullable<PostForgotPasswordConfirmMutationRequest> {
  return createForgotPasswordConfirmPostRequest();
}

/**
 * @description 200 response
 */
export function createPostForgotPasswordConfirmMutationResponse(): NonNullable<PostForgotPasswordConfirmMutationResponse> {
  return createForgotPasswordConfirmPostResponse();
}
